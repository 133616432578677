.headerContainer {
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba($color: #000000, $alpha: 0.16);
  margin-bottom: 20px;
  position: fixed;
  margin-top: 30px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;

  .form-control,
  input[type=email],
  input[type=password],
  input[type=search],
  input[type=text],
  textarea {
    max-width: 100%;
    background: #f5f5f5;
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;
    color: #666;
    margin-bottom: 2%;
    outline: none;
    width: 100%;
  }


  .rowSearch{
    display: flex !important;
    flex-wrap: wrap !important;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
    display: grid;
    grid-template-areas: 'logo nav actions';
    grid-template-columns: 190px 1fr 1fr;
    grid-template-rows: 1fr;

    .menuIcon {
      display: none;
    }

    .logoContainer {
      grid-area: logo;

      img {
        width: 160px;
      }
    }

    nav {
      grid-area: nav;
      display: flex;
      align-items: center;
      list-style: none;

      li {
        margin-right: 40px;

        a {
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: var(--black) !important;
          text-transform: uppercase;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
    }

    .action {
      background-color: transparent;
      border: none;
      width: 40px;
      height: 40px;
      padding: 0;
      position: relative;

      &:hover {
        opacity: 0.7;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      @media screen and (max-width: 768px) {
        img {
          width: 20px;
          height: 20px;
        }
      }

      .counter {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--sage);
        width: 18px;
        height: 18px;
        border-radius: 100px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-areas: 'menu logo actions';
      grid-template-columns: 1fr 1fr 1fr;

      .menuIcon {
        grid-area: menu;
        display: flex;
      }

      .logoContainer {
        display: flex;
        justify-content: center;

        img {
          width: 120px;
        }
      }

      nav {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;
        height: 100%;

        li {
          margin: 10px 0;

          a {
            padding: 20px;
          }
        }
      }
    }
  }
}
