.m-0 {
  margin: 0px;
}

.p-0 {
  padding: 0px;
}

.m-1 {
  margin: 10px;
}

.p-1 {
  padding: 10px;
}

.m-2 {
  margin: 20px;
}

.p-2 {
  padding: 20px;
}

.m-3 {
  margin: 30px;
}

.p-3 {
  padding: 30px;
}

.m-4 {
  margin: 40px;
}

.p-4 {
  padding: 40px;
}

.m-5 {
  margin: 50px;
}

.p-5 {
  padding: 50px;
}

.m-6 {
  margin: 60px;
}

.p-6 {
  padding: 60px;
}

.m-7 {
  margin: 70px;
}

.p-7 {
  padding: 70px;
}

.m-8 {
  margin: 80px;
}

.p-8 {
  padding: 80px;
}

.m-9 {
  margin: 90px;
}

.p-9 {
  padding: 90px;
}

.m-10 {
  margin: 100px;
}

.p-10 {
  padding: 100px;
}

.mt-0 {
  margin-top: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.mt-1 {
  margin-top: 10px;
}

.pt-1 {
  padding-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.pt-2 {
  padding-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.pt-3 {
  padding-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.pt-4 {
  padding-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.pt-5 {
  padding-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

.pt-6 {
  padding-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}

.pt-7 {
  padding-top: 70px;
}

.mt-8 {
  margin-top: 80px;
}

.pt-8 {
  padding-top: 80px;
}

.mt-9 {
  margin-top: 90px;
}

.pt-9 {
  padding-top: 90px;
}

.mt-10 {
  margin-top: 100px;
}

.pt-10 {
  padding-top: 100px;
}

.mb-0 {
  margin-bottom: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.mb-1 {
  margin-bottom: 10px;
}

.pb-1 {
  padding-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.pb-2 {
  padding-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.pb-3 {
  padding-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.pb-4 {
  padding-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.pb-5 {
  padding-bottom: 50px;
}

.mb-6 {
  margin-bottom: 60px;
}

.pb-6 {
  padding-bottom: 60px;
}

.mb-7 {
  margin-bottom: 70px;
}

.pb-7 {
  padding-bottom: 70px;
}

.mb-8 {
  margin-bottom: 80px;
}

.pb-8 {
  padding-bottom: 80px;
}

.mb-9 {
  margin-bottom: 90px;
}

.pb-9 {
  padding-bottom: 90px;
}

.mb-10 {
  margin-bottom: 100px;
}

.pb-10 {
  padding-bottom: 100px;
}

.ml-0 {
  margin-left: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.ml-1 {
  margin-left: 10px;
}

.pl-1 {
  padding-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.pl-2 {
  padding-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.pl-3 {
  padding-left: 30px;
}

.ml-4 {
  margin-left: 40px;
}

.pl-4 {
  padding-left: 40px;
}

.ml-5 {
  margin-left: 50px;
}

.pl-5 {
  padding-left: 50px;
}

.ml-6 {
  margin-left: 60px;
}

.pl-6 {
  padding-left: 60px;
}

.ml-7 {
  margin-left: 70px;
}

.pl-7 {
  padding-left: 70px;
}

.ml-8 {
  margin-left: 80px;
}

.pl-8 {
  padding-left: 80px;
}

.ml-9 {
  margin-left: 90px;
}

.pl-9 {
  padding-left: 90px;
}

.ml-10 {
  margin-left: 100px;
}

.pl-10 {
  padding-left: 100px;
}

.mr-0 {
  margin-right: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.mr-1 {
  margin-right: 10px;
}

.pr-1 {
  padding-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.pr-2 {
  padding-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.pr-3 {
  padding-right: 30px;
}

.mr-4 {
  margin-right: 40px;
}

.pr-4 {
  padding-right: 40px;
}

.mr-5 {
  margin-right: 50px;
}

.pr-5 {
  padding-right: 50px;
}

.mr-6 {
  margin-right: 60px;
}

.pr-6 {
  padding-right: 60px;
}

.mr-7 {
  margin-right: 70px;
}

.pr-7 {
  padding-right: 70px;
}

.mr-8 {
  margin-right: 80px;
}

.pr-8 {
  padding-right: 80px;
}

.mr-9 {
  margin-right: 90px;
}

.pr-9 {
  padding-right: 90px;
}

.mr-10 {
  margin-right: 100px;
}

.pr-10 {
  padding-right: 100px;
}

@media (min-width:576px) {
  .m-xs-0 {
    margin: 0px;
  }

  .p-xs-0 {
    padding: 0px;
  }

}

@media (min-width:576px) {
  .m-xs-1 {
    margin: 10px;
  }

  .p-xs-1 {
    padding: 10px;
  }

}

@media (min-width:576px) {
  .m-xs-2 {
    margin: 20px;
  }

  .p-xs-2 {
    padding: 20px;
  }

}

@media (min-width:576px) {
  .m-xs-3 {
    margin: 30px;
  }

  .p-xs-3 {
    padding: 30px;
  }

}

@media (min-width:576px) {
  .m-xs-4 {
    margin: 40px;
  }

  .p-xs-4 {
    padding: 40px;
  }

}

@media (min-width:576px) {
  .m-xs-5 {
    margin: 50px;
  }

  .p-xs-5 {
    padding: 50px;
  }

}

@media (min-width:576px) {
  .m-xs-6 {
    margin: 60px;
  }

  .p-xs-6 {
    padding: 60px;
  }

}

@media (min-width:576px) {
  .m-xs-7 {
    margin: 70px;
  }

  .p-xs-7 {
    padding: 70px;
  }

}

@media (min-width:576px) {
  .m-xs-8 {
    margin: 80px;
  }

  .p-xs-8 {
    padding: 80px;
  }

}

@media (min-width:576px) {
  .m-xs-9 {
    margin: 90px;
  }

  .p-xs-9 {
    padding: 90px;
  }

}

@media (min-width:576px) {
  .m-xs-10 {
    margin: 100px;
  }

  .p-xs-10 {
    padding: 100px;
  }

}

@media (min-width:576px) {
  .mt-xs-0 {
    margin-top: 0px;
  }

  .pt-xs-0 {
    padding-top: 0px;
  }

}

@media (min-width:576px) {
  .mt-xs-1 {
    margin-top: 10px;
  }

  .pt-xs-1 {
    padding-top: 10px;
  }

}

@media (min-width:576px) {
  .mt-xs-2 {
    margin-top: 20px;
  }

  .pt-xs-2 {
    padding-top: 20px;
  }

}

@media (min-width:576px) {
  .mt-xs-3 {
    margin-top: 30px;
  }

  .pt-xs-3 {
    padding-top: 30px;
  }

}

@media (min-width:576px) {
  .mt-xs-4 {
    margin-top: 40px;
  }

  .pt-xs-4 {
    padding-top: 40px;
  }

}

@media (min-width:576px) {
  .mt-xs-5 {
    margin-top: 50px;
  }

  .pt-xs-5 {
    padding-top: 50px;
  }

}

@media (min-width:576px) {
  .mt-xs-6 {
    margin-top: 60px;
  }

  .pt-xs-6 {
    padding-top: 60px;
  }

}

@media (min-width:576px) {
  .mt-xs-7 {
    margin-top: 70px;
  }

  .pt-xs-7 {
    padding-top: 70px;
  }

}

@media (min-width:576px) {
  .mt-xs-8 {
    margin-top: 80px;
  }

  .pt-xs-8 {
    padding-top: 80px;
  }

}

@media (min-width:576px) {
  .mt-xs-9 {
    margin-top: 90px;
  }

  .pt-xs-9 {
    padding-top: 90px;
  }

}

@media (min-width:576px) {
  .mt-xs-10 {
    margin-top: 100px;
  }

  .pt-xs-10 {
    padding-top: 100px;
  }

}

@media (min-width:576px) {
  .mb-xs-0 {
    margin-bottom: 0px;
  }

  .pb-xs-0 {
    padding-bottom: 0px;
  }

}

@media (min-width:576px) {
  .mb-xs-1 {
    margin-bottom: 10px;
  }

  .pb-xs-1 {
    padding-bottom: 10px;
  }

}

@media (min-width:576px) {
  .mb-xs-2 {
    margin-bottom: 20px;
  }

  .pb-xs-2 {
    padding-bottom: 20px;
  }

}

@media (min-width:576px) {
  .mb-xs-3 {
    margin-bottom: 30px;
  }

  .pb-xs-3 {
    padding-bottom: 30px;
  }

}

@media (min-width:576px) {
  .mb-xs-4 {
    margin-bottom: 40px;
  }

  .pb-xs-4 {
    padding-bottom: 40px;
  }

}

@media (min-width:576px) {
  .mb-xs-5 {
    margin-bottom: 50px;
  }

  .pb-xs-5 {
    padding-bottom: 50px;
  }

}

@media (min-width:576px) {
  .mb-xs-6 {
    margin-bottom: 60px;
  }

  .pb-xs-6 {
    padding-bottom: 60px;
  }

}

@media (min-width:576px) {
  .mb-xs-7 {
    margin-bottom: 70px;
  }

  .pb-xs-7 {
    padding-bottom: 70px;
  }

}

@media (min-width:576px) {
  .mb-xs-8 {
    margin-bottom: 80px;
  }

  .pb-xs-8 {
    padding-bottom: 80px;
  }

}

@media (min-width:576px) {
  .mb-xs-9 {
    margin-bottom: 90px;
  }

  .pb-xs-9 {
    padding-bottom: 90px;
  }

}

@media (min-width:576px) {
  .mb-xs-10 {
    margin-bottom: 100px;
  }

  .pb-xs-10 {
    padding-bottom: 100px;
  }

}

@media (min-width:576px) {
  .ml-xs-0 {
    margin-left: 0px;
  }

  .pl-xs-0 {
    padding-left: 0px;
  }

}

@media (min-width:576px) {
  .ml-xs-1 {
    margin-left: 10px;
  }

  .pl-xs-1 {
    padding-left: 10px;
  }

}

@media (min-width:576px) {
  .ml-xs-2 {
    margin-left: 20px;
  }

  .pl-xs-2 {
    padding-left: 20px;
  }

}

@media (min-width:576px) {
  .ml-xs-3 {
    margin-left: 30px;
  }

  .pl-xs-3 {
    padding-left: 30px;
  }

}

@media (min-width:576px) {
  .ml-xs-4 {
    margin-left: 40px;
  }

  .pl-xs-4 {
    padding-left: 40px;
  }

}

@media (min-width:576px) {
  .ml-xs-5 {
    margin-left: 50px;
  }

  .pl-xs-5 {
    padding-left: 50px;
  }

}

@media (min-width:576px) {
  .ml-xs-6 {
    margin-left: 60px;
  }

  .pl-xs-6 {
    padding-left: 60px;
  }

}

@media (min-width:576px) {
  .ml-xs-7 {
    margin-left: 70px;
  }

  .pl-xs-7 {
    padding-left: 70px;
  }

}

@media (min-width:576px) {
  .ml-xs-8 {
    margin-left: 80px;
  }

  .pl-xs-8 {
    padding-left: 80px;
  }

}

@media (min-width:576px) {
  .ml-xs-9 {
    margin-left: 90px;
  }

  .pl-xs-9 {
    padding-left: 90px;
  }

}

@media (min-width:576px) {
  .ml-xs-10 {
    margin-left: 100px;
  }

  .pl-xs-10 {
    padding-left: 100px;
  }

}

@media (min-width:576px) {
  .mr-xs-0 {
    margin-right: 0px;
  }

  .pr-xs-0 {
    padding-right: 0px;
  }

}

@media (min-width:576px) {
  .mr-xs-1 {
    margin-right: 10px;
  }

  .pr-xs-1 {
    padding-right: 10px;
  }

}

@media (min-width:576px) {
  .mr-xs-2 {
    margin-right: 20px;
  }

  .pr-xs-2 {
    padding-right: 20px;
  }

}

@media (min-width:576px) {
  .mr-xs-3 {
    margin-right: 30px;
  }

  .pr-xs-3 {
    padding-right: 30px;
  }

}

@media (min-width:576px) {
  .mr-xs-4 {
    margin-right: 40px;
  }

  .pr-xs-4 {
    padding-right: 40px;
  }

}

@media (min-width:576px) {
  .mr-xs-5 {
    margin-right: 50px;
  }

  .pr-xs-5 {
    padding-right: 50px;
  }

}

@media (min-width:576px) {
  .mr-xs-6 {
    margin-right: 60px;
  }

  .pr-xs-6 {
    padding-right: 60px;
  }

}

@media (min-width:576px) {
  .mr-xs-7 {
    margin-right: 70px;
  }

  .pr-xs-7 {
    padding-right: 70px;
  }

}

@media (min-width:576px) {
  .mr-xs-8 {
    margin-right: 80px;
  }

  .pr-xs-8 {
    padding-right: 80px;
  }

}

@media (min-width:576px) {
  .mr-xs-9 {
    margin-right: 90px;
  }

  .pr-xs-9 {
    padding-right: 90px;
  }

}

@media (min-width:576px) {
  .mr-xs-10 {
    margin-right: 100px;
  }

  .pr-xs-10 {
    padding-right: 100px;
  }

}

@media (min-width:768px) {
  .m-sm-0 {
    margin: 0px;
  }

  .p-sm-0 {
    padding: 0px;
  }

}

@media (min-width:768px) {
  .m-sm-1 {
    margin: 10px;
  }

  .p-sm-1 {
    padding: 10px;
  }

}

@media (min-width:768px) {
  .m-sm-2 {
    margin: 20px;
  }

  .p-sm-2 {
    padding: 20px;
  }

}

@media (min-width:768px) {
  .m-sm-3 {
    margin: 30px;
  }

  .p-sm-3 {
    padding: 30px;
  }

}

@media (min-width:768px) {
  .m-sm-4 {
    margin: 40px;
  }

  .p-sm-4 {
    padding: 40px;
  }

}

@media (min-width:768px) {
  .m-sm-5 {
    margin: 50px;
  }

  .p-sm-5 {
    padding: 50px;
  }

}

@media (min-width:768px) {
  .m-sm-6 {
    margin: 60px;
  }

  .p-sm-6 {
    padding: 60px;
  }

}

@media (min-width:768px) {
  .m-sm-7 {
    margin: 70px;
  }

  .p-sm-7 {
    padding: 70px;
  }

}

@media (min-width:768px) {
  .m-sm-8 {
    margin: 80px;
  }

  .p-sm-8 {
    padding: 80px;
  }

}

@media (min-width:768px) {
  .m-sm-9 {
    margin: 90px;
  }

  .p-sm-9 {
    padding: 90px;
  }

}

@media (min-width:768px) {
  .m-sm-10 {
    margin: 100px;
  }

  .p-sm-10 {
    padding: 100px;
  }

}

@media (min-width:768px) {
  .mt-sm-0 {
    margin-top: 0px;
  }

  .pt-sm-0 {
    padding-top: 0px;
  }

}

@media (min-width:768px) {
  .mt-sm-1 {
    margin-top: 10px;
  }

  .pt-sm-1 {
    padding-top: 10px;
  }

}

@media (min-width:768px) {
  .mt-sm-2 {
    margin-top: 20px;
  }

  .pt-sm-2 {
    padding-top: 20px;
  }

}

@media (min-width:768px) {
  .mt-sm-3 {
    margin-top: 30px;
  }

  .pt-sm-3 {
    padding-top: 30px;
  }

}

@media (min-width:768px) {
  .mt-sm-4 {
    margin-top: 40px;
  }

  .pt-sm-4 {
    padding-top: 40px;
  }

}

@media (min-width:768px) {
  .mt-sm-5 {
    margin-top: 50px;
  }

  .pt-sm-5 {
    padding-top: 50px;
  }

}

@media (min-width:768px) {
  .mt-sm-6 {
    margin-top: 60px;
  }

  .pt-sm-6 {
    padding-top: 60px;
  }

}

@media (min-width:768px) {
  .mt-sm-7 {
    margin-top: 70px;
  }

  .pt-sm-7 {
    padding-top: 70px;
  }

}

@media (min-width:768px) {
  .mt-sm-8 {
    margin-top: 80px;
  }

  .pt-sm-8 {
    padding-top: 80px;
  }

}

@media (min-width:768px) {
  .mt-sm-9 {
    margin-top: 90px;
  }

  .pt-sm-9 {
    padding-top: 90px;
  }

}

@media (min-width:768px) {
  .mt-sm-10 {
    margin-top: 100px;
  }

  .pt-sm-10 {
    padding-top: 100px;
  }

}

@media (min-width:768px) {
  .mb-sm-0 {
    margin-bottom: 0px;
  }

  .pb-sm-0 {
    padding-bottom: 0px;
  }

}

@media (min-width:768px) {
  .mb-sm-1 {
    margin-bottom: 10px;
  }

  .pb-sm-1 {
    padding-bottom: 10px;
  }

}

@media (min-width:768px) {
  .mb-sm-2 {
    margin-bottom: 20px;
  }

  .pb-sm-2 {
    padding-bottom: 20px;
  }

}

@media (min-width:768px) {
  .mb-sm-3 {
    margin-bottom: 30px;
  }

  .pb-sm-3 {
    padding-bottom: 30px;
  }

}

@media (min-width:768px) {
  .mb-sm-4 {
    margin-bottom: 40px;
  }

  .pb-sm-4 {
    padding-bottom: 40px;
  }

}

@media (min-width:768px) {
  .mb-sm-5 {
    margin-bottom: 50px;
  }

  .pb-sm-5 {
    padding-bottom: 50px;
  }

}

@media (min-width:768px) {
  .mb-sm-6 {
    margin-bottom: 60px;
  }

  .pb-sm-6 {
    padding-bottom: 60px;
  }

}

@media (min-width:768px) {
  .mb-sm-7 {
    margin-bottom: 70px;
  }

  .pb-sm-7 {
    padding-bottom: 70px;
  }

}

@media (min-width:768px) {
  .mb-sm-8 {
    margin-bottom: 80px;
  }

  .pb-sm-8 {
    padding-bottom: 80px;
  }

}

@media (min-width:768px) {
  .mb-sm-9 {
    margin-bottom: 90px;
  }

  .pb-sm-9 {
    padding-bottom: 90px;
  }

}

@media (min-width:768px) {
  .mb-sm-10 {
    margin-bottom: 100px;
  }

  .pb-sm-10 {
    padding-bottom: 100px;
  }

}

@media (min-width:768px) {
  .ml-sm-0 {
    margin-left: 0px;
  }

  .pl-sm-0 {
    padding-left: 0px;
  }

}

@media (min-width:768px) {
  .ml-sm-1 {
    margin-left: 10px;
  }

  .pl-sm-1 {
    padding-left: 10px;
  }

}

@media (min-width:768px) {
  .ml-sm-2 {
    margin-left: 20px;
  }

  .pl-sm-2 {
    padding-left: 20px;
  }

}

@media (min-width:768px) {
  .ml-sm-3 {
    margin-left: 30px;
  }

  .pl-sm-3 {
    padding-left: 30px;
  }

}

@media (min-width:768px) {
  .ml-sm-4 {
    margin-left: 40px;
  }

  .pl-sm-4 {
    padding-left: 40px;
  }

}

@media (min-width:768px) {
  .ml-sm-5 {
    margin-left: 50px;
  }

  .pl-sm-5 {
    padding-left: 50px;
  }

}

@media (min-width:768px) {
  .ml-sm-6 {
    margin-left: 60px;
  }

  .pl-sm-6 {
    padding-left: 60px;
  }

}

@media (min-width:768px) {
  .ml-sm-7 {
    margin-left: 70px;
  }

  .pl-sm-7 {
    padding-left: 70px;
  }

}

@media (min-width:768px) {
  .ml-sm-8 {
    margin-left: 80px;
  }

  .pl-sm-8 {
    padding-left: 80px;
  }

}

@media (min-width:768px) {
  .ml-sm-9 {
    margin-left: 90px;
  }

  .pl-sm-9 {
    padding-left: 90px;
  }

}

@media (min-width:768px) {
  .ml-sm-10 {
    margin-left: 100px;
  }

  .pl-sm-10 {
    padding-left: 100px;
  }

}

@media (min-width:768px) {
  .mr-sm-0 {
    margin-right: 0px;
  }

  .pr-sm-0 {
    padding-right: 0px;
  }

}

@media (min-width:768px) {
  .mr-sm-1 {
    margin-right: 10px;
  }

  .pr-sm-1 {
    padding-right: 10px;
  }

}

@media (min-width:768px) {
  .mr-sm-2 {
    margin-right: 20px;
  }

  .pr-sm-2 {
    padding-right: 20px;
  }

}

@media (min-width:768px) {
  .mr-sm-3 {
    margin-right: 30px;
  }

  .pr-sm-3 {
    padding-right: 30px;
  }

}

@media (min-width:768px) {
  .mr-sm-4 {
    margin-right: 40px;
  }

  .pr-sm-4 {
    padding-right: 40px;
  }

}

@media (min-width:768px) {
  .mr-sm-5 {
    margin-right: 50px;
  }

  .pr-sm-5 {
    padding-right: 50px;
  }

}

@media (min-width:768px) {
  .mr-sm-6 {
    margin-right: 60px;
  }

  .pr-sm-6 {
    padding-right: 60px;
  }

}

@media (min-width:768px) {
  .mr-sm-7 {
    margin-right: 70px;
  }

  .pr-sm-7 {
    padding-right: 70px;
  }

}

@media (min-width:768px) {
  .mr-sm-8 {
    margin-right: 80px;
  }

  .pr-sm-8 {
    padding-right: 80px;
  }

}

@media (min-width:768px) {
  .mr-sm-9 {
    margin-right: 90px;
  }

  .pr-sm-9 {
    padding-right: 90px;
  }

}

@media (min-width:768px) {
  .mr-sm-10 {
    margin-right: 100px;
  }

  .pr-sm-10 {
    padding-right: 100px;
  }

}

@media (min-width:992px) {
  .m-md-0 {
    margin: 0px;
  }

  .p-md-0 {
    padding: 0px;
  }

}

@media (min-width:992px) {
  .m-md-1 {
    margin: 10px;
  }

  .p-md-1 {
    padding: 10px;
  }

}

@media (min-width:992px) {
  .m-md-2 {
    margin: 20px;
  }

  .p-md-2 {
    padding: 20px;
  }

}

@media (min-width:992px) {
  .m-md-3 {
    margin: 30px;
  }

  .p-md-3 {
    padding: 30px;
  }

}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 90%;
  }
}

@media (min-width:992px) {
  .m-md-4 {
    margin: 40px;
  }

  .p-md-4 {
    padding: 40px;
  }

}

@media (min-width:992px) {
  .m-md-5 {
    margin: 50px;
  }

  .p-md-5 {
    padding: 50px;
  }

}

@media (min-width:992px) {
  .m-md-6 {
    margin: 60px;
  }

  .p-md-6 {
    padding: 60px;
  }

}

@media (min-width:992px) {
  .m-md-7 {
    margin: 70px;
  }

  .p-md-7 {
    padding: 70px;
  }

}

@media (min-width:992px) {
  .m-md-8 {
    margin: 80px;
  }

  .p-md-8 {
    padding: 80px;
  }

}

@media (min-width:992px) {
  .m-md-9 {
    margin: 90px;
  }

  .p-md-9 {
    padding: 90px;
  }

}

@media (min-width:992px) {
  .m-md-10 {
    margin: 100px;
  }

  .p-md-10 {
    padding: 100px;
  }

}

@media (min-width:992px) {
  .mt-md-0 {
    margin-top: 0px;
  }

  .pt-md-0 {
    padding-top: 0px;
  }

}

@media (min-width:992px) {
  .mt-md-1 {
    margin-top: 10px;
  }

  .pt-md-1 {
    padding-top: 10px;
  }

}

@media (min-width:992px) {
  .mt-md-2 {
    margin-top: 20px;
  }

  .pt-md-2 {
    padding-top: 20px;
  }

}

@media (min-width:992px) {
  .mt-md-3 {
    margin-top: 30px;
  }

  .pt-md-3 {
    padding-top: 30px;
  }

}

@media (min-width:992px) {
  .mt-md-4 {
    margin-top: 40px;
  }

  .pt-md-4 {
    padding-top: 40px;
  }

}

@media (min-width:992px) {
  .mt-md-5 {
    margin-top: 50px;
  }

  .pt-md-5 {
    padding-top: 50px;
  }

}

@media (min-width:992px) {
  .mt-md-6 {
    margin-top: 60px;
  }

  .pt-md-6 {
    padding-top: 60px;
  }

}

@media (min-width:992px) {
  .mt-md-7 {
    margin-top: 70px;
  }

  .pt-md-7 {
    padding-top: 70px;
  }

}

@media (min-width:992px) {
  .mt-md-8 {
    margin-top: 80px;
  }

  .pt-md-8 {
    padding-top: 80px;
  }

}

@media (min-width:992px) {
  .mt-md-9 {
    margin-top: 90px;
  }

  .pt-md-9 {
    padding-top: 90px;
  }

}

@media (min-width:992px) {
  .mt-md-10 {
    margin-top: 100px;
  }

  .pt-md-10 {
    padding-top: 100px;
  }

}

@media (min-width:992px) {
  .mb-md-0 {
    margin-bottom: 0px;
  }

  .pb-md-0 {
    padding-bottom: 0px;
  }

}

@media (min-width:992px) {
  .mb-md-1 {
    margin-bottom: 10px;
  }

  .pb-md-1 {
    padding-bottom: 10px;
  }

}

@media (min-width:992px) {
  .mb-md-2 {
    margin-bottom: 20px;
  }

  .pb-md-2 {
    padding-bottom: 20px;
  }

}

@media (min-width:992px) {
  .mb-md-3 {
    margin-bottom: 30px;
  }

  .pb-md-3 {
    padding-bottom: 30px;
  }

}

@media (min-width:992px) {
  .mb-md-4 {
    margin-bottom: 40px;
  }

  .pb-md-4 {
    padding-bottom: 40px;
  }

}

@media (min-width:992px) {
  .mb-md-5 {
    margin-bottom: 50px;
  }

  .pb-md-5 {
    padding-bottom: 50px;
  }

}

@media (min-width:992px) {
  .mb-md-6 {
    margin-bottom: 60px;
  }

  .pb-md-6 {
    padding-bottom: 60px;
  }

}

@media (min-width:992px) {
  .mb-md-7 {
    margin-bottom: 70px;
  }

  .pb-md-7 {
    padding-bottom: 70px;
  }

}

@media (min-width:992px) {
  .mb-md-8 {
    margin-bottom: 80px;
  }

  .pb-md-8 {
    padding-bottom: 80px;
  }

}

@media (min-width:992px) {
  .mb-md-9 {
    margin-bottom: 90px;
  }

  .pb-md-9 {
    padding-bottom: 90px;
  }

}

@media (min-width:992px) {
  .mb-md-10 {
    margin-bottom: 100px;
  }

  .pb-md-10 {
    padding-bottom: 100px;
  }

}

@media (min-width:992px) {
  .ml-md-0 {
    margin-left: 0px;
  }

  .pl-md-0 {
    padding-left: 0px;
  }

}

@media (min-width:992px) {
  .ml-md-1 {
    margin-left: 10px;
  }

  .pl-md-1 {
    padding-left: 10px;
  }

}

@media (min-width:992px) {
  .ml-md-2 {
    margin-left: 20px;
  }

  .pl-md-2 {
    padding-left: 20px;
  }

}

@media (min-width:992px) {
  .ml-md-3 {
    margin-left: 30px;
  }

  .pl-md-3 {
    padding-left: 30px;
  }

}

@media (min-width:992px) {
  .ml-md-4 {
    margin-left: 40px;
  }

  .pl-md-4 {
    padding-left: 40px;
  }

}

@media (min-width:992px) {
  .ml-md-5 {
    margin-left: 50px;
  }

  .pl-md-5 {
    padding-left: 50px;
  }

}

@media (min-width:992px) {
  .ml-md-6 {
    margin-left: 60px;
  }

  .pl-md-6 {
    padding-left: 60px;
  }

}

@media (min-width:992px) {
  .ml-md-7 {
    margin-left: 70px;
  }

  .pl-md-7 {
    padding-left: 70px;
  }

}

@media (min-width:992px) {
  .ml-md-8 {
    margin-left: 80px;
  }

  .pl-md-8 {
    padding-left: 80px;
  }

}

@media (min-width:992px) {
  .ml-md-9 {
    margin-left: 90px;
  }

  .pl-md-9 {
    padding-left: 90px;
  }

}

@media (min-width:992px) {
  .ml-md-10 {
    margin-left: 100px;
  }

  .pl-md-10 {
    padding-left: 100px;
  }

}

@media (min-width:992px) {
  .mr-md-0 {
    margin-right: 0px;
  }

  .pr-md-0 {
    padding-right: 0px;
  }

}

@media (min-width:992px) {
  .mr-md-1 {
    margin-right: 10px;
  }

  .pr-md-1 {
    padding-right: 10px;
  }

}

@media (min-width:992px) {
  .mr-md-2 {
    margin-right: 20px;
  }

  .pr-md-2 {
    padding-right: 20px;
  }

}

@media (min-width:992px) {
  .mr-md-3 {
    margin-right: 30px;
  }

  .pr-md-3 {
    padding-right: 30px;
  }

}

@media (min-width:992px) {
  .mr-md-4 {
    margin-right: 40px;
  }

  .pr-md-4 {
    padding-right: 40px;
  }

}

@media (min-width:992px) {
  .mr-md-5 {
    margin-right: 50px;
  }

  .pr-md-5 {
    padding-right: 50px;
  }

}

@media (min-width:992px) {
  .mr-md-6 {
    margin-right: 60px;
  }

  .pr-md-6 {
    padding-right: 60px;
  }

}

@media (min-width:992px) {
  .mr-md-7 {
    margin-right: 70px;
  }

  .pr-md-7 {
    padding-right: 70px;
  }

}

@media (min-width:992px) {
  .mr-md-8 {
    margin-right: 80px;
  }

  .pr-md-8 {
    padding-right: 80px;
  }

}

@media (min-width:992px) {
  .mr-md-9 {
    margin-right: 90px;
  }

  .pr-md-9 {
    padding-right: 90px;
  }

}

@media (min-width:992px) {
  .mr-md-10 {
    margin-right: 100px;
  }

  .pr-md-10 {
    padding-right: 100px;
  }

}

@media (min-width:1200px) {
  .m-lg-0 {
    margin: 0px;
  }

  .p-lg-0 {
    padding: 0px;
  }

}

@media (min-width:1200px) {
  .m-lg-1 {
    margin: 10px;
  }

  .p-lg-1 {
    padding: 10px;
  }

}

@media (min-width:1200px) {
  .m-lg-2 {
    margin: 20px;
  }

  .p-lg-2 {
    padding: 20px;
  }

}

@media (min-width:1200px) {
  .m-lg-3 {
    margin: 30px;
  }

  .p-lg-3 {
    padding: 30px;
  }

}

@media (min-width:1200px) {
  .m-lg-4 {
    margin: 40px;
  }

  .p-lg-4 {
    padding: 40px;
  }

}

@media (min-width:1200px) {
  .m-lg-5 {
    margin: 50px;
  }

  .p-lg-5 {
    padding: 50px;
  }

}

@media (min-width:1200px) {
  .m-lg-6 {
    margin: 60px;
  }

  .p-lg-6 {
    padding: 60px;
  }

}

@media (min-width:1200px) {
  .m-lg-7 {
    margin: 70px;
  }

  .p-lg-7 {
    padding: 70px;
  }

}

@media (min-width:1200px) {
  .m-lg-8 {
    margin: 80px;
  }

  .p-lg-8 {
    padding: 80px;
  }

}

@media (min-width:1200px) {
  .m-lg-9 {
    margin: 90px;
  }

  .p-lg-9 {
    padding: 90px;
  }

}

@media (min-width:1200px) {
  .m-lg-10 {
    margin: 100px;
  }

  .p-lg-10 {
    padding: 100px;
  }

}

@media (min-width:1200px) {
  .mt-lg-0 {
    margin-top: 0px;
  }

  .pt-lg-0 {
    padding-top: 0px;
  }

}

@media (min-width:1200px) {
  .mt-lg-1 {
    margin-top: 10px;
  }

  .pt-lg-1 {
    padding-top: 10px;
  }

}

@media (min-width:1200px) {
  .mt-lg-2 {
    margin-top: 20px;
  }

  .pt-lg-2 {
    padding-top: 20px;
  }

}

@media (min-width:1200px) {
  .mt-lg-3 {
    margin-top: 30px;
  }

  .pt-lg-3 {
    padding-top: 30px;
  }

}

@media (min-width:1200px) {
  .mt-lg-4 {
    margin-top: 40px;
  }

  .pt-lg-4 {
    padding-top: 40px;
  }

}

@media (min-width:1200px) {
  .mt-lg-5 {
    margin-top: 50px;
  }

  .pt-lg-5 {
    padding-top: 50px;
  }

}

@media (min-width:1200px) {
  .mt-lg-6 {
    margin-top: 60px;
  }

  .pt-lg-6 {
    padding-top: 60px;
  }

}

@media (min-width:1200px) {
  .mt-lg-7 {
    margin-top: 70px;
  }

  .pt-lg-7 {
    padding-top: 70px;
  }

}

@media (min-width:1200px) {
  .mt-lg-8 {
    margin-top: 80px;
  }

  .pt-lg-8 {
    padding-top: 80px;
  }

}

@media (min-width:1200px) {
  .mt-lg-9 {
    margin-top: 90px;
  }

  .pt-lg-9 {
    padding-top: 90px;
  }

}

@media (min-width:1200px) {
  .mt-lg-10 {
    margin-top: 100px;
  }

  .pt-lg-10 {
    padding-top: 100px;
  }

}

@media (min-width:1200px) {
  .mb-lg-0 {
    margin-bottom: 0px;
  }

  .pb-lg-0 {
    padding-bottom: 0px;
  }

}

@media (min-width:1200px) {
  .mb-lg-1 {
    margin-bottom: 10px;
  }

  .pb-lg-1 {
    padding-bottom: 10px;
  }

}

@media (min-width:1200px) {
  .mb-lg-2 {
    margin-bottom: 20px;
  }

  .pb-lg-2 {
    padding-bottom: 20px;
  }

}

@media (min-width:1200px) {
  .mb-lg-3 {
    margin-bottom: 30px;
  }

  .pb-lg-3 {
    padding-bottom: 30px;
  }

}

@media (min-width:1200px) {
  .mb-lg-4 {
    margin-bottom: 40px;
  }

  .pb-lg-4 {
    padding-bottom: 40px;
  }

}

@media (min-width:1200px) {
  .mb-lg-5 {
    margin-bottom: 50px;
  }

  .pb-lg-5 {
    padding-bottom: 50px;
  }

}

@media (min-width:1200px) {
  .mb-lg-6 {
    margin-bottom: 60px;
  }

  .pb-lg-6 {
    padding-bottom: 60px;
  }

}

@media (min-width:1200px) {
  .mb-lg-7 {
    margin-bottom: 70px;
  }

  .pb-lg-7 {
    padding-bottom: 70px;
  }

}

@media (min-width:1200px) {
  .mb-lg-8 {
    margin-bottom: 80px;
  }

  .pb-lg-8 {
    padding-bottom: 80px;
  }

}

@media (min-width:1200px) {
  .mb-lg-9 {
    margin-bottom: 90px;
  }

  .pb-lg-9 {
    padding-bottom: 90px;
  }

}

@media (min-width:1200px) {
  .mb-lg-10 {
    margin-bottom: 100px;
  }

  .pb-lg-10 {
    padding-bottom: 100px;
  }

}

@media (min-width:1200px) {
  .ml-lg-0 {
    margin-left: 0px;
  }

  .pl-lg-0 {
    padding-left: 0px;
  }

}

@media (min-width:1200px) {
  .ml-lg-1 {
    margin-left: 10px;
  }

  .pl-lg-1 {
    padding-left: 10px;
  }

}

@media (min-width:1200px) {
  .ml-lg-2 {
    margin-left: 20px;
  }

  .pl-lg-2 {
    padding-left: 20px;
  }

}

@media (min-width:1200px) {
  .ml-lg-3 {
    margin-left: 30px;
  }

  .pl-lg-3 {
    padding-left: 30px;
  }

}

@media (min-width:1200px) {
  .ml-lg-4 {
    margin-left: 40px;
  }

  .pl-lg-4 {
    padding-left: 40px;
  }

}

@media (min-width:1200px) {
  .ml-lg-5 {
    margin-left: 50px;
  }

  .pl-lg-5 {
    padding-left: 50px;
  }

}

@media (min-width:1200px) {
  .ml-lg-6 {
    margin-left: 60px;
  }

  .pl-lg-6 {
    padding-left: 60px;
  }

}

@media (min-width:1200px) {
  .ml-lg-7 {
    margin-left: 70px;
  }

  .pl-lg-7 {
    padding-left: 70px;
  }

}

@media (min-width:1200px) {
  .ml-lg-8 {
    margin-left: 80px;
  }

  .pl-lg-8 {
    padding-left: 80px;
  }

}

@media (min-width:1200px) {
  .ml-lg-9 {
    margin-left: 90px;
  }

  .pl-lg-9 {
    padding-left: 90px;
  }

}

@media (min-width:1200px) {
  .ml-lg-10 {
    margin-left: 100px;
  }

  .pl-lg-10 {
    padding-left: 100px;
  }

}

@media (min-width:1200px) {
  .mr-lg-0 {
    margin-right: 0px;
  }

  .pr-lg-0 {
    padding-right: 0px;
  }

}

@media (min-width:1200px) {
  .mr-lg-1 {
    margin-right: 10px;
  }

  .pr-lg-1 {
    padding-right: 10px;
  }

}

@media (min-width:1200px) {
  .mr-lg-2 {
    margin-right: 20px;
  }

  .pr-lg-2 {
    padding-right: 20px;
  }

}

@media (min-width:1200px) {
  .mr-lg-3 {
    margin-right: 30px;
  }

  .pr-lg-3 {
    padding-right: 30px;
  }

}

@media (min-width:1200px) {
  .mr-lg-4 {
    margin-right: 40px;
  }

  .pr-lg-4 {
    padding-right: 40px;
  }

}

@media (min-width:1200px) {
  .mr-lg-5 {
    margin-right: 50px;
  }

  .pr-lg-5 {
    padding-right: 50px;
  }

}

@media (min-width:1200px) {
  .mr-lg-6 {
    margin-right: 60px;
  }

  .pr-lg-6 {
    padding-right: 60px;
  }

}

@media (min-width:1200px) {
  .mr-lg-7 {
    margin-right: 70px;
  }

  .pr-lg-7 {
    padding-right: 70px;
  }

}

@media (min-width:1200px) {
  .mr-lg-8 {
    margin-right: 80px;
  }

  .pr-lg-8 {
    padding-right: 80px;
  }

}

@media (min-width:1200px) {
  .mr-lg-9 {
    margin-right: 90px;
  }

  .pr-lg-9 {
    padding-right: 90px;
  }

}

@media (min-width:1200px) {
  .mr-lg-10 {
    margin-right: 100px;
  }

  .pr-lg-10 {
    padding-right: 100px;
  }

}

.bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-image {
  background-image: url("../images/backgrounds/img-02.jpeg");
}

.bg-image-1 {
  background-image: url("../images/banners/small-banner.jpg");
}

.bg-gray {
  background: #f5f5f5;
}

.bg-dark-gray {
  background: #323232;
}

.bg-yellow {
  background-color: #ff0 !important;
}

.bg-primary {
  background-color: var(--sage) !important;
}

.row-equal-height {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row-equal-height [class*="col-"] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.owl-carousel .owl-nav {
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 0;
  width: 100%;
  cursor: pointer;
  z-index: 999;
}

.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-indent: inherit;
  width: auto;
  cursor: pointer;
  opacity: 0;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  top: 50%;
  background: 0 0;
  padding: 0;
  border: none;
}

.owl-carousel .owl-nav .owl-prev {
  left: 0;
}

.owl-carousel .owl-nav .owl-next {
  right: 0;
}

.owl-carousel .owl-nav i {
  font-size: 18px;
  width: 28px;
  height: 40px;
  line-height: 40px;
  background: #323232;
  color: #fff;
  border-radius: 2px;
  border: 0 solid rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  padding-left: 2px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  margin-top: -3px;
}

.owl-carousel:hover .owl-nav .owl-next,
.owl-carousel:hover .owl-nav .owl-prev {
  opacity: 1;
  background: 0 0;
  padding: 0;
}

.owl-carousel:hover .owl-nav .owl-next:hover i,
.owl-carousel:hover .owl-nav .owl-prev:hover i {
  background: var(--sage);
}

.owl-carousel:hover .owl-nav .owl-next:focus,
.owl-carousel:hover .owl-nav .owl-prev:focus {
  outline: 0;
}

.owl-carousel:hover .owl-nav .owl-prev {
  left: 15px;
}

.owl-carousel:hover .owl-nav .owl-next {
  right: 15px;
}

.owl-carousel .owl-dots {
  display: inline-block;
  position: absolute;
  bottom: -35px;
  text-indent: inherit;
  left: 0;
  width: 100%;
  cursor: pointer;
  text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
  background: 0 0;
  padding: 0;
  border: none;
}

.owl-carousel .owl-dots .owl-dot span {
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  border-radius: 50px;
  width: 8px;
  height: 8px;
  background: #555;
}

.owl-carousel .owl-dots .owl-dot.active span {
  width: 20px;
  background: var(--sage);
}

.owl-carousel .owl-dots .owl-dot:focus {
  outline: 0;
}

.owl-theme .owl-nav {
  margin-top: -17px;
}

::selection {
  background: #999999;
  color: #fff;
  text-shadow: none;
}

body {
  color: #969696;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
   overflow-x: hidden !important;
   width: 100%;
}

.button,
a,
button,
input,
input[type=submit],
select,
textarea {
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

btn,
input,
select,
textarea {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #323232;
  margin-top: 0;
  margin-bottom: 15px;
}

h1 {
  font-size: 36px;
  line-height: 44px;
}

h2 {
  font-size: 30px;
  line-height: 38px;
}

h3 {
  font-size: 26px;
  line-height: 34px;
}

h4 {
  font-size: 22px;
  line-height: 30px;
}

h5 {
  font-size: 20px;
  line-height: 28px;
}

h6 {
  font-size: 18px;
  line-height: 22px;
}

.section-title h1,
.section-title h2,
.section-title h3,
.section-title h4,
.section-title h5,
.section-title h6 {
  word-break: break-word;
}

table {
  border: 1px solid #f5f5f5;
  margin-bottom: 20px;
}

table#wp-calendar {
  background: #fff;
}

table caption {
  color: #969696;
}

table th {
  background: #fbfbfb;
  color: #323232;
}

table td,
table th {
  border: 1px solid #eaeaea;
  padding: 4px 8px;
}

dl dd {
  margin-bottom: 15px;
}

dl dd:last-child {
  margin-bottom: 0;
}

ol,
ul {
  padding-left: 25px;
  margin-bottom: 1em;
}

ol {
  padding-left: 0;
}

ol li {
  list-style: decimal inside;
}

ol ol {
  padding-left: 25px;
}

ul li {
  list-style: inherit;
}

ul.list-inside {
  padding-left: 15px;
}

ul.list-inside li {
  list-style-position: inside;
}

a {
  color: var(--sage);
  outline: 0;
}

a:focus,
a:hover {
  color: #323232;
  text-decoration: none;
}

ins {
  background: #fff9c0;
  text-decoration: none;
}

img {
  max-width: 100%;
}

pre {
  background: #f5f5f5;
  padding: 20px;
  border: 1px solid #e1e1e1;
}

select {
  border-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  height: 43px;
  padding: 0 10px;
}

abbr[title] {
  text-decoration: none;
}

audio,
video {
  width: 100%;
  vertical-align: top;
}

btn,
input,
select,
textarea {
  outline: 0;
}

button.submit {
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
}

.form-control,
input[type=email],
input[type=password],
input[type=search],
input[type=text],
textarea {
  width: 100%;
  padding: 10px 15px;
  height: 46px;
  background: #fff;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #969696;
  font-size: 14px;
}

.form-control:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=text]:focus,
textarea:focus {
  border-color: var(--sage);
  box-shadow: none;
}

textarea.form-control {
  height: auto;
  padding: 13px;
}

input[type=submit] {
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 44px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}

input[type=submit]:hover,
input[type=submit]:focus {
  background: #323232;
  color: #fff;
}

textarea {
  padding: 13px;
  height: auto;
}

/**************************
    HEADER CLASSIC
**************************/
.menu-list-items .navbar {
  padding: 0;
}

header.site-header {
  position:  fixed;
  width: 100%;
  z-index: 1021;
  background: #fff;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.mobile-filter-content{
  position: fixed;
  width: 100%;
  z-index: 900;
  margin-top: 0.5%;
  background-color: #fff;
  border-bottom: .1rem solid #ccc;
  border-top: .1rem solid #ccc;
  background: #fff;
}

.mobile-filter-options-content{
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 900;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
}

.mobile-ul-filter-options{
  list-style: none;
}

.mobile-li-filter-options{
  border-bottom: .1rem solid #ccc;
  padding: 0.5rem 0;
  width: 95%;
}

.paddingHeaderMobile{
  padding-top: 14.6%;
}

.paddingHeaderDesktop{
  padding-top: 5.5%;
}

header.site-header .logo-wrapper {
  text-align: left;
}

header.site-header .logo-wrapper img {

}

header.site-header .topbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 7px 0;
}

header.site-header .topbar .topbar-link ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

header.site-header .topbar .topbar-link ul li {
  font-size: 13px;
  padding-right: 15px;
  line-height: 30px;
}

header.site-header .topbar .topbar-link ul li a {
  color: #323232;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

header.site-header .topbar .topbar-link ul li a:hover {
  color: var(--sage);
}

header.site-header .topbar .topbar-link ul li a:first-child {
  padding-left: 0;
}

header.site-header .topbar .topbar-link ul li i {
  padding-right: 5px;
  color: inherit;
}

header.site-header .topbar .topbar-link .topbar-social_profiles {
  padding-left: 0;
}

header.site-header .topbar .topbar-link .topbar-social_profiles li {
  padding: 0;
  text-align: center;
}

header.site-header .topbar .topbar-right .topbar-link {
  display: inline-block;
  vertical-align: top;
}

header.site-header .topbar .topbar-right .topbar-link > ul > li {
  padding-right: 0;
  padding-left: 12px;
}

header.site-header .topbar .topbar-right .topbar-link > ul > li a {
  color: #323232;
}

header.site-header .topbar .topbar-right .topbar-link > ul > li a:hover {
  color: var(--sage);
}

header.site-header .topbar .topbar-right .topbar-link > ul > li .top-menu li:first-child {
  padding-left: 0;
}

header.site-header .topbar .topbar-right .topbar-link > ul > li .topbar-social_profiles {
  padding-left: 0;
}

header.site-header .topbar .topbar-right .topbar-link > ul > li .topbar-social_profiles li:first-child {
  padding-right: 0;
}

header.site-header .topbar .topbar-right .topbar-link > ul > li .topbar-social_profiles li a {
  padding: 10px 6px;
}

header.site-header .topbar .topbar-right .topbar-link > ul > li .topbar-social_profiles li a i {
  padding-right: 0;
}

header.site-header .topbar .dropdown-menu {
  min-width: 6.5rem;
  overflow-y: auto;
  padding: 0;
  top: 12px !important;
}

header.site-header .topbar .dropdown-menu .dropdown-item {
  padding: 0 15px;
  color: #969696;
  font-size: 13px;
  line-height: 32px;
  background: #fff;
}

header.site-header .topbar .dropdown-menu .dropdown-item:focus,
header.site-header .topbar .dropdown-menu .dropdown-item:hover {
  background-color: var(--sage);
  color: #fff;
}

header.site-header .dropdown-toggle:after {
  content: "";
  margin-left: 12px;
  border-top: .35em solid;
  border-left: .35em solid transparent;
  border-right: .35em solid transparent;
  vertical-align: middle;
}


header.site-header .logo-wrapper {
  padding: 20px 0;
}

header.site-header .header-style-default .ciya-tools {
  margin-top: 0;
  margin-left: 15px;
  display: inline-block;
}

header.site-header .ciya-tools {
  float: right;
  margin-left: 15px;
}

.profile-important{
  height: 28px !important;
  width: 28px !important;
}

header.site-header .ciya-tools-actions {
  padding: 0;
  margin: 0;
}

.input-submit{
text-transform:  none !important;
}

header.site-header .ciya-tools-actions > li {
  list-style-type: none;
  display: inline-block;
  height: 45px;
  padding-left: 20px;
  line-height: 45px;
  text-align: center;
  position: relative;
}

.padding-ciya-tools-mobile {
  padding-left: 28px !important;
}

header.site-header .ciya-tools-actions > li.user-login{
  display: flex;
  align-items: center;
}

header.site-header .ciya-tools-actions > li .glyph-icon {
  font-size: 20px;
}

header.site-header .ciya-tools-actions > li i {
  font-size: 22px;
  margin-right: 0;
  color: #323232;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

header.site-header .ciya-tools-actions > li a {
  color: #323232;
}

header.site-header .ciya-tools-actions > li a:hover {
  color: var(--sage);
}

header.site-header .ciya-tools-actions > li a:hover i {
  color: var(--sage);
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart {
  background-color: #fff;
  color: #323232;
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  width: 430px;
  z-index: 9999;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  padding: 15px;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container {
  position: relative;
  width: 100%;
  max-height: 338px;
  overflow: hidden;
  max-height: 340px;
  padding-bottom: 0;
  padding: 0;
  overflow: auto;
  margin-bottom: 15px;
  border-bottom: 3px double #f5f5f5;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container .svelta-mini-cart {
  padding: 0;
  margin: 0;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container .svelta-mini-cart .ciya-mini-cart-item {
  list-style-type: none;
  text-align: left;
  position: relative;
  margin-bottom: 10px;
  line-height: 18px;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container .svelta-mini-cart .ciya-mini-cart-item img {
  max-width: 60px;
  margin-right: 10px;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container .svelta-mini-cart .ciya-mini-cart-item a {
  font-weight: 400;
  margin-bottom: 3px;
  overflow: hidden;
  display: inline-block;
  line-height: 18px;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container .svelta-mini-cart .ciya-mini-cart-item a.remove {
  font-size: 18px;
  color: #323232 !important;
  position: absolute;
  top: 0;
  left: auto;
  right: 5px;
  margin-top: 0;
  margin-left: 0;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container .svelta-mini-cart .ciya-mini-cart-item a.product-title {
  font-size: 13px;
  color: #323232;
  margin-right: 20px;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .pgs-product-list-widget-container .svelta-mini-cart .ciya-mini-cart-item a.product-title:hover {
  color: var(--sage);
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .quantity {
  padding: 0;
  position: relative;
  display: block;
  line-height: 18px;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .quantity .amount {
  font-weight: 600;
  color: var(--sage);
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__total {
  border: none;
  border-top: 0 solid #f5f5f5;
  margin: 0;
  padding: 5px 0;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__total.total {
  border-top: 0 double #f5f5f5;
  padding: 0;
  margin-bottom: 0;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__total.total strong {
  color: #323232;
  min-width: 40px;
  display: inline-block;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__total.total .amount {
  float: right;
  font-size: 20px;
  font-weight: 600;
  padding-left: 5px;
  color: var(--sage);
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__buttons {
  padding: 0;
  margin: 0;
  display: flex;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__buttons .button {
  margin: 0;
  padding: 5px 27px;
  margin-right: 5px;
  line-height: 34px;
  width: 49%;
  border-radius: 0;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__buttons a.button {
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__buttons a.button:last-child {
  background: #323232;
  margin-right: 0;
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__buttons a.button:last-child:hover {
  background: var(--sage);
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__buttons a.button:first-child {
  background: var(--sage);
}

header.site-header .ciya-tools-actions li .cart-contents .widget-shopping-cart .svelta-mini-cart__buttons a.button:first-child:hover {
  background: #323232;
}

header.site-header .ciya-tools-actions li:hover .cart-contents .widget-shopping-cart {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  overflow-y: auto;
}

header.site-header .ciya-tools-actions li:hover .cart-contents .widget-shopping-cart .svelta-mini-cart-empty-message {
  margin: 10px 0;
}

header.site-header .ciya-tools-cart .cart-link,
header.site-header .ciya-tools-cart a,
header.site-header .ciya-tools-wishlist .cart-link,
header.site-header .ciya-tools-wishlist a {
  position: relative;
}

header.site-header .ciya-tools-cart .cart-link .count,
header.site-header .ciya-tools-cart .cart-link .svelta-wishlist-count,
header.site-header .ciya-tools-cart a .count,
header.site-header .ciya-tools-cart a .svelta-wishlist-count,
header.site-header .ciya-tools-wishlist .cart-link .count,
header.site-header .ciya-tools-wishlist .cart-link .svelta-wishlist-count,
header.site-header .ciya-tools-wishlist a .count,
header.site-header .ciya-tools-wishlist a .svelta-wishlist-count {
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  position: absolute;
  top: 0;
  right: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  margin-top: -12px;
  margin-right: -17px;
  color: #fff;
  background: var(--sage);
  border-radius: 50%;
}

header.site-header .header-nav {
  background: var(--sage);
}

header.site-header .sticky-wrapper {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 96px;
  overflow: hidden;
}

header.site-header .sticky-wrapper.is-sticky {
  opacity: 1;
  z-index: 9999;
}

header.site-header.is-sticky {
  width: 100%;
  left: 0;
  z-index: 999;
  position: fixed;
  top: 0px;
}

header.site-header.is-sticky .container-fluid {
  max-width: 1300px;
  padding-right: 15px;
  padding-left: 15px;
}

header.site-header.is-sticky .topbar {
  display: none;
}

header.site-header.is-sticky .logo-wrapper {
  padding: 14px 0px;
}

header.site-header.is-sticky .header-search-wrap {
  display: none;
}

header.site-header.is-sticky .mega-menu .menu-links > li > a {
  height: 70px;
  line-height: 70px;
}

header.site-header.is-sticky .mega-menu .menu-links > li .drop-down.grid-col-12 {
  width: 1270px;
  left: 50%;
  margin-left: -635px;
}

.mega-menu .menu-links > li:hover {
  background-color: transparent !important;
}

.mega-menu .menu-links > li:hover > a {
  color: var(--sage);
}

.mega-menu .menu-links > li > a {
  border: 0 solid #fff;
  outline: none;
  text-decoration: none;
  padding: 0 10px 0 10px;
  line-height: 83px;
  font-weight: 400;
  height: 83px;
  vertical-align: baseline;
  text-align: left;
  width: auto;
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  background: rgba(0, 0, 0, 0);
  border-radius: 0 0 0 0;
  font-family: inherit;
  font-size: 14px;
}

.mega-menu .menu-links > li > a i {
  padding-left: 5px !important;
}

.mega-menu .menu-links > li.active {
  background-color: transparent;
}

.mega-menu .menu-links > li.active > a {
  color: var(--sage) !important;
}

.mega-menu .menu-links .drop-down-multilevel {
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
}

.mega-menu .menu-links .drop-down-multilevel li:hover {
  background-color: transparent;
  color: var(--sage);
}

.mega-menu .menu-links .drop-down-multilevel li:hover > a {
  color: var(--sage);
}

.mega-menu .menu-links .drop-down-multilevel li:hover > a i {
  color: var(--sage) !important;
}

.mega-menu .menu-links .drop-down-multilevel a {
  background: rgba(255, 255, 255, 0);
  color: #969696;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 15px 8px 15px;
  line-height: 22px;
  min-height: auto;
}

.mega-menu .menu-links .drop-down-multilevel a span {
  transition: all 0.3s ease-in-out;
}

.mega-menu .menu-links .drop-down-multilevel a:hover span {
  color: var(--sage);
}

.mega-menu .menu-links .drop-down {
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
}

.mega-menu .menu-links .drop-down h4 {
  color: #323232;
  margin-bottom: 15px;
}

.mega-menu .menu-links .drop-down a {
  color: #969696;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  padding: 3px 0 3px 0;
  line-height: 22px;
  min-height: auto;
  color: #969696;
}

.mega-menu .menu-links .drop-down a:hover {
  color: var(--sage);
}

.mega-menu .menu-links .drop-down .grid-col-2 {
  width: 20%;
}

.mega-menu > section.menu-list-items {
  background-color: transparent;
}

.search_form-wrap {
  float: right;
}

.search_form-wrap .select2-container {
  width: 225px !important;
}

.search_form-wrap .search_form-inner input.form-control {
  background: 0 0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  width: 450px;
  padding-right: 50px;
  font-size: 15px;
  box-shadow: none;
  height: 45px;
  min-width: 220px;
  padding: 0 25px;
  border-radius: 0 50px 50px 0;
}

.search_form-wrap .search_form-inner .select2-selection.select2-selection--single {
  border: none;
}

.search_form-wrap .search_form-search-button {
  position: absolute;
  top: 0;
  width: 45px;
  height: 45px;
  right: 5px;
}

.search_form-wrap .search_form-search-button:before {
  content: "\f002";
  right: 0;
  position: relative;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  top: 0;
  font-size: 16px;
  line-height: 43px;
  font-family: FontAwesome;
  color: var(--sage);
  transition: all .5s ease-out 0s;
}

.search_form-wrap .search_form-search-button input[type=submit] {
  border: none;
  height: 45px;
  font-size: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  z-index: 9;
  transition: none;
  background: 0 0;
}

.search_form-wrap .search_form-input-wrap {
  position: relative;
}

.search_form-wrap .search-form {
  display: flex;
}

.search_form-wrap .search-form .screen-reader-text {
  display: none;
}

.search_form-wrap .search-form .search_form-search-field {
  position: relative;
  vertical-align: top;
}

.search_form-wrap .select2-selection--single {
  border-radius: 50px 0 0 50px;
  height: 45px;
  border: none;
  background: rgba(0, 0, 0, 0.04);
  padding-left: 15px;
}

.search_form-wrap .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  color: #969696;
}

.search_form-wrap .select2-selection--single .select2-selection__arrow {
  height: 41px;
  width: 30px;
}

.select2-container--default .select2-dropdown {
  border: none;
  border-color: #e8e8e8;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 400px;
  overflow: auto;
}

.select2-container--default .select2-selection--single {
  height: 45px;
  background: #fff;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  padding-left: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  color: #969696;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 41px;
  width: 30px;
}

.select2-container--default .select2-results__option {
  padding: 5px 15px;
  line-height: 24px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: var(--sage);
  color: #fff;
}

.select2-container--default .select2-results__option:hover {
  background-color: var(--sage);
  color: #fff;
}

.select2-container--default .select2-results__option.select2-results__option--highlighted {
  background-color: var(--sage);
  color: #fff;
}

.select2-search--dropdown {
  display: none;
}

/********************************
    header-style-menu-default
********************************/
.header-style-menu-default .topbar .topbar-link ul li:first-child {
  padding-right: 10px;
}

.header-style-menu-default .topbar .topbar-link ul li:last-child {
  padding-right: 0;
}

/********************************
    Header-style-logo-center
********************************/
.header-style-logo-center .header-search-wrap .search-button-wrap .search-button {
  width: 35px;
  height: 45px;
  line-height: 45px;
  background: 0 0;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #323232;
  display: inline-block;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.header-style-logo-center .header-search-wrap .search-button-wrap .search-button:hover {
  color: var(--sage);
}

.header-style-logo-center .header-search-wrap .search-button-wrap .btn.focus,
.header-style-logo-center .header-search-wrap .search-button-wrap .btn:focus,
.header-style-logo-center .header-search-wrap .search-button-wrap .btn-primary,
.header-style-logo-center .header-search-wrap .search-button-wrap .btn-primary:focus {
  box-shadow: none;
}

.header-style-logo-center .logo-wrapper {
  text-align: center !important;
}

.header-style-logo-center .logo-wrapper img {
  max-height: 45px;
}

.header-style-logo-center .primary-nav-wrapper {
  vertical-align: middle;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}

/********************************
    Header-style-menu-center
********************************/
.header-style-menu-center .container-fluid {
  padding-left: 35px;
  padding-right: 35px;
}

.header-style-menu-center .topbar {
  padding: 0 !important;
}

.header-style-menu-center .topbar .topbar-link ul {
  align-items: center;
}

.header-style-menu-center .topbar .topbar-left li.topbar_item {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
}

.header-style-menu-center .topbar .topbar-left li.topbar_item:first-child {
  border: none;
  padding-left: 0;
}

.header-style-menu-center .topbar .topbar-left li.topbar_item:first-child a {
  padding-left: 0;
}

.header-style-menu-center .topbar .topbar-left li.topbar_item:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.header-style-menu-center .topbar .topbar-right .topbar-link > ul > li .top-menu li {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
}

.header-style-menu-center .topbar .topbar-right .topbar-link > ul > li .top-menu li:first-child {
  padding-left: 14px !important;
}

.header-style-menu-center .topbar .topbar-right .topbar-link > ul > li .top-menu li:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.header-style-menu-center .header-nav-right-wrapper {
  text-align: right;
}

.header-style-menu-center .header-nav-right-wrapper .ciya-tools {
  float: none;
  display: inline-block;
  vertical-align: middle;
}

.header-style-menu-center .header-search-wrap {
  display: inline-block;
  vertical-align: middle;
}

.header-style-menu-center .header-search-wrap .search-button-wrap .search-button {
  width: 35px;
  height: 45px;
  line-height: 45px;
  background: 0 0;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #323232;
  display: inline-block;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.header-style-menu-center .header-search-wrap .search-button-wrap .search-button:hover {
  color: var(--sage);
}

.header-style-menu-center .header-search-wrap .search-button-wrap .btn.focus,
.header-style-menu-center .header-search-wrap .search-button-wrap .btn:focus,
.header-style-menu-center .header-search-wrap .search-button-wrap .btn-primary,
.header-style-menu-center .header-search-wrap .search-button-wrap .btn-primary:focus {
  box-shadow: none;
}

.header-style-menu-center .header-search-wrap .search-button-wrap span {
  display: none;
}

.header-style-menu-center .header-nav {
  background: transparent !important;
}

.header-style-menu-center .mega-menu .menu-links > li > a {
  color: #323232;
}

.header-style-menu-center .mega-menu .menu-links > li:hover > a,
.header-style-menu-center .mega-menu .menu-links > li:focus > a {
  color: var(--sage);
}

.header-style-menu-center .primary-nav-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  vertical-align: middle;
}

.header-style-menu-center .logo-wrapper {
  text-align: center;
}

.header-style-menu-center .logo-wrapper img {
  max-height: 45px;
}

.header-style-menu-center .drop-down.grid-col-12 {
  width: 1170px;
  left: 50%;
  margin-left: -585px;
}

/********************************
    Header-style-menu-right
********************************/
.header-style-menu-right .container-fluid {
  padding-left: 35px;
  padding-right: 35px;
}

.header-style-menu-right .topbar {
  padding: 0 !important;
}

.header-style-menu-right .topbar .topbar-link ul {
  align-items: center;
}

.header-style-menu-right .topbar .topbar-left li.topbar_item {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
}

.header-style-menu-right .topbar .topbar-left li.topbar_item:first-child {
  border: none;
  padding-left: 0;
}

.header-style-menu-right .topbar .topbar-left li.topbar_item:first-child a {
  padding-left: 0;
}

.header-style-menu-right .topbar .topbar-left li.topbar_item:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.header-style-menu-right .topbar .topbar-right .topbar-link > ul > li .top-menu li {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
}

.header-style-menu-right .topbar .topbar-right .topbar-link > ul > li .top-menu li:first-child {
  padding-left: 14px !important;
}

.header-style-menu-right .topbar .topbar-right .topbar-link > ul > li .top-menu li:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.header-style-menu-right .logo-wrapper {
  text-align: center;
}

.header-style-menu-right .logo-wrapper img {
  max-height: 45px;
}

.header-style-menu-right .header-nav {
  background: transparent !important;
  text-align: right;
}

.header-style-menu-right .mega-menu .menu-links > li > a {
  color: #323232;
}

.header-style-menu-right .primary-nav-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

.header-style-menu-right .header-search-wrap {
  display: inline-block;
  vertical-align: middle;
}

.header-style-menu-right .header-search-wrap .search-button-wrap .search-button {
  width: 35px;
  height: 45px;
  line-height: 45px;
  background: 0 0;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #323232;
  display: inline-block;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.header-style-menu-right .header-search-wrap .search-button-wrap .search-button:hover {
  color: var(--sage);
}

.header-style-menu-right .header-search-wrap .search-button-wrap .btn.focus,
.header-style-menu-right .header-search-wrap .search-button-wrap .btn:focus,
.header-style-menu-right .header-search-wrap .search-button-wrap .btn-primary,
.header-style-menu-right .header-search-wrap .search-button-wrap .btn-primary:focus {
  box-shadow: none;
}

.header-style-menu-right .header-search-wrap .search-button-wrap span {
  display: none;
}

.header-style-menu-right .header-nav-right-wrapper {
  text-align: right;
  padding-right: 15px;
}

.header-style-menu-right .header-nav-right-wrapper .ciya-tools {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

/********************************
    Header-style-Topbar-with-Main-Header
********************************/
.header-style-topbar-with-main-header .logo-wrapper {
  text-align: center;
}

.header-style-topbar-with-main-header .logo-wrapper img {
  max-height: 45px;
}

.header-style-topbar-with-main-header .header-nav-right-wrapper {
  text-align: right;
  padding-right: 0px;
}

.header-style-topbar-with-main-header .header-nav-right-wrapper .ciya-tools {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

.header-style-topbar-with-main-header .ciya-tools-actions > li i {
  color: #fff !important;
}

.header-style-topbar-with-main-header .ciya-tools-actions > li i:hover:hover {
  color: #323232 !important;
}

.header-style-topbar-with-main-header .ciya-tools-cart a .count,
.header-style-topbar-with-main-header .ciya-tools-wishlist a .svelta-wishlist-count {
  background: #323232 !important;
}


.header-style-topbar-with-main-header .header-search-wrap {
  display: inline-block;
  vertical-align: middle;
}

.header-style-topbar-with-main-header .header-search-wrap .search-button-wrap .search-button {
  width: 35px;
  height: 45px;
  line-height: 45px;
  background: 0 0;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.header-style-topbar-with-main-header .header-search-wrap .search-button-wrap .search-button:hover {
  color: #323232;
}

.header-style-topbar-with-main-header .header-search-wrap .search-button-wrap .btn.focus,
.header-style-topbar-with-main-header .header-search-wrap .search-button-wrap .btn:focus,
.header-style-topbar-with-main-header .header-search-wrap .search-button-wrap .btn-primary,
.header-style-topbar-with-main-header .header-search-wrap .search-button-wrap .btn-primary:focus {
  box-shadow: none;
}

.header-style-topbar-with-main-header .header-search-wrap .search-button-wrap span {
  display: none;
}

.header-style-topbar-with-main-header .primary-nav-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.header-style-topbar-with-main-header .header-main-bg-color-default {
  background-color: var(--sage);
}

/********************************
    header-style-right-topbar-main
********************************/
.header-style-right-topbar-main .topbar-bg-color-default {
  background: var(--sage);
}

.header-style-right-topbar-main .topbar-link > ul > li a {
  color: #fff !important;
}

.header-style-right-topbar-main .topbar {
  padding: 5px 20px !important;
}

.header-style-right-topbar-main .logo-wrapper {
  text-align: center;
}

.header-style-right-topbar-main .logo-wrapper img {
  max-height: 45px;
}

.header-style-right-topbar-main .header-search-wrap {
  display: inline-block;
  vertical-align: middle;
}

.header-style-right-topbar-main .header-search-wrap .search-button-wrap .search-button {
  width: 35px;
  height: 45px;
  line-height: 45px;
  background: 0 0;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #323232;
  display: inline-block;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.header-style-right-topbar-main .header-search-wrap .search-button-wrap .search-button:hover {
  color: var(--sage);
}

.header-style-right-topbar-main .header-search-wrap .search-button-wrap .btn.focus,
.header-style-right-topbar-main .header-search-wrap .search-button-wrap .btn:focus,
.header-style-right-topbar-main .header-search-wrap .search-button-wrap .btn-primary,
.header-style-right-topbar-main .header-search-wrap .search-button-wrap .btn-primary:focus {
  box-shadow: none;
}

.header-style-right-topbar-main .header-search-wrap .search-button-wrap span {
  display: none;
}

.header-style-right-topbar-main .header-nav-right-wrapper {
  text-align: right;
  padding-right: 0px;
}

.header-style-right-topbar-main .header-nav-right-wrapper .ciya-tools {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

.header-style-right-topbar-main .header-main-bg-color-default {
  background-color: transparent;
  padding: 5px 15px;
}

.header-style-right-topbar-main .header-nav {
  background: transparent !important;
}

.header-style-right-topbar-main .mega-menu .menu-links > li > a {
  color: #323232;
}

.header-style-right-topbar-main .border-left {
  border-color: #ddd;
}

#primary-menu i.hot-label,
#primary-menu i.new-label,
#primary-menu i.popular-label,
#primary-menu i.sale-label {
  background: #ffba03;
  color: #fff;
  font-size: 9px;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  padding: 2px 5px;
  line-height: 16px;
  margin-left: 5px;
  border-radius: 2px;
  position: relative;
  top: -2px;
}

.mobile-menu {
  display: none;
}

.search-modal {
  margin: 20px;
  background: #fff;
}

.search-modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered-footer{
  min-height: calc(100% - 3.5rem) !important;
}

.text-transform-none{
  text-transform: none !important;
}

.search-modal .search-wrapper {
  max-width: 100%;
  margin: 0;
  display: table;
  width: 100%;
  height: 100%;
}

.search-modal .search-wrapper .search-modal-content {
  background: 0 0;
  border: none;
  padding: 0;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap {
  float: none;
  text-align: left;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search-form {
  align-items: center;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-inner {
  padding: 0;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-inner .search_form-input-wrap {
  width: 100%;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-inner input.form-control {
  width: 100%;
  min-width: 100%;
  border-radius: 0;
  width: 100%;
  height: 55px;
  padding: 0 15px;
  font-size: 16px;
  border: none;
  padding-right: 60px;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-inner .select2-selection.select2-selection--single {
  border-radius: 0;
  background: transparent;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-inner .search_form-category-wrap {
  position: relative;
  padding-right: 10px;
  vertical-align: middle;
  display: table-cell;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-inner .search_form-category-wrap:before {
  content: "";
  position: absolute;
  top: 20%;
  right: 0;
  width: 1px;
  height: 60%;
  background: #ddd;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-search-button {
  width: 50px;
  height: 55px;
}

.search-modal .search-wrapper .search-modal-content .search_form-wrap .search_form-search-button:before {
  line-height: 55px;
}

.search-modal .search-wrapper .search-modal-content .modal-header {
  background: 0 0;
  border: none;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 40px;
  right: 40px;
}

.search-modal .search-wrapper .search-modal-content .modal-header .close span {
  font-size: 0;
}

.search-modal .search-wrapper .search-modal-content .modal-header .close span:before {
  content: "\f156";
  font-family: pgsicon-ecommerce;
  font-size: 22px;
}

.search-modal .search-wrapper .search-modal-content .search-modal-content-inner {
  width: 50%;
  margin: auto;
}

.search-modal .search-wrapper .search-modal-content .search-modal-content-inner .search_form-keywords-title {
  font-size: 22px;
  text-align: center;
  padding: 60px 0 20px 0;
  color: #323232;
  font-weight: 600;
}

.search-modal .search-wrapper .search-modal-content .search-modal-content-inner .search_form-keywords-list {
  text-align: center;
}

.search-modal .search-wrapper .search-modal-content .search-modal-content-inner .search_form-keywords-list li {
  list-style: none;
  display: inline-block;
}

.search-modal .search-wrapper .search-modal-content .search-modal-content-inner .search_form-keywords-list li a {
  display: inline-block;
  color: #969696;
  line-height: 24px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 5px 3px;
  padding: 4px 18px;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
}

.compare-modal {
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.9);
}

.compare-modal.show {
  display: block;
  visibility: visible;
  opacity: 1;
}

.compare-modal .compare-wrapper {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11000;
  width: 100%;
  height: 100%;
  border: 20px solid #fff;
  overflow: hidden;
  overflow-y: auto;
}

.compare-modal .compare-content {
  padding: 10px;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.compare-modal .compare-content .compare-title {
  position: relative;
  margin-bottom: 15px;
}

.compare-modal .compare-content .compare-title h1 {
  background: 0 0;
  font-size: 22px;
  color: var(--sage);
  font-weight: 600;
  margin-bottom: 5px;
  padding: 10px 0;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
  text-align: center;
}

.compare-modal .compare-content .compare-title button.close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.compare-modal .compare-content .compare-title button.close:focus {
  box-shadow: none;
  outline: none;
}

.compare-modal .compare-content table td,
.compare-modal .compare-content table th {
  vertical-align: middle;
}

.compare-modal .compare-content table th {
  width: 20%;
}

.compare-modal .compare-content table td {
  width: 26%;
}

.compare-modal .compare-content table tbody tr th {
  text-align: right;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}

.compare-modal .compare-content table tbody tr td {
  text-align: center;
}

.compare-modal .compare-content table tbody tr td:nth-of-type(odd) {
  background: #f7f7f7;
}

.compare-modal .compare-content table tbody tr td a {
  color: #969696;
}

.compare-modal .compare-content table tbody tr td a span {
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 16px;
  border-radius: 18px;
  display: inline-block;
  font-weight: 600;
  color: red;
  font-size: 1em;
}

.compare-modal .compare-content table tbody tr td a span:hover {
  background-color: red;
  color: #fff;
}

.compare-modal .compare-content table tbody tr.image .image-wrap img {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #DADADA;
  height: auto;
  max-width: 100%;
  padding: 3px;
  margin-bottom: 20px;
  max-width: none;
  width: 220px;
}

.compare-modal .compare-content table tbody tr.price .ciya-Price-amount.amount {
  font-size: 16px;
  text-decoration: none;
  padding: 0 5px;
  display: inline-block;
  font-weight: 600;
  color: var(--sage);
}



.compare-modal .compare-content table tbody tr.add-to-cart a {
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  margin: 10px 0;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}

.compare-modal .compare-content table tbody tr.add-to-cart a:hover {
  background: #323232;
}

.compare-modal .compare-content table tbody tr.description p,
.compare-modal .compare-content table tbody tr.description span,
.compare-modal .compare-content table tbody tr.description td,
.compare-modal .compare-content table tbody tr.dimensions p,
.compare-modal .compare-content table tbody tr.dimensions span,
.compare-modal .compare-content table tbody tr.dimensions td,
.compare-modal .compare-content table tbody tr.pa-size p,
.compare-modal .compare-content table tbody tr.pa-size span,
.compare-modal .compare-content table tbody tr.pa-size td,
.compare-modal .compare-content table tbody tr.sku p,
.compare-modal .compare-content table tbody tr.sku span,
.compare-modal .compare-content table tbody tr.sku td {
  color: #969696;
}

.compare-modal .compare-content table tbody tr.stock span {
  color: #009900;
}

.compare-modal .compare-content table tbody .remove th,
.compare-modal .compare-content table tbody .remove td {
  border-top: none;
  border-bottom: none;
}

.compare-modal .compare-content table tbody .image th,
.compare-modal .compare-content table tbody .image td {
  border-top: none;
}

.compare-modal .compare-content table tbody tr.title td {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: #323232;
}

/* Responsive */
@media screen and (max-width:991px) {
  .header-style-menu-center .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-menu {
    display: block;
  }

  #mainMenu,
  .slicknav_btn,
  .topbar {
    display: none;
  }

  header.site-header .ciya-tools {
    margin-left: 0;
  }

  header.site-header .ciya-tools .ciya-tools-action i {
    font-size: 18px;
  }

  .mobile-butoon-menu {
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 0 8px;
    padding-right: 0px;
  }

  .mobile-butoon-menu a {
    text-align: center;
    display: block;
    color: #323232;
    font-size: 18px;
    position: relative;
    width: 20px;
    height: 24px;
    margin-left: 5px;
  }

  .mobile-butoon-menu a span {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #323232;
    left: 0;
    top: 11px;
  }

  .mobile-butoon-menu a span:after,
  .mobile-butoon-menu a span:before {
    -webkit-transition: all .2s ease-in-out 0s;
    -moz-transition: all .2s ease-in-out 0s;
    transition: all .2s ease-in-out 0s;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #323232;
    left: 0;
  }

  .mobile-butoon-menu a span:before {
    top: -7px;
  }

  .mobile-butoon-menu a span:after {
    top: 7px;
  }

  .slicknav_menu {
    background: #fff;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    z-index: 99;
    padding: 0px 0;
    -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    text-align: left;
    margin-top: -1px;
  }

}

/*-----------------Header mobile menu---------------------*/
header.site-header .dropdown-toggle:after {
  content: "\f107";
  font-family: FontAwesome;
  margin-left: 12px;
  vertical-align: middle;
  border: medium none;
}

.navbar-nav .dropdown-menu {
  -webkit-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 25px -3px rgba(0, 0, 0, 0.1);
  border: medium none;
}

.navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-right.show {
  transform: rotateX(0deg);
  transition: all 400ms ease 0s;
  padding: 0;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
  position: absolute;
  top: 59px;
  left: auto;
  width: 100%;
  right: auto;
  transition: all 400ms ease 0s;
  -webkit-transition: all 400ms ease 0s;
  -o-transition: all 400ms ease 0s;
  transform: rotateX(90deg);
  transform-origin: 0 0;
  margin-top:-5px;
}

.navbar-nav .dropdown-menu .dropdown-item:first-child {
  padding-top: 10px;
}
.navbar-nav .dropdown-menu .dropdown-item{
  padding: 10px 20px;
}
.navbar-nav .dropdown-menu .dropdown-item:last-child {
  padding-bottom: 10px;
}

.navbar-nav .dropdown-menu .dropdown-item {
  padding: 6px 20px;
}

.navbar-nav .dropdown-menu .dropdown-item a {
  color: #969696;
}

.navbar-nav .dropdown-menu .dropdown-item:hover {
  background: transparent;
}

.navbar-nav .dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--sage);
}

.navbar-nav .dropdown-menu .dropdown-item  a {
  color: #969696;
  padding: 8px 15px 8px 15px;
  display: block;
}

.navbar-light .navbar-nav .nav-link {
  color: #323232;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--sage);
}

nav.navbar.navbar-light.bg-faded {
  display: none;
}

.navbar.navbar-light .navbar-toggler {
  border: medium none;
  position: absolute;
  left: 0px;
  top: -54px;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
  position: absolute;
  top: 100%;
  left: auto;
  right: auto;
  transition: all 0.3s ease-in-out;
  transform: rotateX(90deg);
  transform-origin: 0 0;
}

.navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-right.show {
  transform: rotateX(0deg);
  transition: all 0.3s ease-in-out;
}

.navbar-expand-md .navbar-nav .dropdown-menu.show {
  transform: rotateX(0deg);
  transition: all 0.3s ease-in-out;
}

.navbar-nav .dropdown-menu .dropdown-item a {
  color: #323232;
}

.navbar-light .navbar-nav .nav-link {
  color: #323232;
  font-size: 14px;
  padding: 29px 10px;
  text-transform: uppercase;
}

nav.navbar.navbar-light.bg-faded {
  display: none;
}

.border-nav{
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.dropdown-menu-right{
  width: 100%;
  border: none;
}

.buttonsubmenu{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  border: none;
  margin-bottom: 0px;
  display: block;
  background-color: white !important;
  letter-spacing: normal !important;
  color: var(--black) !important;
  text-transform: uppercase;
}

.buttonsubmenu:not(:disabled):not(.disabled).active:focus{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  display: block;
  line-height: 1.25;
  border: none;
  background-color: white !important;
  letter-spacing: normal !important;
  color: var(--black) !important;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
}

.navbar.navbar-light .navbar-toggler {
  border: medium none;
  position: absolute;
  left: 0px;
  top: -54px;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("../images/toggle.svg")
}

.icon-filter {
  background-image: url("../images/filter.svg")
}


.share-icon {
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  word-wrap: break-word;
  color:black;
  width: 100%;
  padding-left:10px;
}

.share-image{
  margin-right:10px;
}

/********************************
    footer-default
********************************/
footer.site-footer {
  background: #fff;
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
}

footer.site-footer .footer-widgets-wrapper {
  padding: 30px 0 35px;
}

footer.site-footer .logo-wrapper {
  text-align: left;
}

footer.site-footer .logo-wrapper img {
  max-width: 200px;
}

footer.site-footer .footer-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #323232;
}

footer.site-footer .widget {
  margin-top: 30px;
}

footer.site-footer .widget ul {
  margin-bottom: 0;
  padding-left: 0;
}

footer.site-footer .widget ul li {
  margin: 5px 0;
  line-height: 30px;
  list-style-type: none;
}

footer.site-footer .widget ul li a {
  color: #969696;
  display: inline-block;
  line-height: 24px;
  font-weight: 400;
  text-transform: capitalize;
}

footer.site-footer .widget ul li:hover a {
  color: var(--sage);
}

footer.site-footer .pgs-social-profiles .social-profiles ul {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0;
}

footer.site-footer .pgs-social-profiles .social-profiles ul li {
  display: inline-block;
  margin: 0;
  margin-right: 7px;
  margin-bottom: 7px;
  float: left;
  list-style-type: none;
}

footer.site-footer .pgs-social-profiles .social-profiles ul li a i {
  border: 1px solid rgba(173, 173, 173, 0.5);
  font-size: 13px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  vertical-align: top;
  text-align: center;
  color: #969696;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

footer.site-footer .pgs-social-profiles .social-profiles ul li a:hover i {
  background-color: var(--sage);
  border-color: var(--sage);
  color: #fff;
}

footer.site-footer .footer-nav-menu .footer-title {
  margin-bottom: 25px;
  text-transform: capitalize;
}

footer.site-footer .footer-nav-menu ul {
  padding-left: 0;
  margin-bottom: 0;
}

footer.site-footer .footer-nav-menu ul li {
  list-style-type: none;
}

footer.site-footer .footer-nav-menu ul li a {
  position: relative;
  padding: 0;
  padding-left: 15px;
  -webkit-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

footer.site-footer .footer-nav-menu ul li a:after,
footer.site-footer .footer-nav-menu ul li a:before {
  content: "\f105";
  position: absolute;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  opacity: 1;
  -webkit-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

footer.site-footer .footer-nav-menu ul li a:after {
  left: -10px;
  opacity: 0;
}

footer.site-footer .footer-nav-menu ul li a:hover:after {
  left: 0px;
  opacity: 1;
}

footer.site-footer .footer-nav-menu ul li a:hover:before {
  left: 10px;
  opacity: 0;
}

footer.site-footer .pgs-contact-widget ul li i {
  display: table-cell;
  padding-right: 10px;
  min-width: 24px;
  color: var(--sage);
}

footer.site-footer .pgs-contact-widget ul li span {
  display: table-cell;
}

footer.site-footer .pgs-newsletter-widget .newsletter_form {
  position: relative;
}

footer.site-footer .pgs-newsletter-widget .newsletter_form .newsletter-email {
  margin-bottom: 0;
  padding-right: 55px;
  color: #323232;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  padding: 0 15px;
  height: 46px;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
}

footer.site-footer .pgs-newsletter-widget .newsletter_form .newsletter-email:focus {
  border-color: var(--sage);
}

footer.site-footer .pgs-newsletter-widget .newsletter_form .button-area .input-group-btn > .btn {
  width: 43px;
  height: 46px;
  position: absolute;
  font-size: 0;
  top: 0;
  right: 0;
  padding: 0;
  line-height: 46px;
  background: var(--sage);
  color: #fff;
  border-radius: 0 2px 2px 0;
  overflow: hidden;
}

footer.site-footer .pgs-newsletter-widget .newsletter_form .button-area .input-group-btn > .btn:before {
  content: "\f1d9";
  font-family: FontAwesome;
  font-size: 14px;
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li {
  list-style-type: none;
  color: #969696;
  margin: 3px 0;
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li i {
  margin-right: 8px;
  color: var(--sage);
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li span {
  font-weight: 600;
  width: 120px;
  display: inline-block;
}

footer.site-footer .pgs-opening-widget .pgs-opening-hours ul li label {
  margin-bottom: 0;
}

footer.site-footer .pgs-instagram-widget .pgs-instgram-widget .instgram-item {
  width: 29%;
  margin-top: 10px;
  margin-right: 10px;
  float: left;
}

footer.site-footer .pgs-instagram-widget .pgs-instgram-widget .instgram-item:nth-child(-n+3) {
  margin-top: 0;
}

footer.site-footer .pgs-gallery-widget .pgs-gallery {
  display: inline-block;
  width: 100%;
}

footer.site-footer .pgs-gallery-widget .pgs-gallery .gallery-item {
  border: 1px solid #ddd;
  margin-right: 4%;
  margin-bottom: 4%;
  border-radius: 3px;
  width: 29%;
  float: left;
}

footer.site-footer .pgs-gallery-widget .pgs-gallery .gallery-item img {
  margin-bottom: 0;
  border-radius: 3px;
}

footer.site-footer .pgs-recent-entries .recent-post {
  border-bottom: none;
  margin-bottom: 15px;
  padding-bottom: 0;
  display: table;
  width: 100%;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-image {
  width: 65px;
  display: table-cell;
  vertical-align: middle;
  padding-right: 12px;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-info {
  display: table-cell;
  vertical-align: middle;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-info a {
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  line-height: 20px;
  margin-bottom: 3px;
  display: inline-block;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-info .post-date {
  display: block;
  font-size: 12px;
}

footer.site-footer .pgs-recent-entries .recent-post .recent-post-info .post-date i {
  margin-right: 7px;
  color: var(--sage);
}

footer.site-footer .pgs-apps-store .apps-store-img .apps-store-item {
  margin-bottom: 3px;
  margin-right: 3px;
  display: inline-block;
}

footer.site-footer .pgs-apps-store .apps-store-img .apps-store-item img {
  width: 140px;
}

footer.site-footer .pgs-products .product-list-widget li {
  padding: 10px 0;
}

footer.site-footer .pgs-products .product-list-widget li a {
  font-weight: 500;
  color: #323232;
  font-size: 13px;
}

footer.site-footer .pgs-products .product-list-widget li a .media img {
  margin-right: 15px;
  margin-left: 0;
  width: 60px;
}

footer.site-footer .pgs-products .product-list-widget li a .media .amount {
  color: var(--sage);
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom {
  padding-bottom: 40px;
  padding-top: 40px;
  border-top: 1px solid rgba(142, 142, 142, 0.2);
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .pgs-social-profiles {
  margin-bottom: 20px;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .pgs-social-profiles .social-profiles ul li a i {
  background-color: transparent;
  border: none;
  width: auto;
  height: auto;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .pgs-social-profiles .social-profiles ul li a:hover i {
  background-color: none;
  border: none;
  color: var(--sage);
}

footer.site-footer .footer-bottom-wrapper .footer-bottom h5 {
  display: inline-block;
  margin-bottom: 0;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .apps-store-img {
  margin-bottom: 3px;
  margin-right: 3px;
  display: inline-block;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .pgs-newsletter-widget .newsletter_form {
  position: relative;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .pgs-newsletter-widget .newsletter_form .button-area .input-group-btn > .btn.submit {
  width: auto;
  background: none;
  padding: 0 15px;
  padding-left: 25px;
  font-size: 13px;
  font-weight: 600;
  line-height: 46px;
  color: #000000;
  text-transform: uppercase;
  -webkit-border-radius: 0 50px 50px 0;
  -moz-border-radius: 0 50px 50px 0;
  border-radius: 0 50px 50px 0;
}

footer.site-footer .footer-bottom-wrapper .footer-bottom .pgs-newsletter-widget .newsletter_form .button-area .input-group-btn > .btn:before {
  margin-right: 14px;
}

footer.site-footer .site-info {
  font-weight: 600;
  font-size:11px;
  color: #000000;
  text-transform: uppercase;
  background:#f4f1f173;
  padding: 25px 0;
}

footer.site-footer .site-info .footer-widget p {
  margin-bottom: 0;
  color: #000000;
}

footer.site-footer .site-info .footer-widget p a {
  color: #000000;
}

footer.site-footer .site-info .footer-widget p a:hover {
  color: #000000;
}

footer.site-footer .product-tag-cloud .tagcloud a {
  background: 0 0;
  color: #000000;
  margin-bottom: 8px;
  margin-right: 4px;
  display: inline-block;
  border: 1px solid #f2f2f2;
  line-height: 28px;
  padding: 0 12px;
  text-transform: uppercase;
  border-radius: 2px;
}

footer.site-footer .product-tag-cloud .tagcloud a:hover,
footer.site-footer .product-tag-cloud .tagcloud a:focus {
  background: var(--sage);
  color: #fff;
  border-color: var(--sage);
}

footer.site-footer .product-tag-cloud .tagcloud a.tag-cloud-link {
  border: 1px solid rgba(150, 150, 150, 0.25);
  padding: 0 17px;
  font-size: 13px !important;
}

footer.site-footer .footer-align-left {
  text-align: left;
}

footer.site-footer .footer-align-center {
  text-align: center;
}

footer.site-footer .footer-align-center .footer-nav-menu ul li a {
  padding-left: 0;
}

footer.site-footer .footer-align-center .footer-nav-menu ul li a:before,
footer.site-footer .footer-align-center .footer-nav-menu ul li a:after {
  content: "";
}

footer.site-footer .footer-align-center .pgs-contact-widget ul li i {
  display: inline-block;
}

footer.site-footer .footer-align-center .pgs-contact-widget ul li span {
  display: inline-block;
}

footer.site-footer .footer-align-right {
  text-align: right;
}

/********************************
    footer-style-2
********************************/
footer.site-footer.footer-style-2 .footer-widgets {
  text-align: center;
}

footer.site-footer.footer-style-2 .footer-nav-menu ul li {
  padding: 0 10px;
  display: inline-block;
}

footer.site-footer.footer-style-2 .footer-widgets-wrapper {
  padding: 30px 0 50px;
}

/********************************
    footer-style-4
********************************/
@media (min-width:1200px) {
  .container {
    max-width: 1300px;
  }

}

#preloader {
  opacity: 0.6;
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99999999;
}

#preloader > div {
  height: 100%;
  position: relative;
  width: 100%;
}

#preloader > div svg {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}


#preloader-modal-cart {
  opacity: 0.6;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99999999;
}

#preloader-modal-cart > div {
  height: 100%;
  position: relative;
  width: 100%;
}

#preloader-modal-cart > div svg {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#back-to-top .top {
  z-index: 998;
  margin: 0;
  color: #fff;
  position: fixed;
  bottom: 50px;
  right: 40px;
  font-size: 24px;
  background: var(--sage);
  width: 40px;
  height: 40px;
  line-height: 37px;
  text-align: center;
  display: inline-block;
  -webkit-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.header_intro_bg-image {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center center !important;
  background-image: url("../images/backgrounds/img-01.jpeg") !important;
}

.header_intro_opacity::before {
  background-color: rgba(30, 30, 30, 0.8);
}

.inner-intro {
  height: auto !important;
  background: #fbfbfb;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
}

.inner-intro.header_intro {
  height: 200px !important;
  position: relative;
  z-index: 0;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.03);
  -ms-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.03);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.03);
}

.inner-intro.header_intro.header_intro_opacity-custom:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.inner-intro.header_intro h1 {
  color: #fff;
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  text-transform: capitalize;
}

.inner-intro.header_intro .page-breadcrumb {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  width: 100%;
  margin-bottom: 0;
  margin-top: 10px;
}

.inner-intro.header_intro .page-breadcrumb li {
  line-height: 22px;
  color: #fff;
  display: inline;
  list-style: none;
  position: relative;
  margin-right: 5px;
  padding-left: 8px;
}

.inner-intro.header_intro .page-breadcrumb li:before {
  content: "/";
  font-family: FontAwesome;
  position: relative;
  left: -8px;
  height: 20px;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.inner-intro.header_intro .page-breadcrumb li.home:before {
  content: "\f015";
  line-height: 19px;
}

.inner-intro.header_intro .page-breadcrumb li span {
  position: relative;
  padding-left: 0;
}

.inner-intro.header_intro .page-breadcrumb li span a {
  color: inherit;
}

.inner-intro.header_intro .page-breadcrumb li:last-child {
  margin-right: 0;
}

.inner-intro.header_intro .page-breadcrumb li:hover a,
.inner-intro.header_intro .page-breadcrumb li:focus a {
  color: var(--sage);
}

.inner-intro.header_intro .page-breadcrumb li:hover.home:before,
.inner-intro.header_intro .page-breadcrumb li:focus.home:before {
  color: var(--sage);
}

.inner-intro h1 {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 0;
  word-wrap: break-word;
  text-transform: capitalize;
  font-weight: 600;
}

.inner-intro .page-breadcrumb {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  width: 100%;
  margin-bottom: 0;
}

.inner-intro .page-breadcrumb li {
  line-height: 22px;
  color: #323232;
  display: inline;
  list-style: none;
  position: relative;
  margin-right: 5px;
  padding-left: 8px;
}

.inner-intro .page-breadcrumb li:before {
  content: "/";
  font-family: FontAwesome;
  position: relative;
  left: -8px;
  height: 20px;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.inner-intro .page-breadcrumb li.home:before {
  content: "\f015";
  line-height: 19px;
}

.inner-intro .page-breadcrumb li span {
  position: relative;
  padding-left: 0;
}

.inner-intro .page-breadcrumb li span a {
  color: inherit;
}

.inner-intro .page-breadcrumb li:last-child {
  margin-right: 0;
}

.inner-intro .page-breadcrumb li:hover a,
.inner-intro .page-breadcrumb li:focus a {
  color: var(--sage);
}

.inner-intro .page-breadcrumb li:hover.home:before,
.inner-intro .page-breadcrumb li:focus.home:before {
  color: var(--sage);
}

.inner-intro .container {
  height: 100%;
}

.inner-intro .intro-title {
  height: 100%;
  position: relative;
  z-index: 9;
}

.inner-intro .intro-title-inner {
  margin-bottom: 0;
  width: 100%;
}

.inner-intro .intro-section-center .intro-title-inner,
.inner-intro .intro-section-center .page-breadcrumb {
  text-align: center;
  padding-bottom: 0;
}

.inner-intro .intro-section-right .intro-title-inner,
.inner-intro .intro-section-right .page-breadcrumb {
  text-align: right;
}

.inner-intro .intro-section-left .intro-title-inner,
.inner-intro .intro-section-left .page-breadcrumb {
  text-align: left;
}

.section-title h1,
.section-title h2,
.section-title h3,
.section-title h4,
.section-title h5,
.section-title h6 {
  font-weight: 700;
}

.bg-dark-gray .section-title h1,
.bg-dark-gray .section-title h2,
.bg-dark-gray .section-title h3,
.bg-dark-gray .section-title h4,
.bg-dark-gray .section-title h5,
.bg-dark-gray .section-title h6 {
  color: #fff;
}

.bg-dark-gray p {
  color: #fff;
}

.table {
  border: 1px solid #e8e8e8;
  border-radius: 0;
}

.table th {
  line-height: 1.5em;
  font-weight: 700;
}

.svelta_info_box-content_alignment-center {
  text-align: center;
}

.svelta_info_box-content_alignment-center .svelta_info_box-icon-inner {
  margin: 0 auto;
}

.svelta_info_box-layout-style_1.svelta_info_box-content_alignment-center .svelta_info_box-icon,
.svelta_info_box-layout-style_1.svelta_info_box-content_alignment-right .svelta_info_box-icon {
  display: inline-block;
}

.svelta_info_box-layout-style_1 .svelta_info_box-icon {
  margin-bottom: 15px;
}

.svelta_info_box-content_alignment-right {
  text-align: right;
}

.svelta_info_box-layout-style_2 .svelta_info_box-inner {
  clear: both;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-xs .svelta_info_box-icon {
  font-size: 22px;
  line-height: 22px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-icon {
  width: 65px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-sm .svelta_info_box-icon {
  font-size: 33px;
  line-height: 33px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-icon {
  width: 77px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-md .svelta_info_box-icon {
  font-size: 44px;
  line-height: 44px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-icon {
  width: 87px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-lg .svelta_info_box-icon {
  font-size: 55px;
  line-height: 55px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-icon {
  width: 75px;
  padding-left: 0;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-xs .svelta_info_box-icon i:before {
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 15px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-sm .svelta_info_box-icon i:before {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 15px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-md .svelta_info_box-icon i:before {
  font-size: 38px;
  line-height: 48px;
  margin-bottom: 15px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-lg .svelta_info_box-icon i:before {
  font-size: 48px;
  line-height: 88px;
  margin-bottom: 15px;
}

.svelta_info_box-with-icon.svelta_info_box-icon_position-left .svelta_info_box-inner {
  display: table;
  width: 100%;
}

.svelta_info_box-with-icon.svelta_info_box-icon_position-left .svelta_info_box-icon {
  width: 6%;
  padding-right: 20px;
  display: table-cell;
  vertical-align: top;
}

.svelta_info_box-with-icon.svelta_info_box-icon_position-left .left-info-icon-bottom .svelta_info_box-icon {
  width: 87px;
  float: left;
}

.svelta_info_box-with-icon.svelta_info_box-icon_position-left .svelta-info-left-icon .svelta_info_box-icon {
  display: table-cell;
  float: none;
}

.svelta_info_box-with-icon.svelta_info_box-icon_position-right .svelta_info_box-icon {
  float: right;
  padding-left: 15px;
}

.col-sm-3 .svelta_info_box-with-icon.svelta_info_box-icon_position-right .svelta_info_box-content {
  width: 78%;
}

.col-sm-4 .svelta_info_box-with-icon.svelta_info_box-icon_position-right .svelta_info_box-content {
  width: 83%;
}

.col-sm-5 .svelta_info_box-with-icon.svelta_info_box-icon_position-right .svelta_info_box-content {
  width: 87%;
}

.col-sm-6 .svelta_info_box-with-icon.svelta_info_box-icon_position-right .svelta_info_box-content {
  width: 90%;
}

.col-sm-3 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 70%;
}

.col-sm-4 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 75%;
}

.col-sm-5 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 72%;
}

.col-sm-6 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 77%;
}

.col-sm-3 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 75%;
}

.col-sm-4 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 67%;
}

.col-sm-5 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 74%;
}

.col-sm-6 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 79%;
}

.col-sm-3 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 78%;
}

.col-sm-4 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 70%;
}

.col-sm-5 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 76%;
}

.col-sm-6 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 81%;
}

.col-sm-3 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 80%;
}

.col-sm-4 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 73%;
}

.col-sm-5 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 78%;
}

.col-sm-6 .svelta_info_box-icon-style-flat.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 82%;
}

.col-sm-3 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 72%;
}

.col-sm-4 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 66%;
}

.col-sm-5 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 72%;
}

.col-sm-6 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-lg .svelta_info_box-content {
  width: 77%;
}

.col-sm-3 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 73%;
}

.col-sm-4 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 77%;
}

.col-sm-5 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 74%;
}

.col-sm-6 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-md .svelta_info_box-content {
  width: 76%;
}

.col-sm-3 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 75%;
}

.col-sm-4 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 72%;
}

.col-sm-5 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 76%;
}

.col-sm-6 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-sm .svelta_info_box-content {
  width: 81%;
}

.col-sm-3 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 78%;
}

.col-sm-4 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 75%;
}

.col-sm-5 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 78%;
}

.col-sm-6 .svelta_info_box-icon-style-border.svelta_info_box-icon_position-right.svelta_info_box-icon-size-xs .svelta_info_box-content {
  width: 82%;
}

.svelta_info_box-with-icon.svelta_info_box-icon-style-border .svelta_info_box-icon-inner,
.svelta_info_box-with-icon.svelta_info_box-icon-style-border .svelta_info_box-icon-outer {
  display: inline-block;
}

.svelta_info_box-with-icon.svelta_info_box-icon-style-flat .svelta_info_box-icon-inner,
.svelta_info_box-with-icon.svelta_info_box-icon-style-flat .svelta_info_box-icon-outer {
  display: inline-block;
}

.svelta_info_box-with-icon.svelta_info_box-icon-style-flat .svelta_info_box-icon-inner {
  background-color: #878787;
  color: #fff;
}

.svelta_info_box-icon-shape-rounded .svelta_info_box-icon-inner,
.svelta_info_box-icon-shape-rounded .svelta_info_box-icon-outer {
  border-radius: 5px;
}

.svelta_info_box-icon-shape-round .svelta_info_box-icon-inner,
.svelta_info_box-icon-shape-round .svelta_info_box-icon-outer {
  border-radius: 50%;
}

.svelta_info_box-icon-style-border .svelta_info_box-icon-inner {
  border: 1px solid #878787;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-xs .svelta_info_box-icon-inner,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-xs .svelta_info_box-icon-inner {
  height: 50px;
  line-height: 50px;
  width: 50px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-sm .svelta_info_box-icon-inner,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-sm .svelta_info_box-icon-inner {
  height: 60px;
  line-height: 60px;
  width: 60px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-md .svelta_info_box-icon-inner,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-md .svelta_info_box-icon-inner {
  height: 70px;
  line-height: 70px;
  width: 70px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-lg .svelta_info_box-icon-inner,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-lg .svelta_info_box-icon-inner {
  height: 80px;
  line-height: 80px;
  width: 80px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-xs .svelta_info_box-icon-inner .vc-mono,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-xs .svelta_info_box-icon-inner .vc-mono {
  line-height: 11px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-sm .svelta_info_box-icon-inner .vc-mono,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-sm .svelta_info_box-icon-inner .vc-mono {
  line-height: 14px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-md .svelta_info_box-icon-inner .vc-mono,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-md .svelta_info_box-icon-inner .vc-mono,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-border.svelta_info_box-icon-size-lg .svelta_info_box-icon-inner .vc-mono,
.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-lg .svelta_info_box-icon-inner .vc-mono {
  line-height: 26px;
}

.svelta_info_box.svelta_info_box-icon-style-border .svelta_info_box-icon-inner,
.svelta_info_box.svelta_info_box-icon-style-flat .svelta_info_box-icon-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svelta_info_box.svelta_info_box-icon-style-border.svelta_info_box-icon-source-image .svelta_info_box-icon-inner,
.svelta_info_box.svelta_info_box-icon-style-flat.svelta_info_box-icon-source-image .svelta_info_box-icon-inner {
  display: inline-block;
}

.svelta_info_box-icon-shape-square .svelta_info_box-icon {
  font-size: 30px;
}

.svelta_info_box-outer-border .svelta_info_box-icon-outer {
  border: 1px solid #323232;
  padding: 5px;
}

.svelta_info_box-description p {
  margin-bottom: 0;
  line-height: 22px;
}

.svelta_info_box-title {
  margin-bottom: 10px;
  font-weight: 600;
}

.svelta_info_box-icon-source-image .svelta_info_box-icon {
  display: inline-block;
}

.svelta_info_box-icon-source-image .svelta_info_box-icon img {
  width: 100%;
  padding: 10px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-lg .svelta_info_box-icon img {
  padding: 20px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-md .svelta_info_box-icon img {
  padding: 16px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-sm .svelta_info_box-icon img {
  padding: 14px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-xs .svelta_info_box-icon img {
  padding: 12px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-xs .svelta_info_box-icon {
  width: 42px;
  margin-bottom: 15px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-sm .svelta_info_box-icon {
  width: 52px;
  margin-bottom: 15px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-md .svelta_info_box-icon {
  width: 62px;
  margin-bottom: 15px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-lg .svelta_info_box-icon {
  width: 82px;
  margin-bottom: 15px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-xs.svelta_info_box-icon_position-left .svelta_info_box-icon,
.svelta_info_box-icon-source-image.svelta_info_box-icon-size-xs.svelta_info_box-icon_position-right .svelta_info_box-icon {
  width: 82px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-sm.svelta_info_box-icon_position-left .svelta_info_box-icon,
.svelta_info_box-icon-source-image.svelta_info_box-icon-size-sm.svelta_info_box-icon_position-right .svelta_info_box-icon {
  width: 94px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-md.svelta_info_box-icon_position-left .svelta_info_box-icon,
.svelta_info_box-icon-source-image.svelta_info_box-icon-size-md.svelta_info_box-icon_position-right .svelta_info_box-icon {
  width: 99px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-size-lg.svelta_info_box-icon_position-left .svelta_info_box-icon,
.svelta_info_box-icon-source-image.svelta_info_box-icon-size-lg.svelta_info_box-icon_position-right .svelta_info_box-icon {
  width: 110px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-default .svelta_info_box-icon img {
  padding: 0;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-default.svelta_info_box-icon-size-xs .svelta_info_box-icon {
  width: 42px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-default.svelta_info_box-icon-size-sm .svelta_info_box-icon {
  width: 52px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-default.svelta_info_box-icon-size-md .svelta_info_box-icon {
  width: 62px;
}

.svelta_info_box-icon-source-image.svelta_info_box-icon-style-default.svelta_info_box-icon-size-lg .svelta_info_box-icon {
  width: 72px;
}

.svelta_info_box.svelta_info_box-icon-style-default.svelta_info_box-icon_position-left .svelta_info_box-icon {
  padding-right: 25px;
}

.svelta_info_box.svelta_info_box-icon-style-default.svelta_info_box-icon_position-left.svelta_info_box-icon-size-sm .svelta_info_box-icon {
  padding-right: 18px;
}

.info_box-step_position-above_title .svelta_info_box-inner {
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.info_box-step_position-above_title .svelta_info_box-step {
  font-size: 36px;
  font-weight: 700;
  color: #ddd;
}

.theme-dark .info_box-step_position-above_title .svelta_info_box-step {
  color: #fff;
}

.info_box-step_position-under_icon .svelta_info_box-step {
  font-weight: 700;
  color: #323232;
  opacity: .1;
  line-height: 95px;
}

.info_box-step_position-under_icon .svelta_info_box-icon-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.info_box-step_position-under_icon .svelta_info_box-step-wrapper {
  text-align: center;
}

.info_box-step_position-under_icon .svelta_info_box-icon-wrap {
  position: relative;
  min-width: 100px;
}

.info_box-step_position-under_icon .svelta_info_box-inner {
  padding: 15px;
  -webkit-box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.13);
}

.info_box-step_position-under_icon.svelta_info_box-icon-size-lg .svelta_info_box-step {
  font-size: 60px;
}

.info_box-step_position-under_icon.svelta_info_box-icon-size-md .svelta_info_box-step {
  font-size: 50px;
}

.info_box-step_position-under_icon.svelta_info_box-icon-size-sm .svelta_info_box-step {
  font-size: 40px;
}

.info_box-step_position-under_icon.svelta_info_box-icon-size-xs .svelta_info_box-step {
  font-size: 30px;
}

.info_box-step_position-opposite_icon .svelta_info_box-content {
  position: relative;
}

.info_box-step_position-opposite_icon .svelta_info_box-step-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}

.info_box-step_position-opposite_icon .svelta_info_box-step {
  font-size: 95px;
  font-weight: 700;
  color: #777;
  opacity: .1;
  line-height: 95px;
}

.info_box-step_position-under_icon.svelta_info_box-icon-source-image .svelta_info_box-icon-wrap,
.info_box-step_position-under_icon.svelta_info_box-with-icon .svelta_info_box-icon-wrap {
  min-width: inherit;
}

.info_box-step_position-under_icon.svelta_info_box-icon-source-image .svelta_info_box-icon img {
  padding: 4px;
}

.theme-dark .info_box-step_position-opposite_icon .svelta_info_box-step {
  color: #fff;
}

.svelta_info_box-layout-style_3 .svelta_info_box-content {
  margin-top: 15px;
}

.svelta_info_box-layout-style_3 .svelta_info_box-icon-wrap {
  position: relative;
  padding: 15px 0;
}

.svelta_info_box.svelta_info_box-layout-style_3 .svelta_info_box-icon-outer {
  display: table-cell;
  min-width: 70px;
  text-align: center;
}

.svelta_info_box.svelta_info_box-layout-style_3 .svelta_info_box-title {
  display: table-cell;
  padding: 0 0 0 15px;
  margin: 0;
  vertical-align: middle;
}

.svelta_info_box-layout-style_3 .svelta_info_box-step-wrapper {
  position: absolute;
  left: 0;
  top: 0;
}

.svelta_info_box-layout-style_3 .svelta_info_box-step {
  font-size: 85px;
  font-weight: 700;
  color: #000;
  opacity: .03;
  line-height: 85px;
}

.svelta_info_box-layout-style_3.svelta_info_box-icon-style-border .svelta_info_box-icon-outer,
.svelta_info_box-layout-style_3.svelta_info_box-icon-style-flat .svelta_info_box-icon-outer {
  margin-bottom: 15px;
}

.svelta_info_box-layout-style_3.svelta_info_box-content_alignment-center .svelta_info_box-title,
.svelta_info_box-layout-style_3.svelta_info_box-content_alignment-right .svelta_info_box-title {
  display: inherit;
}

.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-lg .svelta_info_box-icon,
.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-md .svelta_info_box-icon,
.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-sm .svelta_info_box-icon,
.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-xs .svelta_info_box-icon {
  width: inherit;
  margin-bottom: inherit;
}

.svelta_info_box-layout-style_3.svelta_info_box-content_alignment-right .svelta_info_box-step-wrapper {
  left: auto;
  right: 0;
}

.svelta_info_box.svelta_info_box-layout-style_3.svelta_info_box-content_alignment-right .svelta_info_box-icon-outer {
  display: inline-block;
  float: right;
}

.svelta_info_box-layout-style_3.svelta_info_box-content_alignment-right .svelta_info_box-title {
  display: inline-block;
  padding: 0 15px 0 0;
}

.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-lg .svelta_info_box-icon-outer {
  min-width: 70px;
}

.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-md .svelta_info_box-icon-outer {
  min-width: 65px;
}

.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-sm .svelta_info_box-icon-outer {
  min-width: 60px;
}

.svelta_info_box-layout-style_3.svelta_info_box-icon-source-image.svelta_info_box-icon-size-xs .svelta_info_box-icon-outer {
  min-width: 55px;
}

.svelta_info_box-layout-style_4 .svelta_info_box-step-wrapper {
  display: table-cell;
  padding-right: 15px;
  vertical-align: middle;
}

.svelta_info_box-layout-style_4 .svelta_info_box-step {
  color: var(--sage);
  margin-bottom: 0;
}

.svelta_info_box-layout-style_4 h2.svelta_info_box-step {
  font-size: 50px;
  line-height: 50px;
}

.svelta_info_box-layout-style_4 h3.svelta_info_box-step {
  font-size: 40px;
  line-height: 40px;
}

.svelta_info_box-layout-style_4 h4.svelta_info_box-step {
  font-size: 34px;
  line-height: 34px;
}

.svelta_info_box-layout-style_4 h5.svelta_info_box-step {
  font-size: 28px;
  line-height: 28px;
}

.svelta_info_box-layout-style_4 h6.svelta_info_box-step {
  font-size: 22px;
  line-height: 22px;
}

.svelta_info_box-layout-style_4 .svelta_info_box-title {
  display: table-cell;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  font-weight: 700;
}

.svelta_info_box-layout-style_4 .svelta_info_box-content {
  margin-top: 5px;
}

.svelta_info_box-layout-style_5 .svelta_info_box-icon {
  display: table-cell;
  padding-right: 65px;
  text-align: center;
  position: relative;
  vertical-align: top;
  padding-top: 2px;
}

.svelta_info_box-layout-style_5 .svelta_info_box-icon:before {
  content: "";
  position: absolute;
  top: 14px;
  left: 40px;
  width: 30px;
  height: 1px;
  background: #ccc;
}

.svelta_info_box-layout-style_5 .svelta_info_box-content {
  display: table-cell;
}

.svelta_info_box-layout-style_5 .svelta_info_box-icon-outer {
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
}

.svelta_info_box-layout-style_5 .svelta_info_box-icon-outer:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #969696;
}

.svelta_info_box-layout-style_5 .svelta_info_box-icon-outer .svelta_info_box-icon-inner {
  font-size: 24px;
}

.svelta_info_box-layout-style_5 .svelta_info_box-step {
  font-size: 16px;
  color: var(--sage);
  font-weight: 700;
  line-height: 22px;
  vertical-align: top;
}

.svelta_info_box-layout-style_5 .svelta_info_box-title {
  font-weight: 600;
}

.svelta_info_box-layout-style_5 h2.svelta_info_box-title {
  font-size: 26px;
  line-height: 32px;
}

.svelta_info_box-layout-style_5 h3.svelta_info_box-title {
  font-size: 24px;
  line-height: 30px;
}

.svelta_info_box-layout-style_5 h4.svelta_info_box-title {
  font-size: 22px;
  line-height: 28px;
}

.svelta_info_box-layout-style_5 h5.svelta_info_box-title {
  font-size: 20px;
  line-height: 26px;
}

.svelta_info_box-layout-style_5 h6.svelta_info_box-title {
  font-size: 18px;
  line-height: 22px;
}

.theme-dark .svelta_info_box-layout-style_5 .svelta_info_box-icon:before {
  background: rgba(255, 255, 255, 0.2);
}

.svelta_info_box-with-icon.svelta_info_box-icon-style-border.svelta_info_box-icon-size-md .svelta_info_box-icon i:before,
.svelta_info_box-with-icon.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-md .svelta_info_box-icon i:before {
  font-size: 32px;
}

.svelta_info_box-with-icon.svelta_info_box-icon-style-border.svelta_info_box-icon-size-lg .svelta_info_box-icon i:before,
.svelta_info_box-with-icon.svelta_info_box-icon-style-flat.svelta_info_box-icon-size-lg .svelta_info_box-icon i:before {
  font-size: 36px;
}

.svelta_info_box.svelta_info_box-layout-style_1.svelta_info_box-icon-style-default.svelta_info_box-icon-size-lg .svelta_info_box-icon i:before {
  line-height: 55px;
}

.svelta_info_box.svelta_info_box-layout-style_2.svelta_info_box-icon-style-default.svelta_info_box-icon-size-lg .svelta_info_box-icon i:before {
  line-height: 60px;
}

.info_box-step_position-above_title .svelta_info_box-step {
  line-height: 34px;
}

.info_box-step_position-under_icon .svelta_info_box-inner {
  -webkit-box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 10px 0;
}

.info_box-step_position-opposite_icon .svelta_info_box-step {
  font-size: 80px;
  line-height: 60px;
}

.svelta_info_box-layout-style_3 .svelta_info_box-icon-wrap {
  padding: 0;
}

.svelta_info_box.svelta_info_box-layout-style_3 .svelta_info_box-icon-outer {
  min-width: 20px;
}

.svelta_info_box-layout-style_3.svelta_info_box-with-icon.svelta_info_box-icon-source-font.svelta_info_box-icon-size-lg .svelta_info_box-icon i:before {
  line-height: 66px;
}

.svelta_info_box_2 .svelta_info_box_2-title {
  margin-bottom: 10px;
}

.svelta_info_box_2 .svelta_info_box_2-content p {
  margin-bottom: 0;
}

.svelta_info_box_2.svelta_info_box_2-icon-shape-rounded .svelta_info_box_2-icon-inner {
  border-radius: 3px;
}

.svelta_info_box_2.svelta_info_box_2-icon-shape-round .svelta_info_box_2-icon-inner {
  border-radius: 75px;
  overflow: hidden;
}

.svelta_info_box_2.svelta_info_box_2-icon-size-lg.svelta_info_box_2-icon-shape-round .svelta_info_box_2-icon-inner img {
  padding: 10px !important;
}

.svelta_info_box_2.svelta_info_box_2-icon-size-xlg.svelta_info_box_2-icon-shape-round .svelta_info_box_2-icon-inner img {
  padding: 15px !important;
}

.svelta_info_box_2-content_alignment-center {
  text-align: center;
}

.svelta_info_box_2-content_alignment-right {
  text-align: right;
}

.svelta_info_box_2-icon-style-default .svelta_info_box_2-icon {
  margin-bottom: 15px;
  display: inline-block;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-source-image .svelta_info_box_2-icon {
  margin-bottom: 20px;
}

.svelta_info_box_2-icon-style-default .svelta_info_box_2-icon-inner i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon-inner {
  font-size: 28px;
  line-height: 28px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon-inner {
  font-size: 35px;
  line-height: 35px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon-inner {
  font-size: 45px;
  line-height: 45px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon-inner {
  font-size: 55px;
  line-height: 55px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon-inner {
  font-size: 70px;
  line-height: 70px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-xs.svelta_info_box_2-icon_position-left .svelta_info_box_2-icon,
.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-xs.svelta_info_box_2-icon_position-right .svelta_info_box_2-icon {
  width: 35px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-sm.svelta_info_box_2-icon_position-left .svelta_info_box_2-icon,
.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-sm.svelta_info_box_2-icon_position-right .svelta_info_box_2-icon {
  width: 45px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-md.svelta_info_box_2-icon_position-left .svelta_info_box_2-icon,
.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-md.svelta_info_box_2-icon_position-right .svelta_info_box_2-icon {
  width: 55px;
}

.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-lg.svelta_info_box_2-icon_position-left .svelta_info_box_2-icon,
.svelta_info_box_2-icon-style-default.svelta_info_box_2-icon-size-lg.svelta_info_box_2-icon_position-right .svelta_info_box_2-icon {
  width: 65px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon-inner {
  font-size: 20px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon-inner {
  font-size: 25px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon-inner {
  font-size: 30px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon-inner {
  font-size: 38px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon-inner {
  font-size: 44px;
}

.svelta_info_box_2-icon-style-border .svelta_info_box_2-icon-inner {
  border-width: 2px;
  border-style: solid;
  border-color: var(--sage);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.svelta_info_box_2-icon-style-flat .svelta_info_box_2-icon-inner {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.svelta_info_box_2-icon-style-border .svelta_info_box_2-icon-inner img,
.svelta_info_box_2-icon-style-flat .svelta_info_box_2-icon-inner img {
  padding: 12px;
  width: 100%;
  height: 100%;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon-inner img,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon-inner img {
  padding: 4px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon-inner img,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon-inner img {
  padding: 5px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon-inner img,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon-inner img {
  padding: 6px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon-inner img,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon-inner img {
  padding: 8px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon-inner img,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon-inner img {
  padding: 10px;
}

.svelta_info_box_2.svelta_info_box_2-icon-style-border .svelta_info_box_2-icon,
.svelta_info_box_2.svelta_info_box_2-icon-style-flat .svelta_info_box_2-icon {
  display: inline-block;
  margin-bottom: 20px;
  color: var(--sage);
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon-inner {
  width: 45px;
  height: 45px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon-inner {
  width: 55px;
  height: 55px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon-inner {
  width: 75px;
  height: 75px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon-inner {
  width: 85px;
  height: 85px;
}

.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon-style-flat.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon-inner {
  width: 100px;
  height: 100px;
}

.svelta_info_box_2-icon_position-left {
  text-align: left;
}

.svelta_info_box_2-icon_position-right {
  text-align: right;
}

.svelta_info_box_2-icon_position-left .svelta_info_box_2-icon-inner,
.svelta_info_box_2-icon_position-right .svelta_info_box_2-icon-inner {
  margin-bottom: 0;
}

.svelta_info_box_2-icon_position-left .svelta_info_box_2-inner,
.svelta_info_box_2-icon_position-right .svelta_info_box_2-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.svelta_info_box_2-icon_position-left .svelta_info_box_2-inner {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.svelta_info_box_2-icon_position-right .svelta_info_box_2-inner {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.svelta_info_box_2-icon_position-left .svelta_info_box_2-inner .svelta_info_box_2-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 0;
  margin-right: 15px;
  text-align: center;
}

.svelta_info_box_2-icon-source-image .svelta_info_box_2-icon-inner {
  display: inline-block;
}

.svelta_info_box_2-icon-source-image.svelta_info_box_2-icon-size-xs .svelta_info_box_2-icon {
  width: 50px;
}

.svelta_info_box_2-icon-source-image.svelta_info_box_2-icon-size-sm .svelta_info_box_2-icon {
  width: 60px;
}

.svelta_info_box_2-icon-source-image.svelta_info_box_2-icon-size-md .svelta_info_box_2-icon {
  width: 70px;
}

.svelta_info_box_2-icon-source-image.svelta_info_box_2-icon-size-lg .svelta_info_box_2-icon {
  width: 85px;
}

.svelta_info_box_2-icon-source-image.svelta_info_box_2-icon-size-xlg .svelta_info_box_2-icon {
  width: 100px;
}

.svelta_info_box_2-icon_position-left .svelta_info_box_2-inner .svelta_info_box_2-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.svelta_info_box_2-icon_position-right .svelta_info_box_2-inner .svelta_info_box_2-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.svelta_info_box_2-icon_position-right .svelta_info_box_2-inner .svelta_info_box_2-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 0;
  margin-left: 15px;
  text-align: center;
}

.svelta_info_box_2-layout-style_3 .svelta_info_box_2-title {
  margin: 10px 0;
}

.svelta_info_box_2-layout-style_3 .svelta_info_box_2-inner {
  padding: 25px;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  min-height: 225px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.svelta_info_box_2.svelta_info_box_2-layout-style_1.animated .svelta_info_box_2-icon-inner,
.svelta_info_box_2.svelta_info_box_2-layout-style_2.animated .svelta_info_box_2-icon-inner {
  transition: all 0.3s ease-in-out;
}

.svelta_info_box_2.svelta_info_box_2-layout-style_1.animated:hover .svelta_info_box_2-icon-inner,
.svelta_info_box_2.svelta_info_box_2-layout-style_2.animated:hover .svelta_info_box_2-icon-inner {
  transform: scale(1.2);
}

.svelta_info_box_2.svelta_info_box_2-layout-style_2.svelta_info_box_2-content_alignment-left.svelta_info_box_2-with-icon.svelta_info_box_2-icon-source-font.svelta_info_box_2-icon-style-border.svelta_info_box_2-icon-size-lg.svelta_info_box_2-icon-shape-round.svelta_info_box_2-icon_position-left {
  padding-left: 0;
}

/* Address Block */
.address-block {
  position: relative;
  padding-left: 80px;
}

.address-block.default {
  padding-left: 70px;
}

.address-block.default i:before {
  font-size: 40px;
}

.address-block i {
  width: 60px;
  height: 60px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  line-height: 54px;
  color: var(--sage);
}

.address-block i:before {
  font-size: 26px;
}

.address-block .title {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
}

.address-block span {
  display: block;
  line-height: 20px;
}

.address-block.border {
  border: none !important;
}

.address-block.border i {
  border: 2px solid var(--sage);
}

.address-block.rounded i {
  border-radius: 10%;
}

.address-block.round i {
  border-radius: 100%;
}

.address-block.flat i {
  line-height: 60px;
  background: var(--sage);
  color: #fff;
}

/* Banners */
@-webkit-keyframes ciya-shine {
  100% {
    left: 125%;
  }

}

@keyframes ciya-shine {
  100% {
    left: 125%;
  }

}

.svelta_banner_wrapper {
  background-size: cover;
  background-position: center center;
}

.svelta_banner {
  position: relative;
  overflow: hidden;
  background: none;
  font-family: "Open Sans", sans-serif;
}

.svelta_banner .svelta_banner-image {
  -webkit-transition: .5s all ease-in-out;
  -moz-transition: .5s all ease-in-out;
  -ms-transition: .5s all ease-in-out;
  -o-transition: .5s all ease-in-out;
  transition: .5s all ease-in-out;
}

.svelta_banner .svelta_banner-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  padding: 25px;
  -webkit-transition: background .5s ease;
  -o-transition: background .5s ease;
  transition: background .5s ease;
}

.svelta_banner-style-style-1.svelta_banner .svelta_banner-content.svelta_banner-content-vtop {
  bottom: inherit;
}

.svelta_banner-style-style-1.svelta_banner .svelta_banner-content.svelta_banner-content-vbottom {
  top: inherit;
}

.svelta_banner-style-style-1.svelta_banner .svelta_banner-content.svelta_banner-content-vbottom .svelta_banner-content-wrapper {
  justify-content: center;
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper {
  vertical-align: top;
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-text {
  line-height: normal;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  font-size: 30px;
  font-weight: 500;
  color: #323232;
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap {
  position: relative;
  z-index: 9;
  margin-top: 15px;
  display: flex;
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap .svelta_banner-btn {
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap.svelta_banner-btn-style-link .svelta_banner-btn {
  font-weight: 600;
  border-bottom: 0px solid;
  padding-left: 0;
  padding-right: 0;
  color: var(--sage);
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap.svelta_banner-btn-size-md .svelta_banner-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 44px;
  padding: 0 25px;
  border: 2px solid #323232;
  color: #323232;
  background: none;
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap.svelta_banner-btn-size-md .svelta_banner-btn:hover,
.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap.svelta_banner-btn-size-md .svelta_banner-btn:focus {
  border: 2px solid var(--sage);
  color: var(--sage);
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap.svelta_banner-btn-style-flat .svelta_banner-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 44px;
  padding: 0 25px;
  background: var(--sage);
  color: #fff;
}

.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap.svelta_banner-btn-style-flat .svelta_banner-btn:hover,
.svelta_banner .svelta_banner-content .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap.svelta_banner-btn-style-flat .svelta_banner-btn:focus {
  background: #323232;
  color: #fff;
}




.svelta_banner .svelta_banner-content.svelta_banner-content-hcenter .svelta_banner-btn-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.svelta_banner .svelta_banner-content.svelta_banner-content-hright .svelta_banner-btn-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.svelta_banner .svelta_banner-content-hleft {
  text-align: left;
}

.svelta_banner .svelta_banner-content-hcenter {
  text-align: center;
}

.svelta_banner .svelta_banner-content-hright {
  text-align: right;
}

.svelta_banner .svelta_banner-content-wrapper {
  display: table;
  height: 100%;
  width: 100%;
}

.svelta_banner .svelta_banner-content-inner-wrapper {
  display: table-cell;
}

.svelta_banner .svelta_banner-content-vtop .svelta_banner-content-inner-wrapper {
  vertical-align: top;
  font-size: 70px;
}

.svelta_banner .svelta_banner-content-vmiddle .svelta_banner-content-inner-wrapper {
  vertical-align: middle;
}

.svelta_banner .svelta_banner-content-vbottom .svelta_banner-content-inner-wrapper {
  vertical-align: bottom;
}

.svelta_banner.svelta_banner-effect-zoom:hover .svelta_banner-image {
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08);
}

.svelta_banner.svelta_banner-effect-flash:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 150px;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.5) 100%);
  -webkit-transform: skewX(-25deg);
  -moz-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.svelta_banner.svelta_banner-effect-flash:hover:before {
  -webkit-animation: ciya-shine 1.5s;
  -moz-animation: ciya-shine 1.5s;
  animation: ciya-shine 1.5s;
  -webkit-transition: all .6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -ms-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
  left: -100%;
}

.svelta_banner.svelta_banner-effect-border:after,
.svelta_banner.svelta_banner-effect-border:before {
  content: "";
  position: absolute;
  opacity: 0;
  -webkit-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
  z-index: 2;
}

.svelta_banner.svelta_banner-effect-border:before {
  border-top: 1px dotted rgba(255, 255, 255, 0.7);
  border-bottom: 1px dotted rgba(255, 255, 255, 0.7);
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
  top: 30px;
  right: 15px;
  bottom: 30px;
  left: 15px;
}

.svelta_banner.svelta_banner-effect-border:after {
  border-left: 1px dotted rgba(255, 255, 255, 0.7);
  border-right: 1px dotted rgba(255, 255, 255, 0.7);
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  transform: scale(0, 1);
  top: 15px;
  right: 30px;
  bottom: 15px;
  left: 30px;
}

.svelta_banner.svelta_banner-effect-border:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.svelta_banner.svelta_banner-effect-border:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.svelta_banner .svelta_banner-badge {
  border-radius: 50%;
  color: #323232;
  display: inline-block;
  font-size: 15px;
  height: 70px;
  line-height: 15px;
  max-height: 100%;
  padding: 5px;
  position: absolute;
  width: 70px;
  margin: 30px;
}

.svelta_banner .svelta_banner-badge.svelta_banner-badge_type-flat {
  width: 50px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  background-color: #ff0000;
  color: #fff;
}

.svelta_banner .svelta_banner-badge.svelta_banner-badge_style-style-1.svelta_banner-badge_type-flat.svelta_banner-badge_align-vtop.svelta_banner-badge_align-hleft {
  width: 80px;
  height: 80px;
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
  background-color: #ffffff;
  bottom: inherit;
  color: var(--sage);
}

.svelta_banner .svelta_banner-badge .svelta_banner-badge-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.svelta_banner .svelta_banner-badge .svelta_banner-badge-inner .svelta_banner-badge-text {
  overflow: hidden;
  padding: 3px;
}

.svelta_banner .svelta_banner-badge_align-vtop {
  top: 0;
}

.svelta_banner .svelta_banner-badge_align-vbottom {
  bottom: 0;
}

.svelta_banner .svelta_banner-badge_align-hleft {
  left: 0;
}

.svelta_banner .svelta_banner-badge_align-hright {
  right: 0;
}

.svelta_banner .svelta_banner-badge_align-hcenter {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}

.svelta_banner .svelta_banner-badge_align-vbottom.svelta_banner-badge_align-hcenter,
.svelta_banner .svelta_banner-badge_align-vtop.svelta_banner-badge_align-hcenter {
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.svelta_banner .svelta_banner-badge_type-border {
  color: #008000;
  width: 50px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-color: #008000;
  border-width: 2px;
  border-style: solid;
}

.svelta_categorybox_wrapper {
  margin-bottom: 30px;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.svelta_categorybox_wrapper:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 155px 160px 155px;
  border-color: transparent transparent rgba(0, 0, 0, 0.05) transparent;
  position: absolute;
  right: -20px;
  bottom: 0;
}

.svelta_categorybox_wrapper .category-box {
  padding: 20px 0 20px 20px;
  background-repeat: no-repeat;
  background-position: 120% 100%;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  -ms-transition: all .3s ease-out 0s;
  -o-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  position: relative;
  overflow: hidden;
}

.svelta_categorybox_wrapper .category-box h2 {
  color: var(--sage);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}

.svelta_categorybox_wrapper .category-box .subhead {
  display: block;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 500;
  color: #323232;
}

.svelta_categorybox_wrapper .category-box .category-box-link ul {
  padding: 0;
  margin-bottom: 0;
}

.svelta_categorybox_wrapper .category-box .category-box-link ul li {
  margin-bottom: 0;
  text-transform: capitalize;
  list-style: none;
  line-height: 30px;
}

.svelta_categorybox_wrapper .category-box .category-box-link ul li a {
  color: #969696;
}

.svelta_categorybox_wrapper .category-box .category-box-link ul li a i {
  margin-right: 12px;
  font-size: 16px;
  color: #969696;
}

.svelta_categorybox_wrapper .category-box .category-box-link ul li a:hover,
.svelta_categorybox_wrapper .category-box .category-box-link ul li a:focus {
  padding-left: 4px;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  -ms-transition: all .3s ease-out 0s;
  -o-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  color: var(--sage);
}

.svelta_categorybox_wrapper .category-box .category-box-link ul li.view-all {
  margin-top: 5px;
  padding-bottom: 0;
}

.svelta_categorybox_wrapper .category-box .category-box-link ul li.view-all a {
  color: var(--sage);
  padding-left: 0;
  text-decoration: underline;
}

.svelta_categorybox_wrapper .category-box .category-box-link ul li.view-all a:hover,
.svelta_categorybox_wrapper .category-box .category-box-link ul li.view-all a:focus {
  text-decoration: none;
  color: #323232;
}

.svelta_categorybox_wrapper .category-box:hover {
  background-position: 110% 100%;
}

.svelta_clients .list-inline {
  margin: 0;
}

.svelta_clients.svelta_clients-list-type-grid.svelta_clients-grid-column-4 {
  padding-top: 0;
}

.svelta_clients .list-inline li {
  float: left;
  margin-bottom: 6%;
  margin-right: 2%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.svelta_clients.svelta_clients-grid-column-5 li {
  width: 18.4%;
}

.svelta_clients.svelta_clients-grid-column-5 li:nth-child(5n+5) {
  margin-right: 0;
}

.svelta_clients.svelta_clients-grid-column-4 li {
  width: 23.5%;
}

.svelta_clients.svelta_clients-grid-column-4 li:nth-child(4n+4) {
  margin-right: 0;
}

.svelta_clients.svelta_clients-grid-column-3 li {
  width: 32%;
}

.svelta_clients.svelta_clients-grid-column-3 li:nth-child(3n+3) {
  margin-right: 0;
}

.svelta_clients.svelta_clients-grid-column-2 li {
  width: 49%;
}

.svelta_clients.svelta_clients-grid-column-2 li:nth-child(2n+2) {
  margin-right: 0;
}

.image-slider-items .pro-deta {
  text-align: center;
  position: relative;
}

.image-slider-items .pro-deta .about-image img {
  display: inline-block;
  transform-style: inherit;
  width: 100%;
}

.errorMessageRemove {
  display: none;
}
.fileUploader .errorMessage {
    word-break: break-all;
    padding: 5px;
    line-height: 20px;
    box-shadow: none;
    border: 1px solid #e2e2e2;
    display: none;
}

.image-slider-items.image-slider-style-1 .about-details {
  background: #fff;
  padding: 25px 30px;
  text-align: center;
  border: 1px solid #f1f1f1;
  border-top: none;
}

.image-slider-items.image-slider-style-1 .about-details .about-des {
  display: inline-block;
  margin-bottom: 5px;
  padding: 0 22px;
  line-height: 20px;
  position: relative;
}

.image-slider-items.image-slider-style-1 .about-details .about-des:before,
.image-slider-items.image-slider-style-1 .about-details .about-des:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 25px;
  height: 1px;
  background: #ddd;
}

.image-slider-items.image-slider-style-1 .about-details .about-des:before {
  left: -20px;
}

.image-slider-items.image-slider-style-1 .about-details .about-des:after {
  right: -20px;
}

.image-slider-items.image-slider-style-1 .about-details .title {
  font-size: 16px;
  margin-bottom: 0;
}

.image-slider-items.image-slider-style-2 .about-details {
  background: rgba(255, 255, 255, 0.92);
  padding: 15px 20px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.image-slider-items.image-slider-style-2 .about-details .about-des {
  display: inline-block;
  margin-bottom: 5px;
  line-height: 20px;
  color: var(--sage);
}

.image-slider-items.image-slider-style-2 .about-details .title {
  font-size: 16px;
  margin-bottom: 0;
}

.kite-box {
  display: table;
  position: relative;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

.kite-box .kite-images-wrapper {
  position: relative;
  left: -15%;
  width: 55%;
  max-width: 55%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.kite-box .kite-images-wrapper .image-inner {
  margin: 7px;
  overflow: hidden;
  position: relative;
}

.kite-box .kite-images-wrapper .image-inner .kite-image {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.kite-box .kite-images-wrapper .image-inner .kite-image img {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.5);
}

.kite-box .kite-images-wrapper .image-inner .kite-images-actions {
  position: absolute;
  right: 20px;
  top: 50%;
  text-align: right;
  z-index: 9;
  transform: translateY(-50%);
}

.kite-box .kite-images-wrapper .image-inner .kite-images-actions .kite-actions-wrapper {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.kite-box .kite-images-wrapper .image-inner .kite-images-actions .title {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.kite-box .kite-images-wrapper .image-inner .kite-images-actions .kite-btn {
  display: inline-block;
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 20px;
  text-decoration: none;
  -webkit-transition: all .3s ease-in-out 0s;
  -moz-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
  border-radius: 3px;
}

.kite-box .kite-steps-wrapper {
  display: table-cell;
  width: 45%;
  max-width: 45%;
}

.kite-box .kite-steps-wrapper .kite-step-inner {
  margin: 30px 0;
  display: inline-block;
  width: 100%;
  margin-left: 0;
}

.kite-box .kite-steps-wrapper .kite-step-inner.top-step {
  margin-left: -100px;
}

.kite-box .kite-steps-wrapper .kite-step-inner.middle-step {
  margin-left: 0px;
}

.kite-box .kite-steps-wrapper .kite-step-inner.bottom-step {
  margin-left: -100px;
}

.kite-box .kite-steps-wrapper .step-number {
  display: table-cell;
  font-size: 150px;
  color: var(--sage);
  font-weight: 900;
  line-height: 115px;
  vertical-align: top;
  padding-right: 20px;
}

.kite-box .kite-steps-wrapper .step-content {
  display: table-cell;
  vertical-align: top;
}

.kite-box .kite-steps-wrapper .step-content .step-title {
  font-size: 26px;
  color: #323232;
  font-weight: 600;
  margin-bottom: 10px;
}

.kite-box .kite-steps-wrapper .step-content .step-description {
  font-size: 16px;
  color: #969696;
  font-weight: 400;
  line-height: 30px;
}

.svelta_list {
  padding-left: 0;
}

.svelta_list.icon-list-type-none li {
  list-style-type: none;
}

.svelta_list.icon-list-type-none li p {
  margin-bottom: 0;
}

.svelta_list.list-unstyled li {
  list-style-type: none;
  line-height: 24px;
  display: table;
  margin: 0;
}

.svelta_list.list-unstyled li .svelta-list-info {
  display: table-cell;
  vertical-align: top;
  padding-left: 10px;
  padding-bottom: 0;
}

.svelta_list li {
  list-style-type: circle;
}

.svelta_list li a {
  color: #969696;
}

.svelta_list li a:hover,
.svelta_list li a:focus {
  color: var(--sage);
}

.svelta_list li .svelta-list-info {
  display: table-cell;
  vertical-align: top;
  padding-left: 10px;
  padding-bottom: 0;
}

.svelta_list i {
  color: var(--sage);
  display: table-cell;
  padding-top: 5px;
}

.newsletter-wrapper.newsletter-style-1 {
  text-align: center;
}
 .newsletter-wrapper.newsletter-style-1.newsletter-design-1 > div {
  background-size: cover;

 }

.newsletter-wrapper.newsletter-style-1 .newsletter p {
  padding: 0 50px;
  margin-bottom: 0;
}

.newsletter-wrapper.newsletter-style-1 .newsletter p + .section-field {
  margin-top: 25px;
}

.newsletter-wrapper.newsletter-style-1 .newsletter .section-field {
  margin-top: 15px;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-1 .newsletter_form {
  display: block;
  width: 100%;
  position: relative;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-1 .input-area {
  display: block;
  padding-right: 0;
  position: relative;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-1 .input-area input[type=text] {
  border-right: none;
  background: #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  padding-right: 134px;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-1 .button-area {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-1 .button-area .btn.submit {
  min-width: 125px;
  line-height: 46px;
  padding: 0 10px;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-1 .button-area .btn.submit:hover,
.newsletter-wrapper.newsletter-style-1.newsletter-design-1 .button-area .btn.submit:focus {
  background: #323232;
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-2 .newsletter_form {
  display: block;
  width: 100%;
  position: relative;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-2 .input-area {
  display: block;
  padding-right: 165px;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-2 .input-area input[type=text] {
  border: 0 solid #e5e5e5;
  background: #fff;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-2 .button-area {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-2 .button-area .btn.submit {
  background: var(--sage);
  padding: 0 40px;
  min-width: 155px;
  line-height: 46px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .newsletter_form {
  display: block;
  width: 100%;
  position: relative;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .input-area {
  display: block;
  padding-right: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .input-area input[type=text] {
  border: none;
  background: var(--sage);
  padding-left: 25px;
  padding-right: 130px;
  height: 50px;
  color: #fff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .input-area input[type=text]::-webkit-input-placeholder {

  /* Edge */
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .input-area input[type=text]:-ms-input-placeholder {

  /* Internet Explorer 10-11 */
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .input-area input[type=text]::placeholder {
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .button-area {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .button-area .btn.submit {
  background: 0 0;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 600;
  min-width: 120px;
  line-height: 50px;
  -webkit-border-radius: 0 50px 50px 0;
  -moz-border-radius: 0 50px 50px 0;
  border-radius: 0 50px 50px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .button-area .btn.submit:hover,
.newsletter-wrapper.newsletter-style-1.newsletter-design-3 .button-area .btn.submit:focus {
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .newsletter_form {
  display: block;
  width: 100%;
  position: relative;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .input-area {
  display: block;
  padding-right: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .input-area input[type=text] {
  border: none;
  background: #323232;
  padding-left: 20px;
  padding-right: 140px;
  height: 50px;
  color: #fff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .input-area input[type=text]::-webkit-input-placeholder {

  /* Edge */
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .input-area input[type=text]:-ms-input-placeholder {

  /* Internet Explorer 10-11 */
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .input-area input[type=text]::placeholder {
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .button-area {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .button-area .btn.submit {
  background: 0 0;
  min-width: 115px;
  border: none;
  padding: 0 20px;
  padding-left: 30px;
  font-size: 13px;
  font-weight: 600;
  line-height: 50px;
  -webkit-border-radius: 0 50px 50px 0;
  -moz-border-radius: 0 50px 50px 0;
  border-radius: 0 50px 50px 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .button-area .btn.submit:hover,
.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .button-area .btn.submit:focus {
  color: var(--sage);
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .button-area:before {
  content: "\f1d9";
  font-family: FontAwesome;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .button-area:hover:before,
.newsletter-wrapper.newsletter-style-1.newsletter-design-5 .button-area:focus:before {
  color: var(--sage);
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .newsletter_form {
  display: block;
  width: 100%;
  position: relative;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .input-area {
  display: block;
  padding-right: 0;
  position: relative;
  padding-left: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .input-area input[type=text] {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: 0 0;
  padding-left: 0;
  padding-right: 120px;
  height: 50px;
  border-radius: 0;
}

.newsletter-wrapper .newsletter_form .error {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  color: #dc3545;
  bottom: -24px;
  text-align: center;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .button-area {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .button-area .btn.submit {
  background: 0 0;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 600;
  min-width: 120px;
  line-height: 46px;
  -webkit-border-radius: 0 50px 50px 0;
  -moz-border-radius: 0 50px 50px 0;
  border-radius: 0 50px 50px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  color: #323232;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .button-area .btn.submit:hover,
.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .button-area .btn.submit:focus {
  color: var(--sage);
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .button-area:before {
  content: "\f1d9";
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 0;
  color: #323232;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .button-area:hover:before,
.newsletter-wrapper.newsletter-style-1.newsletter-design-4 .button-area:focus:before {
  color: var(--sage);
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .newsletter_form {
  display: block;
  width: 100%;
  position: relative;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .input-area {
  display: block;
  padding-right: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .input-area input[type=text] {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  padding-left: 15px;
  padding-right: 130px;
  height: 50px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
}

.newsletter-style-2.svelta_newsletter-content-alignment-center.newsletter-bg-type-light .input-area {
 position: relative;
 margin-bottom: 30px;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .input-area input[type=text]::-webkit-input-placeholder {

  /* Edge */
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .input-area input[type=text]:-ms-input-placeholder {

  /* Internet Explorer 10-11 */
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .input-area input[type=text]::placeholder {
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area .btn.submit {
  background: 0 0;
  min-width: 115px;
  padding: 0 15px;
  padding-left: 25px;
  font-size: 13px;
  font-weight: 600;
  line-height: 50px;
  color: #fff;
  -webkit-border-radius: 0 50px 50px 0;
  -moz-border-radius: 0 50px 50px 0;
  border-radius: 0 50px 50px 0;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area .btn.submit:hover,
.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area .btn.submit:focus {
  color: #fff;
}

.newsletter-wrapper.newsletter-style-1.newsletter-design-6 .button-area:before {
  content: "\f1d9";
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 0;
  color: #fff;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.newsletter-wrapper .newsletter-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 10px;
}

.newsletter-wrapper .input-area {
  margin-bottom: 20px;
}

.newsletter-wrapper .input-area input[type=text] {
  width: 100%;
}

.newsletter-wrapper .button-area {
  display: inline-block;
  position: relative;
}

.newsletter-bg {
  margin-top: 10px;
}

.newsletter-bg p.title {
  text-align: center;
}

.product-deals-items-column .product-deals-item {
  display: table;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  min-height: 140px;
}

.product-deals-items-column .product-deals-item .product-deals-item-left {
  display: table-cell;
  width: 35%;
  background-color: #fff;
  vertical-align: middle;
}

.product-deals-items-column .product-deals-item .product-deals-item-right {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 50px;
  background-color: #fff;
}

.product-deals-items-column .product-deals-item .product-deals-item-right .product-deal-content .product-deal-title {
  display: block;
  margin: 0;
  margin-bottom: 5px;
  color: #323232;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.product-deals-items-column .product-deals-item .product-deals-item-right .product-deal-content .product-deal-title a {
  color: #323232;
}

.product-deals-items-column .product-deals-item .product-deals-item-right .product-deal-content .product-deal-title a:hover,
.product-deals-items-column .product-deals-item .product-deals-item-right .product-deal-content .product-deal-title a:focus {
  color: var(--sage);
}

.product-deals-items-column .product-deals-item .product-deals-item-right .product-deal-content .product-deal-content-rating .star-rating {
  font-size: 12px;
}

.product-deals-items-column .product-deals-item .product-deals-item-right .product-deal-content .product-deal-content-price ins {
  background: 0;
  font-size: 18px;
  color: var(--sage);
  font-weight: 600;
  margin-left: 5px;
}

.product-reviews .rating {
  display: flex;
}

.product-reviews .rating li {
  margin-right: 2px;
}

.product-reviews .rating li .fa-star,
.product-reviews .rating li .fa-star-half-o {
  color: #ffbe00;
}

.product-reviews .product-reviews-comment {
  display: flex;
}

.product-reviews .product-reviews-comment .comment-avatar {
  width: 80px;
  margin-right: 20px;
}

.product-reviews .product-reviews-comment .comment-text {
  width: 100%;
  border: 1px solid #e4e1e3;
  padding: 15px;
}

.products-listing-intro-wrapper {
  height: 100%;
  padding: 25px 25px 85px 25px;
  position: relative;
  z-index: 1;
}

.products-listing-intro-wrapper.products-listing-intro-content-alignment-left {
  text-align: left;
}

.products-listing-intro-wrapper.products-listing-intro-content-alignment-right {
  text-align: right;
}

.products-listing-intro-wrapper .products-listing-title h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}

.products-listing-intro-wrapper .products-listing-control {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px;
  padding-top: 0;
  width: 100%;
}

.products-listing-intro-wrapper .products-listing-nav > div {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  color: #969696;
  line-height: 48px;
  cursor: pointer;
  margin-right: 5px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
}

.products-listing-intro-wrapper .products-listing-link {
  margin-top: 15px;
}

.products-listing-intro-wrapper .products-listing-link a {
  font-weight: 600;
  text-decoration: underline;
  color: #fff;
}

.products-listing-intro-wrapper .products-listing-intro-wrapper-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

.products-listing-intro-bg_type-image,
.products-listing-intro-bg_type-image h2 {
  color: #fff;
}

.svelta-products-shortcode .btn.btn-solid {
  border: 1px solid #e5e5e5;
}

.svelta-products-shortcode .btn.btn-solid:hover {
  background: #f5f5f5;
  color: #323232;
}

.latest-post-wrapper .latest-post-item .latest-post-item-inner {
  position: relative;
  display: table;
  height: 100%;
}

.latest-post-wrapper .latest-post-item .latest-post-image {
  display: table-cell;
  width: 50%;
  height: 100%;
  background: #ddd;
}

.latest-post-wrapper .latest-post-item .latest-post-image img {
  width: 100%;
}

.latest-post-wrapper .latest-post-item .latest-post-content {
  display: table-cell;
  vertical-align: top;
  padding: 20px;
  position: relative;
  height: 100%;
}

.latest-post-wrapper .latest-post-item .post-date {
  position: absolute;
  top: 5px;
  left: 0;
  margin-left: -85px;
  width: 60px;
  height: 60px;
  background: #fff;
  text-align: center;
  color: var(--sage);
  font-size: 18px;
  font-weight: 600;
  padding-top: 7px;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
}

.latest-post-wrapper .latest-post-item .post-date span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
}

.latest-post-wrapper .latest-post-item .latest-post-meta {
  display: inline-block;
  margin-bottom: 5px;
}

.latest-post-wrapper .latest-post-item .latest-post-meta ul {
  padding: 0;
  margin: 0;
}

.latest-post-wrapper .latest-post-item .latest-post-meta ul li {
  list-style-type: none;
  float: left;
  margin-right: 10px;
  display: inline-block;
  font-size: 13px;
  color: #969696;
  line-height: 24px;
}

.latest-post-wrapper .latest-post-item .latest-post-meta ul li a {
  color: #969696;
}

.latest-post-wrapper .latest-post-item .latest-post-meta ul li a:hover,
.latest-post-wrapper .latest-post-item .latest-post-meta ul li a:focus {
  color: var(--sage);
}

.latest-post-wrapper .latest-post-item .latest-post-meta ul li i {
  margin-right: 5px;
  color: var(--sage);
}

.latest-post-wrapper .latest-post-item .blog-title {
  font-size: 18px;
  color: #323232;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 5px;
}

.latest-post-wrapper .latest-post-item .blog-title a {
  color: #323232;
}

.latest-post-wrapper .latest-post-item .blog-title a:hover,
.latest-post-wrapper .latest-post-item .blog-title a:focus {
  color: var(--sage);
}

.latest-post-wrapper .latest-post-item .latest-post-category span {
  background: var(--sage);
  padding: 0 25px;
  display: inline-block;
  color: #fff;
  line-height: 28px;
  text-transform: capitalize;
}

.latest-post-wrapper .latest-post-item .latest-post-entry-footer {
  display: inline-block;
  width: 100%;
}

.latest-post-wrapper .latest-post-item .latest-post-entry-footer a {
  float: left;
  line-height: 28px;
  margin-bottom: -58px;
  padding-top: 30px;
}

.latest-post-wrapper .latest-post-item .latest-post-entry-footer a:hover,
.latest-post-wrapper .latest-post-item .latest-post-entry-footer a:focus {
  color: var(--sage);
}

.latest-post-wrapper .latest-post-item .latest-post-entry-footer .latest-post-social-share {
  margin-left: -9px;
}

.latest-post-wrapper .latest-post-item .latest-post-entry-footer .latest-post-social-share ul {
  padding: 0;
  margin: 0;
}

.latest-post-wrapper .latest-post-item .latest-post-entry-footer .latest-post-social-share ul li {
  list-style-type: none;
  display: table-cell;
}

.latest-post-wrapper .latest-post-item .latest-post-entry-footer .latest-post-social-share ul li a {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: inline-block;
  color: #969696;
}

.latest-post-wrapper.latest-post-style-1 .latest-post-item .latest-post-content {
  padding: 0;
  padding-left: 30px;
  vertical-align: middle;
  position: static;
  text-align: left;
}

.latest-post-wrapper.latest-post-style-1 .latest-post-item .post-date {
  position: absolute;
  left: 15px;
  top: 15px;
  background: #323232;
  color: #fff;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  margin-left: 0;
  width: auto;
  height: auto;
  display: inline-block;
  padding: 0 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}

.latest-post-wrapper.latest-post-style-1 .latest-post-item .post-date span {
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  line-height: inherit;
  padding-left: 5px;
}

.latest-post-wrapper.latest-post-style-1 .latest-post-item .latest-post-entry-footer {
  display: inline-block;
  width: 100%;
}

.latest-post-wrapper.latest-post-style-1 .latest-post-item .latest-post-entry-footer a {
  float: none;
  color: #969696;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
  display: inline-block;
  padding-top: 0;
}

.latest-post-wrapper.latest-post-style-1 .latest-post-item .latest-post-entry-footer a:hover,
.latest-post-wrapper.latest-post-style-1 .latest-post-item .latest-post-entry-footer a:focus {
  color: var(--sage);
}

.latest-post-wrapper.latest-post-style-1 .latest-post-item .latest-post-entry-footer .latest-post-social-share {
  display: none;
}

.latest-post-wrapper.latest-post-style-2 .latest-post-item .latest-post-item-inner {
  background: #fff;
  border: 1px solid #eeeeee;
  display: block;
}

.latest-post-wrapper.latest-post-style-2 .latest-post-item .latest-post-image {
  width: 100%;
  height: auto;
  display: block;
}

.latest-post-wrapper.latest-post-style-2 .latest-post-item .latest-post-content {
  width: 100%;
  height: auto;
  display: block;
  padding: 30px;
  background: 0 0;
  position: static;
}

.latest-post-wrapper.latest-post-style-2 .latest-post-item .post-date {
  position: absolute;
  left: 0;
  top: 15px;
  background: #323232;
  color: #fff;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  margin-left: 0;
  width: auto;
  height: auto;
  display: inline-block;
  padding: 0 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}

.latest-post-wrapper.latest-post-style-2 .latest-post-item .post-date span {
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  line-height: inherit;
  padding-left: 5px;
}

.latest-post-wrapper.latest-post-style-2 .latest-post-item .blog-title a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.latest-post-wrapper.latest-post-style-2 .latest-post-item .latest-post-entry-footer {
  display: none;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-item-inner {
  box-shadow: none;
  padding-bottom: 30px;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-image {
  width: 100%;
  height: auto;
  display: block;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-content {
  background: #fff;
  width: 91%;
  height: auto;
  display: block;
  margin: 0 4.5%;
  margin-top: -40px;
  padding: 40px 20px 15px;
  position: relative;
  z-index: 9;
  text-align: center;
  -webkit-box-shadow: 0 3px 25px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 25px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.05);
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .post-date {
  left: 50%;
  top: -35px;
  margin-left: -30px;
  background: var(--sage);
  color: #fff;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  transform: inherit;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .post-date .post-date-inner {
  transform: inherit;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .blog-title {
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .blog-title a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-meta {
  display: none;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-entry-footer {
  position: relative;
  bottom: auto;
  top: -5px;
  left: 0;
  padding: 0;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-entry-footer a {
  float: none;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-entry-footer a:hover,
.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-entry-footer a:focus {
  color: #323232;
}

.latest-post-wrapper.latest-post-style-3 .latest-post-item .latest-post-social-share {
  display: none;
}

.latest-post-wrapper.latest-post-style-4 .latest-post-item .latest-post-content {
  padding: 15px 0 15px 50px;
  vertical-align: middle;
}

.latest-post-wrapper.latest-post-style-4 .latest-post-item .post-date {
  background: var(--sage);
  color: #fff;
  margin-left: -30px;
  top: 30px;
}

.latest-post-wrapper.latest-post-style-4 .latest-post-item .latest-post-entry-footer a {
  margin-bottom: 0;
  padding: 2px 26px;
  border: 2px solid #e8e8e8;
  color: #969696;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.latest-post-wrapper.latest-post-style-4 .latest-post-item .latest-post-entry-footer a:hover,
.latest-post-wrapper.latest-post-style-4 .latest-post-item .latest-post-entry-footer a:focus {
  border-color: var(--sage);
  color: var(--sage);
}

.latest-post-wrapper.latest-post-style-4 .latest-post-item .latest-post-social-share {
  display: none;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner {
  display: block;
  height: auto;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .latest-post-image {
  display: block;
  width: 100%;
  height: auto;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .latest-post-content {
  display: block;
  vertical-align: inherit;
  padding: 20px 0 0;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .post-date {
  top: 10px;
  left: inherit;
  right: 10px;
  margin-left: 0;
  width: auto;
  height: auto;
  color: #969696;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 12px;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .post-date i {
  color: var(--sage);
  margin-right: 3px;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .latest-post-meta {
  margin-bottom: 0;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .latest-post-entry-footer a {
  float: none;
  line-height: inherit;
  margin-bottom: 0;
  padding-top: 0;
  color: #969696;
  position: relative;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .latest-post-entry-footer a:after {
  content: "";
  background: #e5e5e5;
  height: 1px;
  width: 45px;
  display: inline-block;
  top: 50%;
  position: absolute;
  margin-top: 1px;
  margin-left: 8px;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.latest-post-wrapper.latest-post-style-5 .latest-post-item .latest-post-item-inner .latest-post-social-share {
  display: none;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-content {
  padding: 0 0 0 60px;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .post-date {
  top: 0;
  left: 0;
  margin-left: 0;
  width: auto;
  height: auto;
  color: #323232;
  font-size: 32px;
  font-weight: 900;
  padding: 0 0 5px 0;
  box-shadow: none;
  background: 0 0;
  border-bottom: 2px solid #e5e5e5;
  line-height: 32px;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .post-date span {
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 2px;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-category {
  margin-bottom: 10px;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-category span {
  padding: 0 15px;
  line-height: 24px;
  font-size: 11px;
  text-transform: uppercase;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-meta {
  margin-bottom: 0;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-entry-footer a {
  margin-bottom: 0;
  padding: 2px 26px;
  border: 2px solid #e8e8e8;
  color: #969696;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-entry-footer a:hover,
.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-entry-footer a:focus {
  border-color: var(--sage);
  color: var(--sage);
}

.latest-post-wrapper.latest-post-style-6 .latest-post-item .latest-post-social-share {
  display: none;
}

.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-content {
  padding: 0 0 0 20px;
  vertical-align: middle;
}

.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-category {
  margin-bottom: 5px;
}

.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-category span {
  padding: 0 15px;
  line-height: 24px;
  font-size: 11px;
  text-transform: uppercase;
}

.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-meta {
  margin-bottom: 0;
}

.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-entry-footer a {
  margin-bottom: 0;
  padding: 2px 26px;
  border: 2px solid #e8e8e8;
  color: #969696;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-entry-footer a:hover,
.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-entry-footer a:focus {
  border-color: var(--sage);
  color: var(--sage);
}

.latest-post-wrapper.latest-post-style-7 .latest-post-item .latest-post-social-share {
  display: none;
}

.latest-post-type-grid .latest-post-item .latest-post-content,
.latest-post-type-carousel .latest-post-item .latest-post-content {
  height: 100%;
}

.latest-post-type-grid .latest-post-main {
  margin-top: -30px;
}

.latest-post-type-grid .latest-post-main .latest-post-item {
  margin-bottom: 0;
  padding-top: 30px;
  height: 100%;
}

.latest-post-type-carousel.latest-post-style-2 .owl-carousel .latest-post-item {
  padding-bottom: 1px;
}

.latest-post-type-carousel.latest-post-style-2 .owl-carousel .latest-post-item-inner {
  box-shadow: none;
  border: 1px solid #e8e8e8;
}

@-webkit-keyframes svelta-social-toTopFromBottom {
  49% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }

}

@-moz-keyframes svelta-social-toTopFromBottom {
  49% {
    -moz-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }

}

@keyframes svelta-social-toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }

}

.svelta-social-icons ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.svelta-social-icons ul li {
  float: left;
  list-style: none;
  padding: 0;
  margin: 4px 8px 4px 0;
  margin-bottom: 10px;
}

.svelta-social-icons ul li a {
  float: left;
  height: 32px;
  width: 32px;
  line-height: 33px;
  font-size: 16px;
  text-align: center;
  margin: 0;
  border: 0;
  background: 0 0;
  color: #323232;
  overflow: hidden;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.svelta-social-icons ul li:hover i {
  webkit-animation: svelta-social-toTopFromBottom .3s forwards;
  -moz-animation: svelta-social-toTopFromBottom .3s forwards;
  animation: svelta-social-toTopFromBottom .3s forwards;
}

.svelta-social-icons.svelta-social-size-medium ul li a {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 18px;
}

.svelta-social-icons.svelta-social-size-large ul li a {
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 20px;
}

.svelta-social-icons.svelta-social-size-extra-large ul li a {
  height: 54px;
  width: 54px;
  line-height: 54px;
  font-size: 20px;
}

.svelta-social-icons.svelta-social-style-border ul li a {
  border: 1px solid #eee;
  background: 0 0;
}

.bg-dark-gray .svelta-social-icons li a {
  color: #fff;
}

.svelta-social-icons.svelta-social-effect-color-hover a:hover,
.svelta-social-icons.svelta-social-style-flat-color a {
  color: #fff;
  border: 0;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-rss a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-rss a {
  background-color: #faa33d;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-facebook a:hover,
.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-facebook-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-facebook a,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-facebook-square a {
  background-color: #5d82d1;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-twitter a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-twitter a {
  background-color: #40bff5;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-vimeo a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-vimeo a {
  background-color: #35c6ea;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-myspace a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-myspace a {
  background-color: #008dde;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-youtube a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-youtube a {
  background-color: #ef4e41;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-instagram a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-instagram a {
  background-color: #548bb6;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-gplus a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-gplus a {
  background-color: #d68400;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-stumbleupon a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-stumbleupon a {
  background-color: #ff5c30;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-lastfm a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-lastfm a {
  background-color: #f34320;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-pinterest a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-pinterest a {
  background-color: #e13138;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-google a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-google a {
  background-color: #eb5e4c;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-evernote a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-evernote a {
  background-color: #9acf4f;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-dribbble a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-dribbble a {
  background-color: #f7659c;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-skype a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-skype a {
  background-color: #13c1f3;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-forrst a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-forrst a {
  background-color: #45ad76;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-linkedin a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-linkedin a {
  background-color: #238cc8;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-wordpress a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-wordpress a {
  background-color: #2592c3;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-grooveshark a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-grooveshark a {
  background-color: #ffb21d;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-delicious a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-delicious a {
  background-color: #377bda;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-behance a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-behance a {
  background-color: #1879fd;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-dropbox a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-dropbox a {
  background-color: #17a3eb;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-soundcloud a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-soundcloud a {
  background-color: #ff7e30;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-deviantart a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-deviantart a {
  background-color: #6a8a7b;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-yahoo a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-yahoo a {
  background-color: #ab47ac;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-flickr a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-flickr a {
  background-color: #ff48a3;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-digg a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-digg a {
  background-color: #75788d;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-blogger a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-blogger a {
  background-color: #ff9233;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-tumblr a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-tumblr a {
  background-color: #426d9b;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-quora a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-quora a {
  background-color: #ea3d23;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-github a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-github a {
  background-color: #3f91cb;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-amazon a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-amazon a {
  background-color: #ff8e2e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-xing a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-xing a {
  background-color: #1a8e8c;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-wikipedia a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-wikipedia a {
  background-color: #b3b5b8;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-android a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-android a {
  background-color: #a4c639;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-googleplus a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-googleplus a {
  background-color: #d34836;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-apple a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-apple a {
  background-color: #999;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-twitter-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-twitter-square a {
  background-color: #40bff5;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-linkedin-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-linkedin-square a {
  background-color: #238cc8;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-github-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-github-square a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-pinterest-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-pinterest-square a {
  background-color: #e13138;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-google-plus-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-google-plus-square a {
  background-color: #da4f43;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-google-plus a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-google-plus a {
  background-color: #da4f43;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-github-alt a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-github-alt a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-maxcdn a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-maxcdn a {
  background-color: #ff5b02;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-html5 a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-html5 a {
  background-color: #e44d26;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-css3 a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-css3 a {
  background-color: #0071bb;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-btc a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-btc a {
  background-color: #f7931a;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-youtube-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-youtube-square a {
  background-color: #c12025;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-stack-overflow a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-stack-overflow a {
  background-color: #f48023;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-xing-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-xing-square a {
  background-color: #006567;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-youtube-play a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-youtube-play a {
  background-color: #c12025;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-adn a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-adn a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-bitbucket a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-bitbucket a {
  background-color: #205081;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-bitbucket-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-bitbucket-square a {
  background-color: #205081;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-tumblr-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-tumblr-square a {
  background-color: #395976;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-windows a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-windows a {
  background-color: #04aef4;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-linux a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-linux a {
  background-color: #fc0;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-foursquare a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-foursquare a {
  background-color: #fa4778;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-trello a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-trello a {
  background-color: #007cc3;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-gratipay a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-gratipay a {
  background-color: #630;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-vk a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-vk a {
  background-color: #4c75a3;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-vk a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-vk a {
  background-color: #4c75a3;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-weibo a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-weibo a {
  background-color: #f93;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-renren a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-renren a {
  background-color: #005eac;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-pagelines a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-pagelines a {
  background-color: #1996fc;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-stack-exchange a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-stack-exchange a {
  background-color: #f48023;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-vimeo-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-vimeo-square a {
  background-color: #23b6ea;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-slack a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-slack a {
  background-color: #78d4b6;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-yahoo a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-yahoo a {
  background-color: #7b0099;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-reddit a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-reddit a {
  background-color: #ff4500;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-reddit-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-reddit-square a {
  background-color: #ff4500;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-stumbleupon-circle a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-stumbleupon-circle a {
  background-color: #eb4924;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-pied-piper-pp a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-pied-piper-pp a {
  background-color: #388e3c;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-pied-piper-alt a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-pied-piper-alt a {
  background-color: #388e3c;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-drupal a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-drupal a {
  background-color: #2aa9e0;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-joomla a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-joomla a {
  background-color: #116faf;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-behance-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-behance-square a {
  background-color: #005cff;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-steam a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-steam a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-steam-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-steam-square a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-spotify a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-spotify a {
  background-color: #1dd069;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-openid a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-openid a {
  background-color: #f7931e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-vine a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-vine a {
  background-color: #1ccd94;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-codepen a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-codepen a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-jsfiddle a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-jsfiddle a {
  background-color: #4679a4;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-rebel a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-rebel a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-empire a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-empire a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-git-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-git-square a {
  background-color: #f05033;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-hacker-news a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-hacker-news a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-tencent-weibo a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-tencent-weibo a {
  background-color: #006cb6;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-qq a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-qq a {
  background-color: #4d97d4;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-weixin a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-weixin a {
  background-color: #50b31b;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-share-alt a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-share-alt a {
  background-color: #02bf01;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-share-alt-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-share-alt-square a {
  background-color: #02bf01;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-slideshare a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-slideshare a {
  background-color: #42c0fb;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-twitch a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-twitch a {
  background-color: #6441a4;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-yelp a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-yelp a {
  background-color: #d32323;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-paypal a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-paypal a {
  background-color: #253b80;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-google-wallet a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-google-wallet a {
  background-color: #d7d7d7;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-visa a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-visa a {
  background-color: #3958a6;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-mastercard a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-mastercard a {
  background-color: #003663;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-git a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-git a {
  background-color: #f05033;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-discover a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-discover a {
  background-color: #f60;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-amex a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-amex a {
  background-color: #2f77bf;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-paypal a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-paypal a {
  background-color: #002f86;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-stripe a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-stripe a {
  background-color: #009cde;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-lastfm a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-lastfm a {
  background-color: #d20039;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-lastfm-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-lastfm-square a {
  background-color: #d20039;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-ioxhost a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-ioxhost a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-angellist a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-angellist a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-meanpath a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-meanpath a {
  background-color: #538dd6;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-buysellads a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-buysellads a {
  background-color: #f05033;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-connectdevelop a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-connectdevelop a {
  background-color: #3b164a;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-dashcube a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-dashcube a {
  background-color: #ff7f00;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-forumbee a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-forumbee a {
  background-color: #86ad22;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-leanpub a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-leanpub a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-sellsy a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-sellsy a {
  background-color: #0075c8;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-shirtsinbulk a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-shirtsinbulk a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-skyatlas a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-skyatlas a {
  background-color: #4cc3c8;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-facebook-official a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-facebook-official a {
  background-color: #3b5998;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-pinterest-p a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-pinterest-p a {
  background-color: #cb2027;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-whatsapp a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-whatsapp a {
  background-color: #29a71a;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-viacoin a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-viacoin a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-medium a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-medium a {
  background-color: #263238;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-y-combinator a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-y-combinator a {
  background-color: #f26522;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-optin-monster a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-optin-monster a {
  background-color: #9cff00;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-opencart a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-opencart a {
  background-color: #23c1f0;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-expeditedssl a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-expeditedssl a {
  background-color: #455a64;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-jcb a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-jcb a {
  background-color: #fba51a;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-cc-diners-club a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-cc-diners-club a {
  background-color: #1b458e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-gg a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-gg a {
  background-color: #f7931e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-gg-circle a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-gg-circle a {
  background-color: #f7931e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-tripadvisor a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-tripadvisor a {
  background-color: #7db557;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-odnoklassniki a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-odnoklassniki a {
  background-color: #f96200;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-odnoklassniki-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-odnoklassniki-square a {
  background-color: #f96200;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-get-pocket a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-get-pocket a {
  background-color: #ef4056;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-wikipedia-w a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-wikipedia-w a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-safari a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-safari a {
  background-color: #409ed4;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-chrome a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-chrome a {
  background-color: #333;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-firefox a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-firefox a {
  background-color: #f58d00;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-opera a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-opera a {
  background-color: #ff1a2e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-internet-explorer a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-internet-explorer a {
  background-color: #2672ec;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-contao a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-contao a {
  background-color: #f47c00;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-500px a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-500px a {
  background-color: #1e88e5;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-houzz a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-houzz a {
  background-color: #8ac541;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-black-tie a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-black-tie a {
  background-color: #000;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-fonticons a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-fonticons a {
  background-color: #109d58;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-reddit-alien a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-reddit-alien a {
  background-color: #ff4500;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-edge a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-edge a {
  background-color: #2c74be;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-codiepie a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-codiepie a {
  background-color: #96deda;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-modx a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-modx a {
  background-color: #61a349;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-fort-awesome a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-fort-awesome a {
  background-color: #1d9e74;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-usb a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-usb a {
  background-color: #0054a6;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-product-hunt a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-product-hunt a {
  background-color: #da542f;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-mixcloud a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-mixcloud a {
  background-color: #27394d;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-scribd a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-scribd a {
  background-color: #32201e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-bluetooth a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-bluetooth a {
  background-color: #0a3c91;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-bluetooth-b a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-bluetooth-b a {
  background-color: #0a3c91;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-gitlab a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-gitlab a {
  background-color: #30353e;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-wpbeginner a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-wpbeginner a {
  background-color: #f60;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-wpforms a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-wpforms a {
  background-color: #b85a1b;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-envira a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-envira a {
  background-color: #7cc048;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-glide a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-glide a {
  background-color: #00a7ff;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-glide-g a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-glide-g a {
  background-color: #00a7ff;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-viadeo a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-viadeo a {
  background-color: #ff7e00;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-viadeo-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-viadeo-square a {
  background-color: #ff7e00;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-snapchat a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-snapchat a {
  background-color: #fff010;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-snapchat-ghost a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-snapchat-ghost a {
  background-color: #fff010;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-snapchat-square a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-snapchat-square a {
  background-color: #fff010;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-pied-piper a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-pied-piper a {
  background-color: #2e9e48;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-first-order a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-first-order a {
  background-color: maroon;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-yoast a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-yoast a {
  background-color: #9d2563;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-themeisle a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-themeisle a {
  background-color: #1599c4;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-google-plus-official a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-google-plus-official a {
  background-color: #dd5044;
}

.svelta-social-icons.svelta-social-effect-color-hover .svelta-social-color-font-awesome a:hover,
.svelta-social-icons.svelta-social-style-flat-color .svelta-social-color-font-awesome a {
  background-color: #1d9e74;
}

.svelta-social-icons.svelta-social-shape-rounded li a {
  border-radius: 3px;
}

.svelta-social-icons.svelta-social-shape-round li a {
  border-radius: 100%;
}

.team {
  background: #fff;
  border: none;
  padding-top: 0;
  text-align: center;
  position: relative;
  cursor: default;
  overflow: hidden;
}

.team .team-description {
  opacity: 0;
  background: #323232;
  text-align: center;
  padding: 10px 15px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.team .team-description h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
}

.team .team-description span {
  color: var(--sage);
}

.team .team-social-icon {
  background: var(--sage);
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  padding: 15px 10px;
}

.team .team-social-icon ul {
  display: inline-block;
  vertical-align: top;
  padding: 0px;
  margin-bottom: 0;
}

.team .team-social-icon ul li {
  display: inline-block;
  margin: 0 2px;
}

.team .team-social-icon ul li a {
  line-height: 24px;
  font-size: 18px;
  color: #fff;
  border: 2px solid #fff;
  width: 35px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  margin-bottom: 0;
}

.team .team-social-icon ul li a:last-child {
  margin-right: 0;
}

.team:hover .team-description {
  opacity: 1;
}

.team:hover .team-social-icon {
  bottom: 68px;
}

.team.shadow {
  border: 1px solid #f5f5f5;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05) !important;
}

.team.shadow .team-info {
  background: #fff;
  width: 100%;
  position: absolute;
  bottom: -65px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.team.shadow .team-info .team-description {
  background: 0 0;
  position: initial;
  padding: 15px 10px;
  opacity: 1;
}

.team.shadow .team-info .team-description h4 {
  color: #323232;
}

.team.shadow .team-info .team-description span {
  color: #969696;
}

.team.shadow .team-info .team-social-icon {
  position: inherit;
  background: none;
  bottom: 0;
  position: relative;
  z-index: 9;
}

.team.shadow:hover .team-info {
  bottom: 0;
  background: var(--sage);
}

.team.shadow:hover .team-info .team-description h4 {
  color: #fff;
}

.team.shadow:hover .team-info .team-description span {
  color: #fff;
}

.svelta_team_members_style_style-1 .owl-carousel .owl-item img {
  display: inline-block;
}

.svelta_team_members_style_style-3 .team.shadow:hover .team-social-icon a {
  border: 2px solid #fff;
}

.testimonial {
  background: 0 0;
  box-shadow: none;
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  position: relative;
}

.testimonial .slick-initialized .slick-slide {
  outline: 0;
}

.testimonial i.fa-quote-left {
  font-size: 70px;
  margin-bottom: 20px;
  color: var(--sage);
}

.testimonial p {
  font-size: 18px;
  line-height: 34px;
  color: #323232;
  margin-bottom: 15px;
  font-style: italic;
}

.testimonial .client-info {
  margin-bottom: 20px;
}

.testimonial .client-info .author-name {
  display: inline-block;
  font-size: 14px;
  color: var(--sage);
  font-weight: 400;
  margin-bottom: 0;
  line-height: 28px;
}

.testimonial .client-info .author-name + span:before {
  content: "|";
  font-size: 14px;
  padding: 0 6px;
  color: #969696;
}

.testimonial .testimonial-nav {
  margin-bottom: 0;
  width: 200px;
  margin: 0 auto;
}

.testimonial .testimonial-nav .slick-slide {
  margin: 0;
}

.testimonial .testimonial-nav .slick-slide .author-photo {
  margin-bottom: 0;
  cursor: pointer;
}

.testimonial .testimonial-nav .slick-slide img {
  padding: 8px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  border-radius: 80px;
}

.testimonial .testimonial-nav .slick-current img {
  padding: 0;
  border: 3px solid var(--sage);
}

.testimonial.testimonial-style-1 .author-photo {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.testimonial.testimonial-style-1 .author-photo img {
  margin: 0 auto;
}

.testimonial.testimonial-style-1 .slick-prev:before,
.testimonial.testimonial-style-1 .slick-next:before {
  color: #323232;
}

.testimonial.testimonial-style-1 .slick-prev:hover:before,
.testimonial.testimonial-style-1 .slick-next:hover:before {
  color: var(--sage);
}

.testimonial.testimonial-style-1 .slick-next {
  right: 40%;
  top: inherit;
  bottom: 10px;
  z-index: 9;
}

.testimonial.testimonial-style-1 .slick-prev {
  left: 40%;
  top: inherit;
  bottom: 10px;
  z-index: 9;
}

.testimonial.testimonial-style-1 .slick-next:before {
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  font-size: 18px;
  color: #323232;
}

.testimonial.testimonial-style-1 .slick-prev:before {
  content: "\f104";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  font-size: 18px;
  color: #323232;
}

.testimonial.testimonial-style-2 .client-image {
  margin-bottom: 20px;
  padding-top: 20px;
}

.testimonial.testimonial-style-2 .client-image .author-photo img {
  width: 125px;
  height: auto;
  display: inline-block;
  border: 4px solid #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  border-radius: 80px;
}

.testimonial.testimonial-style-2 .client-info p {
  color: #969696;
  font-style: normal;
  font-size: 15px;
  margin-top: 15px;
}

.testimonial.testimonial-style-2 .testimonials-title {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
}

.testimonial.testimonial-style-3 {
  text-align: left;
}

.testimonial.testimonial-style-3 .owl-item {
  padding: 0 20px;
}

.testimonial.testimonial-style-3 .owl-item .item {
  background: #fff;
  margin: 20px 0;
  padding: 23px;
  -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

.testimonial.testimonial-style-3 .testimonial-content p {
  line-height: 28px;
  color: #e8e8e8;
  font-size: 15px;
  font-style: normal;
  margin: 0;
}

.testimonial.testimonial-style-3 .testimonial-meta {
  display: table;
  padding-top: 20px;
}

.testimonial.testimonial-style-3 .testimonial-meta .client-image {
  display: table-cell;
  padding-right: 15px;
}

.testimonial.testimonial-style-3 .testimonial-meta .client-image .author-photo img {
  width: 65px;
  border: 2px solid var(--sage);
  border-radius: 50%;
}

.testimonial.testimonial-style-3 .testimonial-meta .client-info {
  margin-bottom: 20px;
  display: table-cell;
  position: relative;
  vertical-align: bottom;
  color: #e8e8e8;
}

.testimonial.testimonial-style-3 .testimonial-meta .client-info:before {
  content: "\f10d";
  font-family: FontAwesome;
  font-size: 46px;
  position: absolute;
  left: 3px;
  top: 12px;
  opacity: .1;
  color: #e8e8e8;
}

.testimonial.testimonial-style-3 .testimonial-meta .client-info span:before {
  color: #e8e8e8;
}

.bg-dark-gray .testimonial.testimonial-style-3 .owl-item .item {
  background: rgba(255, 255, 255, 0.03);
}

.insta_v2_content img {
  width: 100%;
  height: auto;
}

.insta_v2_header {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.insta_v2_header--title {
  -webkit-box-flex: 0;
  -ms-box-flex: 0 0 auto;
  flex: 1 1 auto;
  margin-right: 10px;
}

.insta_v2_header--title .insta_v2_title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
}

.insta_v2_header--button {
  margin-left: auto;
  -webkit-flex: 0 0 132px;
  -ms-flex: 0 0 132px;
  flex: 0 0 132px;
}

.insta_v2_header--button a {
  background: var(--sage);
  border: none;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  line-height: 24px;
  padding: 8px 18px 8px 35px;
  position: relative;
}

.insta_v2_header--button a i {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 18px;
}

.insta_v2_header--button a:hover {
  background: #323232;
}

.pgs-mtpl-header-wrapper .nav {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}

.pgs-mtpl-header-wrapper .nav li {
  list-style-type: none;
  margin: 2px;
  display: inline-block;
}

.pgs-mtpl-header-wrapper .nav li a {
  color: #323232;
}

.pgs-mtpl-header-wrapper .mtpl-tabs--tabs_style-style-1 {
  position: relative;
}

.pgs-mtpl-header-wrapper .mtpl-tabs--tabs_style-style-1 li {
  position: relative;
  margin: 2px 8px;
}

.pgs-mtpl-header-wrapper .mtpl-tabs--tabs_style-style-1 li a {
  padding: 0 8px;
  font-size: 18px;
  font-weight: 400;
}

.pgs-mtpl-header-wrapper .mtpl-tabs--tabs_style-style-1 li a.active {
  color: var(--sage);
}

.pgs-mtpl-header-tabs_alignment-center .nav {
  text-align: center;
}

@keyframes animateZoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}

.sidebar .widget {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

.sidebar .widget:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.sidebar .shop-sidebar-widgets .widget_price_filter .price_slider_wrapper .price_slider_wrapper-inner {
  width: 100%;
}

.sidebar .shop-sidebar-widgets .widget_price_filter .price_slider_wrapper .button {
  font-size: 14px;
  float: right;
  background: 0 0;
  color: #323232;
  padding: 0;
  line-height: 24px;
  font-weight: 600;
  margin-top: 0px;
  position: relative;
}

.sidebar .shop-sidebar-widgets .widget_price_filter .price_slider_wrapper .button:before {
  content: "\f0b0";
  font-family: FontAwesome;
  padding-right: 10px;
}

.sidebar .shop-sidebar-widgets .widget_price_filter .price_slider_wrapper .button:hover {
  color: var(--sage);
}

.sidebar .shop-sidebar-widgets .widget_price_filter .price_slider_wrapper .ui-slider {
  margin-bottom: 20px;
}

.sidebar .shop-sidebar-widgets .widget_price_filter .price_slider_wrapper input#amount {
  height: auto;
  color: #969696;
  border: 0;
  position: absolute;
  padding: 0;
  width: auto;
}

.price_slider_wrapper .input-range__track--active {
  background: var(--sage);
}

.price_slider_wrapper .input-range__slider {
  background: var(--sage);
  border-color: var(--sage);
}

.price_slider_wrapper .input-range__label--value {
  top: -30px;
}

.price_slider_wrapper .input-range__label {
  left: 0;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.price_slider_wrapper .input-range__label--max,
.price_slider_wrapper .input-range__label--min {
  right: 10px;
  left: inherit;
  display: none;
}

.sidebar .shop-sidebar-widgets .widget_price_filter .price_slider_wrapper .price_slider_wrapper-inner {
  margin-bottom: 10px;
}

.sidebar .shop-sidebar-widgets .ui-slider {
  margin-top: 5px;
  margin-bottom: 5px;
  background: #e9e9e9;
  height: 5px;
  border-radius: 1em;
  border: 0;
}

.sidebar .shop-sidebar-widgets .ui-slider .ui-slider-range {
  background-color: var(--sage);
  border-radius: 0;
}

.sidebar .shop-sidebar-widgets .ui-slider .ui-slider-handle {
  background-color: #fff;
  border: 2px solid var(--sage);
  top: -7px;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  cursor: ew-resize;
}

.sidebar .shop-sidebar-widgets .ui-slider .ui-slider-handle:focus {
  outline: 0;
}

.sidebar .shop-sidebar-widgets .button {
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  float: right;
  padding: 0 15px;
  line-height: 33px;
  font-size: 13px;
}

.sidebar .shop-sidebar-widgets .button:hover {
  background: #323232;
}

.sidebar .shop-sidebar-widgets .button:focus {
  outline: 0;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container {
  position: relative;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0 !important;
  bottom: 0;
  left: 0;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list:focus {
  outline: none;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term {
  padding: 0;
  margin: 6px 0;
  padding-right: 50px;
  padding-left: 25px;
  position: relative;
  margin-right: 10px;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:first-child {
  margin-top: 0;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > a {
  color: #969696;
  padding: 0;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > a > span {
  background: #e8e8e8;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > a > span:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 11px;
  color: #fff;
  font-weight: 400;
  position: absolute;
  top: 1px;
  line-height: 16px;
  left: 3px;
  opacity: 0;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:hover a > span {
  background: var(--sage);
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:hover a > span:before {
  opacity: 1;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:hover span {
  color: var(--sage);
  border-color: var(--sage);
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term .woocommerce_attribute_item-title {
  font-size: 14px;
  line-height: 18px;
  color: #969696;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > .count {
  position: absolute;
  right: 0;
  top: 50%;
  border: 1px solid rgba(130, 130, 130, 0.3);
  width: 35px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  border-radius: 50px;
  font-size: 12px;
  color: #969696;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term .attribute_item-title {
  display: inline-block;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidebar .shop-sidebar-widgets .pgs-widget-layered-nav-list div span {
  text-transform: capitalize;
  padding-left: 10px;
}

.sidebar .pgs-widget-layered-nav-list-container .custom-control-label {
  text-transform: capitalize;
}

.custom-control-input:checked~.custom-control-label::before {
  background: var(--sage);
  border-color: var(--sage);
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.custom-control-label:after {
  position: absolute;
  top: 5px;
  left: -20px;
  width: 13px;
  height: 13px;
}

.sidebar .pgs-widget-layered-nav-list-container .custom-control-input:checked~.custom-control-label::before {
  background: var(--sage);
  border-color: var(--sage);
}

.sidebar .pgs-widget-layered-nav-list-container div label {
  padding-left: 10px;
  margin-bottom: 0;
}

.sidebar .pgs-widget-layered-nav-list-container .collection-filter-checkbox label {
  padding-left: 4px;
}

.sidebar .shop-sidebar-widgets .widget_product_categories {
  max-height: inherit;
  height: auto !important;
  padding-bottom: 10px;
}

.sidebar .shop-sidebar-widgets .widget_product_categories .product-categories {
  position: relative;
  margin-top: 0;
  left: auto;
  right: auto !important;
  overflow: inherit;
  padding: 0;
}

.sidebar .shop-sidebar-widgets .widget_product_categories .product-categories li {
  list-style-type: none;
  position: relative;
  margin: 0;
}

.sidebar .shop-sidebar-widgets .widget_product_categories .product-categories li a {
  display: block;
  padding: 8px 0;
  font-size: 14px;
  line-height: 24px;
}

.sidebar .shop-sidebar-widgets .widget_product_categories .product-categories li a:hover {
  color: var(--sage);
}

.sidebar .shop-sidebar-widgets .pgs_brand_filters .pgs-brand-items {
  padding: 0;
  margin: 0;
}

.sidebar .shop-sidebar-widgets .pgs_brand_filters .pgs-brand-items li {
  position: relative;
  list-style-type: none;
  padding: 12px 15px;
  border: 1px solid #e8e8e8;
  margin: 6px 0;
}

.sidebar .shop-sidebar-widgets .pgs_brand_filters .pgs-brand-items li h5 {
  margin-bottom: 0;
}

.sidebar .shop-sidebar-widgets .pgs_brand_filters .pgs-brand-items li h5 a {
  display: block;
}

.sidebar .shop-sidebar-widgets .pgs_brand_filters .pgs-brand-items li h5 a:hover {
  color: var(--sage);
}

.sidebar .shop-sidebar-widgets .pgs_brand_filters .pgs-brand-items li img {
  height: 23px;
  width: auto;
}

.sidebar .shop-sidebar-widgets .pgs_brand_filters .pgs-brand-items .widget_brand-product-count {
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 13px;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.sidebar .shop-sidebar-widgets .widget_pgs_social_profiles {
  border-top: 1px solid #f1f1f1;
  margin-top: 30px;
  padding-top: 30px;
  margin-bottom: 30px;
}

.sidebar .shop-sidebar-widgets .widget.widget_price_filter {
  padding-bottom: 30px;
}

.carousel-wrapper .item a {
  display: inline-block;
  border: 1px solid #f7f7f7;
  background: #fff;
  width: 100%;
  text-align: center;
  margin-bottom: 7px;
  padding: 15px;
  padding-top: 10px;
  position: relative;
}

.carousel-wrapper .item a:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1px;
  width: 45px;
  height: 1px;
  background: #fff;
  z-index: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.carousel-wrapper .item a:hover {
  border-color: var(--sage);
}

.carousel-wrapper .item a:hover .category-name,
.carousel-wrapper .item a:hover .category-products-count {
  color: var(--sage);
}

.carousel-wrapper .item a:hover .cat-count {
  border-color: var(--sage);
  color: var(--sage);
}

.carousel-wrapper .category-image img {
  display: inline;
  width: auto;
}

.carousel-wrapper .category-name {
  color: #969696;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.carousel-wrapper .category-products-count {
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
}

.carousel-wrapper .category-products-count .cat-count {
  display: inline-block;
  position: relative;
  border: 1px solid #e8e8e8;
  color: #969696;
  min-width: 33px;
  border-radius: 50px;
  line-height: 16px;
  font-size: 12px;
  background: #fff;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.carousel-wrapper .category-products-count .cat-label {
  display: none;
}

.products-header .right-banner,
.products-header .loop-header {
  margin-bottom: 30px;
}

.products-header .loop-header-filters {
  margin-bottom: 15px;
}

.products-header .loop-header-filters .widget {
  margin-bottom: 0;
}

.products-header .loop-header-filters .widget .widgettitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 26px;
  text-transform: uppercase;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #f1f1f1;
  padding-top: 30px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .row > .widget {
  border-left: 1px solid #f1f1f1;
  padding-left: 25px;
  padding-right: 25px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .row > .widget:first-child {
  border-left: none;
  padding-left: 15px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .row > .widget:last-child {
  padding-right: 15px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 30px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_price_filter .price_slider_wrapper .price_slider_wrapper-inner {
  width: 100%;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_price_filter .price_slider_wrapper .button {
  font-size: 14px;
  float: right;
  background: 0 0;
  color: #323232;
  padding: 0;
  line-height: 24px;
  font-weight: 600;
  margin-top: 0px;
  position: relative;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_price_filter .price_slider_wrapper .button:before {
  content: "\f0b0";
  font-family: FontAwesome;
  padding-right: 10px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_price_filter .price_slider_wrapper .button:hover {
  color: var(--sage);
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_price_filter .price_slider_wrapper .ui-slider {
  margin-bottom: 20px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container {
  position: relative;
  width: 100%;
  min-height: 215px;
  max-height: 215px;
  overflow: hidden;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container.has-scrollbar {
  min-height: inherit;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term {
  padding: 0;
  margin: 6px 0;
  padding-right: 50px;
  padding-left: 25px;
  position: relative;
  margin-right: 10px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:first-child {
  margin-top: 0;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > a {
  color: #969696;
  padding: 0;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > a > span {
  background: #e8e8e8;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > a > span:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 11px;
  color: #fff;
  font-weight: 400;
  position: absolute;
  top: 1px;
  line-height: 16px;
  left: 3px;
  opacity: 0;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:hover a > span {
  background: var(--sage);
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:hover a > span:before {
  opacity: 1;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term:hover span {
  color: var(--sage);
  border-color: var(--sage);
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term .woocommerce_attribute_item-title {
  font-size: 14px;
  line-height: 18px;
  color: #969696;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term > .count {
  position: absolute;
  right: 0;
  top: 50%;
  border: 1px solid rgba(130, 130, 130, 0.3);
  width: 35px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  border-radius: 50px;
  font-size: 12px;
  color: #969696;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list-container > .pgs-widget-layered-nav-list > .wc-layered-nav-term .attribute_item-title {
  display: inline-block;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .pgs-widget-layered-nav-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_rating_filter ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_rating_filter ul li {
  padding: 0 0 1px;
  list-style: none;
  margin: 5px 0px;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_rating_filter ul li a {
  padding: 1px 0;
  text-decoration: none;
  display: inline-block;
  color: #969696;
  line-height: 24px;
  font-weight: 400;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_rating_filter ul li a:hover {
  color: var(--sage);
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_rating_filter ul li .star-rating {
  float: none;
  display: inline-block;
}

.products-header .loop-header-filters .pgs_widgets_shop-filters .widget_rating_filter ul li .star-rating .fa-star {
  color: #ffbe00;
}

.products-header .pgs-shop-filters-wrapper .shop-filter {
  padding: 0;
  padding-right: 10px;
}

.products-header .pgs-shop-filters-wrapper .shop-filter .select2 {
  width: 100%;
}

.products-header .pgs-shop-filters-wrapper .row > div:last-child .shop-filter {
  padding-right: 0;
}

.products-header .pgs-shop-filters-wrapper .row > [class*=col] {
  margin-bottom: 10px;
}

.products-header .pgs-shop-filters-wrapper .shop-filter-search {
  position: relative;
}

.products-header .pgs-shop-filters-wrapper .shop-filter-search:before {
  content: "\f002";
  font-family: FontAwesome;
  position: absolute;
  top: 11px;
  left: 15px;
  margin-right: 0;
}

.products-header .pgs-shop-filters-wrapper .shop-filter-search input.search-field {
  padding-left: 35px;
  background: #fff;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  height: 45px;
  border-radius: 0;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .price_slider_wrapper-inner {
  float: left;
  width: 67%;
  position: relative;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .price_slider_wrapper-inner input#amount {
  height: auto;
  color: #969696;
  border: 0;
  position: absolute;
  padding: 0;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .ui-slider {
  margin-top: 5px;
  margin-bottom: 5px;
  background: #e9e9e9;
  height: 5px;
  border-radius: 1em;
  border: 0;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .ui-slider .ui-slider-range {
  background-color: var(--sage);
  border-radius: 0;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .ui-slider .ui-slider-handle {
  background-color: #fff;
  border: 2px solid var(--sage);
  top: -7px;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  cursor: ew-resize;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .ui-slider .ui-slider-handle:focus {
  outline: 0;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .button {
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  float: right;
  padding: 0 15px;
  line-height: 33px;
  font-size: 13px;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .button:hover {
  background: #323232;
}

.products-header .pgs-shop-filters-wrapper .price_slider_wrapper .button:focus {
  outline: 0;
}

.products-header .select2-container {
  width: 100% !important;
}

.products-header .select2-container.select2-container--default {
  padding-left: 0;
  height: 35px;
}

.products-header .select2-container.select2-container--default .select2-selection--single {
  border-color: #e8e8e8;
  padding-left: 5px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 0;
  height: 35px;
}

.products-header .select2-container.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 33px;
  font-size: 14px;
  color: #969696;
}

.products-header .select2-container.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 30px;
  height: 33px;
  line-height: 33px;
}

.products-header .loop-header-tools {
  padding: 10px 0;
}

.products-header .loop-header-tools .result-count {
  float: left;
  padding: 0;
  margin: 0;
  line-height: 42px;
  margin-right: 15px;
}

.products-header .loop-header-tools .ordering {
  float: right;
  display: inline-block;
  margin: 0;
  width: 206px;
}

.products-header .loop-header-tools .gridlist-toggle-wrap {
  float: right;
  margin-left: 15px;
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle {
  padding: 0;
  margin: 0;
  margin-top: 12px;
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle .gridlist-button {
  display: inline-block;
  background-color: #ddd;
  width: 19px;
  height: 19px;
  color: #323232;
  font-size: 0;
  line-height: normal !important;
  padding: 0;
  margin-left: 8px;
  text-align: center;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle .gridlist-button em {
  display: none;
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle .grid-2-column {
  background-image: url("../images/shop/grid-2-icon.png");
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle .grid-3-column {
  background-image: url("../images/shop/grid-3-icon.png");
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle .grid-4-column {
  background-image: url("../images/shop/grid-4-icon.png");
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle .gridlist-toggle-list {
  background-image: url("../images/shop/list-icon.png");
}

.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle a:hover,
.products-header .loop-header-tools .gridlist-toggle-wrap .gridlist-toggle a.active {
  background-color: var(--sage);
}

.products .product {
  -webkit-animation: animateZoom .5s ease-in-out;
  animation: animateZoom .5s ease-in-out;
  margin-bottom: 30px;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product .product-thumbnail .product-thumbnail-main {
  overflow: hidden;
}

.products .product .product-thumbnail .product-thumbnail-swap {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  overflow: hidden;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

@media screen and (min-width: 1025px) {
  .products .product a img {
    width: 100%;
    height: auto;
  
    display: block;
    box-shadow: none;
  }
}

@media screen and (max-width: 1024px) {
  .products .product a img {
    width: 100%;
    height: calc(2.10059*((100vw - 28px)/2 - 4px));
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    display: block;
    box-shadow: none;
  }
}



.col-6-custom {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
}

.products .product .price {
  margin-bottom: 0;
  font-size: .857em;
  display: block;
  font-weight: 400;
  color: #323232;
}

.products .product .price del {
  color: inherit;
  opacity: .5;
  display: inline-block;
}

.products .product .price ins {
  background: 0 0;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.products .product .product-actions {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  opacity: 1;
  text-align: center;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  z-index: 9;
}

.products .product .product-actions .product-action-add-to-cart {
  display: inline-block;
}

.products .product .product-actions a {
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0 2px !important;
  display: inline-block;
  font-size: 0;
  background: var(--sage);
  line-height: 32px;
  position: relative;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
}

.products .product .product-actions a:before {
  content: "";
  position: relative;
  left: 0;
  top: 6px;
  font-family: FontAwesome;
  font-size: 16px;
}

.products .product .product-actions .product-action-wishlist a:before {
  content: "\f004";
}

.products .product:hover .product-actions {
  opacity: 1;
}

.products .product .onsale,
.products .product .featured {
  position: absolute;
  top: 15px !important;
  left: auto;
  right: 0;
  z-index: 5;
  font-weight: 500;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  height: 20px;
  line-height: 20px;
  padding: 0 8px 0 8px;
  border-radius: 0;
  transform: rotate(-45deg) translateY(-100%);
  -webkit-transform: rotate(0) translateY(0);
  transform-origin: right top;
  -webkit-transform-origin: right top;
  text-align: center;
  min-height: inherit;
  min-width: inherit;
  margin: 0 !important;
  background: #323232;
}

.products .product .onsale:before,
.products .product .onsale:after,
.products .product .featured:before,
.products .product .featured:after {
  content: "";
  position: absolute;
  left: -10px;
  color: #323232;
  border: solid 10px transparent;
  border-left-color: #323232;
}

.products .product .onsale:before,
.products .product .featured:before {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.products .product .onsale:after,
.products .product .featured:after {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.products .product .featured {
  background: #f4524d;
}

.products .product .featured:before,
.products .product .featured:after {
  content: "";
  position: absolute;
  left: -10px;
  color: #f4524d;
  border: solid 10px transparent;
  border-left-color: #f4524d;
}

.products .product-inner .stock {
  display: inline-block;
  background: rgba(97, 208, 8, 0.8);
  font-size: 12px;
  line-height: 22px;
  color: #fff;
  padding: 0 7px;
  position: absolute;
  left: 15px;
  bottom: 15px;
  margin-bottom: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.products .product-inner .out-of-stock {
  background: #de4646;
  font-size: 14px;
  min-width: 110px;
  text-align: center;
}

.products .product-inner:hover .product-thumbnail .product-thumbnail-main img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.products .product-inner:hover .product-thumbnail .product-thumbnail-swap {
  opacity: 1;
}

.products .product-inner:hover .product-thumbnail .product-thumbnail-swap img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.products .product-thumbnail {
  position: relative;
  overflow: hidden;
}

.products a {
  text-decoration: none;
}

.products .product-thumbnail-inner {
  position: relative;
}

.products .product-thumbnail-inner img {
  width: 100%;
  -webkit-transition: all .6s ease-out 0s;
  -moz-transition: all .6s ease-out 0s;
  transition: all .6s ease-out 0s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.products .product-info {
  text-align: center;
  padding: 20px;
}

.products .product-info .svelta-product-category {
  text-transform: capitalize;
  display: flex;
}

.products .product-info .fa-star,
.products .product-info .fa-star-half-o {
  color: #ffbe00;
}

.products .product-info .svelta-product-category,
.products .product-info .svelta-product-category a {
  font-size: 14px;
  color: #ababab;
  line-height: 18px;
  display: block;
}

.products .product-info .svelta-product-name{
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    font-size: 12px;
    text-transform: capitalize;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    height: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}



.products .product-info .svelta-product-category:hover,
.products .product-info .svelta-product-category a:hover {
  color: #323232;
}

.products .product-info .product-name {
  padding-bottom: 0;
  margin: 0;
}

.products .product-info .product-name a {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  display: block;
  color: #323232;
}

.products .product-info .product-name a:hover,
.products .product-info .product-name a:focus {
  color: var(--sage);
}

.products .product-info .price .amount-installment {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #757575;
  font-size: 12px;
}

.products .product_title_type-single_line .product-info .product-name a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.products .product_icon_type-line-icon .product-actions .product-action-wishlist .add_to_wishlist:before {
  content: "\f18a";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product_icon_type-line-icon .product-actions .product-action-compare a:before {
  content: "\f176";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product_icon_type-line-icon .product-actions .product-action-add-to-cart a:before {
  content: "\f1bf";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product_icon_type-line-icon .product-actions .product-action-quick-view a:before {
  content: "\f109";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product-details__short-description {
  display: none;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
  max-height: inherit;
  overflow: hidden;
}

.products .product-details__short-description p {
  margin: 0;
}

.products .open-quick-view {
  position: absolute;
  top: 15px;
  left: 50%;
  min-width: 115px;
  background: var(--sage);
  color: #fff;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  padding: 7px 20px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
}

.products .open-edit-view {
  position: absolute;
  top: 15px;
  right: 60px;
  background: var(--sage);
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  z-index: 9;
}

.product-delete {
  position: absolute;
  top: 15px;
  right: 20px;
  background: #dc3545;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  z-index: 9;
}

.products .open-edit-view:hover,
.product-delete:hover {
  color: #ffffff;
  background: #323232;
}

.products .product-hover-style-default {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-default .product-inner .stock {
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  opacity: 1;
}

.products .product-hover-style-default .product-inner .out-of-stock {
  width: calc(100% - 30px);
  line-height: 28px;
  font-size: 13px;
}

.products .product-hover-style-default .open-quick-view {
  top: 5px;
  left: 5px;
  opacity: 1;
  transform: translate(0, 0);
  font-size: 0;
  color: #323232;
  background: 0 0;
  min-width: auto;
  padding: 10px;
  line-height: normal;
  vertical-align: top;
  border-radius: 0;
}

.products .product-hover-style-default .open-quick-view:before {
  content: "\f109";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
  position: relative;
  left: 0;
  top: 0;
}

.products .product-hover-style-default .open-quick-view:hover {
  color: var(--sage);
}

.products .product-hover-style-default .product-actions {
  padding-left: 15px;
  padding-right: 15px;
  bottom: 10px;
  opacity: 0;
}

.products .product-hover-style-default .product-actions .product-actions-inner {
  background: #323232;
}

.products .product-hover-style-default .product-actions .product-actions-inner > .product-action {
  display: inline-block;
  vertical-align: top;
}

.products .product-hover-style-default .product-actions .product-actions-inner.product-action-wishlist {
  float: none;
}

.products .product-hover-style-default .product-actions .product-actions-inner > .product-action-add-to-cart + .product-action-wishlist {
  float: left;
}

.products .product-hover-style-default .product-actions .product-action-add-to-cart {
  width: calc(100% - 46px);
  border-left: 1px solid #5a5a5a;
}

.products .product-hover-style-default .product-actions .product-action-add-to-cart a {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: #a7a5a6;
  line-height: 40px;
  text-transform: uppercase;
}

.products .product-hover-style-default .product-actions a {
  background: 0 0;
  box-shadow: inherit;
  margin: 0 !important;
  border-radius: 0;
  color: #a7a5a6;
}

.products .product-hover-style-default .product-actions a:hover {
  color: #fff;
}

.products .product-hover-style-default .product-info {
  padding: 0;
  margin-top: 10px;
  text-align: left;
  padding-left: 10px;
  position: relative;
}

.products .product-hover-style-default .product-info .product-name {
  padding-top: 5px;
  margin-bottom: 3px;
}

.products .product-hover-style-default .product-info .price {
  text-align: inherit;
}

.products .product-hover-style-default .product-rating-price {
  display: flex;
}

.products .product-hover-style-default .product-rating-price .product-rating {
  margin-left: auto;
}

.products .product-hover-style-default:hover .product-inner .stock {
  opacity: 0;
}

.products .product-hover-style-default:hover .open-quick-view {
  opacity: 1;
  background: 0 0;
}

.products .product-hover-style-default:hover .product-actions {
  bottom: 15px;
}

.products .product-hover-style-default.product_icon_type-line-icon .open-quick-view:before {
  content: "\f109";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product-hover-style-default.product-hover-button-style-light .product-actions-inner {
  background: #fff;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
}

.products .product-hover-style-default.product-hover-button-style-light .product-actions .product-action-add-to-cart {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.products .product-hover-style-default.product-hover-button-style-light .product-actions .product-action-add-to-cart a {
  color: #323232;
}

.products .product-hover-style-default.product-hover-button-style-light .product-actions .product-action-add-to-cart a:hover,
.products .product-hover-style-default.product-hover-button-style-light .product-actions .product-action-add-to-cart a:focus {
  color: var(--sage);
}

.products .product-hover-style-default.product-hover-button-style-light .product-actions .product-action-add-to-cart a:before {
  content: '';
}

.products .product-hover-style-default.product-hover-button-style-light .product-actions a {
  color: #323232;
}

.products .product-hover-style-default.product-hover-button-style-light .product-actions a:hover,
.products .product-hover-style-default.product-hover-button-style-light .product-actions a:focus {
  color: var(--sage);
}

.products .product-hover-style-icon-top-left,
.products .product-hover-style-icon-top-right {
  border: none;
  box-shadow: none;
}

.products .product-hover-style-icon-top-left .product-actions,
.products .product-hover-style-icon-top-right .product-actions {
  position: absolute;
  top: 15px;
  bottom: auto;
  left: -5px;
  width: auto;
  opacity: 0;
  visibility: hidden;
}

.products .product-hover-style-icon-top-left .product-actions .product-actions-inner,
.products .product-hover-style-icon-top-right .product-actions .product-actions-inner {
  background: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.products .product-hover-style-icon-top-left .product-actions .product-actions-inner > .product-action,
.products .product-hover-style-icon-top-right .product-actions .product-actions-inner > .product-action {
  position: relative;
}

.products .product-hover-style-icon-top-left .product-actions .product-actions-inner > .product-action:after,
.products .product-hover-style-icon-top-right .product-actions .product-actions-inner > .product-action:after {
  content: "";
  width: 25px;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  left: 15px;
  bottom: 0;
}

.products .product-hover-style-icon-top-left .product-actions a,
.products .product-hover-style-icon-top-right .product-actions a {
  width: 55px;
  height: 55px;
  line-height: 40px;
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  border-radius: 0;
  box-shadow: none;
}

.products .product-hover-style-icon-top-left .product-actions a:hover,
.products .product-hover-style-icon-top-right .product-actions a:hover {
  color: var(--sage);
}

.products .product-hover-style-icon-top-left .open-quick-view,
.products .product-hover-style-icon-top-right .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-icon-top-left .product-inner .stock,
.products .product-hover-style-icon-top-right .product-inner .stock {
  bottom: auto;
  top: 15px;
}

.products .product-hover-style-icon-top-left .product-inner .out-of-stock,
.products .product-hover-style-icon-top-right .product-inner .out-of-stock {
  width: calc(100% - 30px);
  line-height: 28px;
  font-size: 13px;
  top: auto;
  bottom: 15px;
  opacity: 1;
}

.products .product-hover-style-icon-top-left .product-info,
.products .product-hover-style-icon-top-right .product-info {
  padding: 0;
  padding-top: 12px;
  padding-right: 35px;
  position: relative;
  text-align: left;
}

.products .product-hover-style-icon-top-left .product-info .svelta-product-category,
.products .product-hover-style-icon-top-right .product-info .svelta-product-category {
  display: none;
}

.products .product-hover-style-icon-top-left .product-info .product-name,
.products .product-hover-style-icon-top-right .product-info .product-name {
  padding: 0;
}

.products .product-hover-style-icon-top-left .product-info .price,
.products .product-hover-style-icon-top-right .product-info .price {
  text-align: left;
}

.products .product-hover-style-icon-top-left .product-info .price .amount,
.products .product-hover-style-icon-top-right .product-info .price .amount {
  color: var(--sage);
}

.products .product-hover-style-icon-top-left .product-info .price del .amount,
.products .product-hover-style-icon-top-right .product-info .price del .amount {
  color: #323232;
}

.products .product-hover-style-icon-top-left .product-action-wishlist,
.products .product-hover-style-icon-top-right .product-action-wishlist {
  position: absolute;
  right: 0;
  top: 0;
}

.products .product-hover-style-icon-top-left .product-action-wishlist a,
.products .product-hover-style-icon-top-right .product-action-wishlist a {
  width: 25px;
  height: 45px;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 0;
  line-height: 32px;
  color: #323232;
}

.products .product-hover-style-icon-top-left .product-action-wishlist a:before,
.products .product-hover-style-icon-top-right .product-action-wishlist a:before {
  position: relative;
  left: 0;
  top: 6px;
  content: "\f18a";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product-hover-style-icon-top-left:hover .product-actions,
.products .product-hover-style-icon-top-right:hover .product-actions {
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-icon-top-right .product-actions {
  left: auto;
  right: -5px;
}

.products .product-hover-style-icon-top-right .product-inner .stock {
  left: auto;
  right: 15px;
}

.products .product-hover-style-icon-top-right:hover .product-actions {
  right: 15px;
  opacity: 1;
  visibility: visible;
  left: auto;
}

.products .product-hover-style-icon-bottom-right {
  border: none;
  box-shadow: none;
}

.products .product-hover-style-icon-bottom-right .product-actions {
  width: auto;
  transform: inherit;
  top: auto;
  bottom: 30px;
  opacity: 0;
  left: auto;
  right: 15px;
}

.products .product-hover-style-icon-bottom-right .product-actions .product-actions-inner {
  background: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.products .product-hover-style-icon-bottom-right .product-actions .product-actions-inner > .product-action {
  position: relative;
}

.products .product-hover-style-icon-bottom-right .product-actions .product-actions-inner > .product-action:after {
  content: "";
  width: 25px;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  left: 15px;
  bottom: 0;
}

.products .product-hover-style-icon-bottom-right .product-actions .product-actions-inner > .product-action:last-child:after {
  height: 0 !important;
}

.products .product-hover-style-icon-bottom-right .product-actions a {
  width: 55px;
  height: 55px;
  line-height: 40px;
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  border-radius: 0;
  box-shadow: none;
}

.products .product-hover-style-icon-bottom-right .product-actions a:hover {
  color: var(--sage);
}

.products .product-hover-style-icon-bottom-right .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-icon-bottom-right .product-inner .stock {
  bottom: auto;
  top: 15px;
}

.products .product-hover-style-icon-bottom-right .product-inner .out-of-stock {
  width: calc(100% - 30px);
  line-height: 28px;
  font-size: 13px;
  top: auto;
  bottom: 15px;
  opacity: 1;
}

.products .product-hover-style-icon-bottom-right .product-info {
  padding: 0;
  padding-top: 15px;
  position: relative;
}

.products .product-hover-style-icon-bottom-right .product-info .product-name {
  padding-top: 5px;
}

.products .product-hover-style-icon-bottom-right:hover .product-actions {
  bottom: 15px;
  opacity: 1;
}

.products .product-hover-style-image-center {
  box-shadow: none;
  border: 1px solid #eee;
}

.products .product-hover-style-image-center .stock {
  bottom: auto;
  top: 15px;
}

.products .product-hover-style-image-center .open-quick-view {
  top: auto;
  bottom: 30px;
  opacity: 0;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.products .product-hover-style-image-center .product-actions {
  bottom: auto;
  top: 60%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}

.products .product-hover-style-image-center .product-actions .product-actions-inner > .product-action {
  display: inline-block;
  vertical-align: top;
}

.products .product-hover-style-image-center .open-quick-view {
  top: auto;
  bottom: 30px;
  opacity: 0;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.products .product-hover-style-image-center .product-info {
  position: relative;
  border: none;
  min-height: 118px;
}

.products .product-hover-style-image-center .product-info .product-name {
  padding-top: 5px;
}

.products .product-hover-style-image-center.product-hover-button-style-border .product-actions a {
  color: #fff;
}

.products .product-hover-style-image-center.product-hover-button-style-border .open-quick-view {
  background: #fff;
  color: #323232;
}

.products .product-hover-style-image-center.product-hover-button-style-border .open-quick-view:hover {
  color: #fff;
}

.products .product-hover-style-image-center:hover {
  -webkit-box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
}

.products .product-hover-style-image-center:hover .product-actions {
  opacity: 1;
  bottom: auto;
  top: 50%;
}

.products .product-hover-style-image-center:hover .open-quick-view {
  bottom: 15px;
  opacity: 1;
}

.products .product-hover-style-image-center:hover .open-quick-view:hover {
  background: #323232;
}

.products .product-hover-style-image-left {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-image-left .product-actions {
  width: auto;
  transform: inherit;
  top: auto;
  bottom: 30px;
  opacity: 0;
  left: 15px;
}

.products .product-hover-style-image-left .product-actions .product-actions-inner {
  background: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.products .product-hover-style-image-left .product-actions .product-actions-inner > .product-action {
  position: relative;
}

.products .product-hover-style-image-left .product-actions .product-actions-inner > .product-action:after {
  content: "";
  width: 25px;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: 99;
}

.products .product-hover-style-image-left .product-actions .product-actions-inner > .product-action:last-child:after {
  content: "";
  width: 25px;
  height: 0;
  background: #e5e5e5;
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: 99;
}

.products .product-hover-style-image-left .product-actions a {
  width: 55px;
  height: 55px;
  min-width: inherit;
  line-height: 40px;
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.products .product-hover-style-image-left .price {
  overflow: hidden;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-image-left .price > * {
  position: relative;
  top: 0;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-image-left .price + .product-action-add-to-cart {
  margin-top: -30px;
}

.products .product-hover-style-image-left .product-info .product-name {
  padding: 5px 0px;
}

.products .product-hover-style-image-left .product-action-add-to-cart {
  margin: 0;
  margin-top: 0;
  line-height: 22px;
  overflow: hidden;
}

.products .product-hover-style-image-left .product-action-add-to-cart .button {
  margin: 0;
  background: 0 0;
  color: #323232;
  padding: 0;
  line-height: 22px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
  bottom: -22px;
}

.products .product-hover-style-image-left .product-action-add-to-cart .button:before {
  position: relative;
  left: 0;
  top: 0;
  padding-right: 10px;
  font-weight: 400;
  content: "\f139";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product-hover-style-image-left .product-action-add-to-cart .button:hover {
  color: var(--sage);
}

.products .product-hover-style-image-left .product-inner .stock {
  bottom: auto;
  top: 15px;
}

.products .product-hover-style-image-left .open-quick-view {
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-image-left:hover {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-image-left:hover .product-actions {
  bottom: 15px;
  opacity: 1;
}

.products .product-hover-style-image-left:hover .price {
  opacity: 0;
}

.products .product-hover-style-image-left:hover .product-action-add-to-cart .button {
  bottom: 0;
}

.products .product-hover-style-image-left.product-hover-button-style-flat.product-hover-button-style-dark .product-actions .product-actions-inner {
  background: #323232;
}

.products .product-hover-button-style-flat .product-actions a {
  background: #fff;
  color: #323232;
}

.products .product-hover-button-style-flat .product-actions a:hover {
  color: var(--sage);
}

.products .product-hover-button-style-border .product-thumbnail {
  z-index: 0;
}

.products .product-hover-button-style-border .product-thumbnail:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
  background: var(--sage);
  z-index: 1;
}

.products .product-hover-button-style-border .product-actions a {
  background: 0 0;
  box-shadow: none;
  border: 2px solid #fff;
  color: #fff;
  width: 43px;
  height: 43px;
  line-height: 28px;
}

.products .product-hover-button-style-border .product-actions a:hover {
  background: #fff;
  border-color: transparent !important;
  color: var(--sage);
}

.products .product-hover-button-style-border:hover .product-thumbnail:after {
  opacity: .8;
}

.products .product-hover-style-icons-transparent-center {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-icons-transparent-center .product-inner .stock {
  top: 15px;
  bottom: auto;
}

.products .product-hover-style-icons-transparent-center .product-inner .stock.out-of-stock {
  top: auto;
  bottom: 15px;
}

.products .product-hover-style-icons-transparent-center .product-actions {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.35);
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

.products .product-hover-style-icons-transparent-center .product-actions .product-actions-inner {
  display: inline-block;
  text-align: center;
  position: relative;
  top: 45%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.products .product-hover-style-icons-transparent-center .product-actions .product-actions-inner > .product-action {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.products .product-hover-style-icons-transparent-center .product-actions .product-actions-inner > .product-action:after {
  content: "";
  width: 1px;
  height: 18px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: -2px;
  top: 13px;
}

.products .product-hover-style-icons-transparent-center .product-actions .product-actions-inner > .product-action a {
  background: 0 0;
  box-shadow: none;
}

.products .product-hover-style-icons-transparent-center .product-actions .product-actions-inner .product-action-add-to-cart {
  float: none;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

.products .product-hover-style-icons-transparent-center .product-actions .product-actions-inner .product-action-add-to-cart a {
  margin: 0 !important;
  width: 100%;
  height: auto;
  font-size: 13px;
  background: 0 0;
  border: 2px solid #fff;
  border-radius: 0;
  padding: 8px 25px;
  display: inline;
  text-transform: uppercase;
  cursor: pointer;
}

.products .product-hover-style-icons-transparent-center .product-actions .product-actions-inner .product-action-add-to-cart a:hover {
  background: #fff;
  color: #323232;
}

.products .product-hover-style-icons-transparent-center .product-action-quick-view:after {
  width: 0 !important;
}

.products .product-hover-style-icons-transparent-center .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-icons-transparent-center .product-action-add-to-cart a:before {
  content: '' !important;
}

.products .product-hover-style-icons-transparent-center .product-action-add-to-cart:after {
  width: 0 !important;
}

.products .product-hover-style-icons-transparent-center .product-inner .out-of-stock {
  width: calc(100% - 30px);
  line-height: 28px;
  font-size: 13px;
}

.products .product-hover-style-icons-transparent-center:hover .product-actions {
  visibility: visible;
  opacity: 1;
}

.products .product-hover-style-icons-transparent-center:hover .product-actions .product-actions-inner {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-info-transparent-center {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-info-transparent-center .product-inner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .25s ease-out 0s;
  -moz-transition: all .25s ease-out 0s;
  transition: all .25s ease-out 0s;
}

.products .product-hover-style-info-transparent-center .product-info {
  position: absolute;
  left: 0;
  top: 48%;
  z-index: 2;
  text-align: center;
  width: 100%;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.products .product-hover-style-info-transparent-center .product-info .svelta-product-category,
.products .product-hover-style-info-transparent-center .product-info .svelta-product-category a {
  color: rgba(255, 255, 255, 0.9);
}

.products .product-hover-style-info-transparent-center .product-info .product-name {
  padding-top: 0.5em;
}

.products .product-hover-style-info-transparent-center .product-info .product-name a {
  color: #fff;
}

.products .product-hover-style-info-transparent-center .product-info .product-name a:hover {
  color: var(--sage);
}

.products .product-hover-style-info-transparent-center .product-info .price {
  line-height: 24px;
}

.products .product-hover-style-info-transparent-center .product-info .price del .amount {
  color: #fff;
  opacity: 0.5;
}

.products .product-hover-style-info-transparent-center .product-info .price ins .amount {
  color: #fff;
}

.products .product-hover-style-info-transparent-center .product-actions {
  position: relative;
  bottom: auto;
  margin-bottom: 5px;
}

.products .product-hover-style-info-transparent-center .product-actions .product-actions-inner > .product-action {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.products .product-hover-style-info-transparent-center .product-actions .product-actions-inner > .product-action:after {
  content: "";
  width: 1px;
  height: 18px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: -2px;
  top: 13px;
}

.products .product-hover-style-info-transparent-center .product-actions .product-actions-inner > .product-action a {
  background: 0 0;
  box-shadow: none;
}

.products .product-hover-style-info-transparent-center .product-action-add-to-cart {
  float: none;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

.products .product-hover-style-info-transparent-center .product-action-add-to-cart a {
  margin: 0 !important;
  width: 100%;
  height: auto;
  font-size: 13px;
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 0;
  padding: 8px 25px;
  display: inline;
  text-transform: uppercase;
  cursor: pointer;
}

.products .product-hover-style-info-transparent-center .product-action-add-to-cart a:hover {
  background: #fff;
  color: #323232;
}

.products .product-hover-style-info-transparent-center .product-action-quick-view:after {
  width: 0 !important;
}

.products .product-hover-style-info-transparent-center .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-info-transparent-center .product-action-add-to-cart a:before {
  content: '' !important;
}

.products .product-hover-style-info-transparent-center .product-action-add-to-cart:after {
  width: 0 !important;
}

.products .product-hover-style-info-transparent-center .product-inner .out-of-stock {
  width: calc(100% - 30px);
  line-height: 28px;
  font-size: 13px;
}

.products .product-hover-style-info-transparent-center:hover .product-actions {
  opacity: 1;
}

.products .product-hover-style-info-transparent-center:hover .product-info {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-info-transparent-center:hover .product-inner:before {
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-standard-info-transparent {
  border: none;
  box-shadow: none;
}

.products .product-hover-style-standard-info-transparent .product-inner .stock {
  bottom: 20px;
  right: 15px;
  left: auto;
  z-index: 1;
}

.products .product-hover-style-standard-info-transparent .product-actions {
  position: absolute;
  bottom: auto;
  left: auto;
  top: 15px;
  right: -5px;
  width: auto;
  opacity: 0;
  visibility: hidden;
}

.products .product-hover-style-standard-info-transparent .product-actions .product-actions-inner {
  background: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.products .product-hover-style-standard-info-transparent .product-actions .product-actions-inner > .product-action {
  position: relative;
}

.products .product-hover-style-standard-info-transparent .product-actions .product-actions-inner > .product-action:after {
  content: "";
  width: 25px;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  left: 15px;
  bottom: 0;
}

.products .product-hover-style-standard-info-transparent .product-actions a {
  width: 55px;
  height: 50px;
  line-height: 40px;
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  border-radius: 0;
  box-shadow: none;
}

.products .product-hover-style-standard-info-transparent .product-actions a:hover {
  color: var(--sage);
}

.products .product-hover-style-standard-info-transparent .product-info {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  padding-right: 85px;
  text-align: left;
  background: rgba(0, 0, 0, 0.1);
}

.products .product-hover-style-standard-info-transparent .product-info .svelta-product-category,
.products .product-hover-style-standard-info-transparent .product-info .svelta-product-category a,
.products .product-hover-style-standard-info-transparent .product-info .svelta-product-category a:hover {
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  font-size: 12px;
}

.products .product-hover-style-standard-info-transparent .product-info .product-name {
  padding-top: 5px;
}

.products .product-hover-style-standard-info-transparent .product-info .product-name a {
  color: #fff;
}

.products .product-hover-style-standard-info-transparent .product-info .product-name a:hover {
  color: var(--sage);
}

.products .product-hover-style-standard-info-transparent .product-info .standard-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  padding-right: 90px;
}

.products .product-hover-style-standard-info-transparent .product-info .standard-info .price {
  line-height: 24px;
  color: #fff;
}

.products .product-hover-style-standard-info-transparent:hover {
  border: none;
  box-shadow: none;
}

.products .product-hover-style-standard-info-transparent:hover .product-actions {
  right: 15px;
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-standard-quick-shop {
  border: none;
  box-shadow: none;
}

.products .product-hover-style-standard-quick-shop .onsale {
  right: auto;
  left: 0;
}

.products .product-hover-style-standard-quick-shop .product-actions {
  position: absolute;
  bottom: auto;
  left: auto;
  top: 15px;
  right: -5px;
  width: auto;
  opacity: 0;
  visibility: hidden;
}

.products .product-hover-style-standard-quick-shop .product-actions .product-actions-inner {
  background: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.products .product-hover-style-standard-quick-shop .product-actions .product-actions-inner > .product-action {
  position: relative;
}

.products .product-hover-style-standard-quick-shop .product-actions .product-actions-inner > .product-action a {
  width: 55px;
  height: 50px;
  line-height: 40px;
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  border-radius: 0;
  box-shadow: none;
}

.products .product-hover-style-standard-quick-shop .product-actions .product-actions-inner > .product-action a:hover {
  color: var(--sage);
}

.products .product-hover-style-standard-quick-shop .product-actions .product-actions-inner > .product-action:after {
  content: "";
  width: 25px;
  height: 1px;
  background: #e5e5e5;
  position: absolute;
  left: 15px;
  bottom: 0;
}

.products .product-hover-style-standard-quick-shop .product-action-add-to-cart {
  display: block;
  margin-bottom: 10px;
  margin-top: 5px;
}

.products .product-hover-style-standard-quick-shop .product-action-add-to-cart a {
  margin-top: 0;
  position: relative;
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
}

.products .product-hover-style-standard-quick-shop .product-action-add-to-cart a:hover {
  background: #323232;
}

.products .product-hover-style-standard-quick-shop:hover .product-actions {
  right: 15px;
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-minimal {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-minimal .stock {
  bottom: auto;
  top: 15px;
}

.products .product-hover-style-minimal .product-action-quick-view {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  padding: 7px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .25s ease-out 0s;
  -moz-transition: all .25s ease-out 0s;
  transition: all .25s ease-out 0s;
}

.products .product-hover-style-minimal .product-action-quick-view .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 0;
  padding: 5px 0;
  background: #fff;
  color: #969696;
}

.products .product-hover-style-minimal .product-action-quick-view .open-quick-view:before {
  content: "\f06e";
  position: relative;
  font-family: FontAwesome;
  font-size: 14px;
  font-weight: 400;
  padding-right: 8px;
}

.products .product-hover-style-minimal .product-info {
  padding: 0;
  padding-top: 10px;
  text-align: left;
  position: relative;
}

.products .product-hover-style-minimal .product-info .svelta-product-category {
  display: none;
}

.products .product-hover-style-minimal .product-info .product-name {
  padding-top: 5px;
}

.products .product-hover-style-minimal .product-info .product-actions {
  position: relative;
  bottom: auto;
  width: auto;
  left: auto;
  float: right;
  line-height: 20px;
}

.products .product-hover-style-minimal .product-info .product-actions .product-actions-inner > .product-action {
  float: left;
}

.products .product-hover-style-minimal .product-info .product-actions .product-actions-inner > .product-action a {
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  box-shadow: none;
  width: 35px;
  height: 30px;
  line-height: 20px;
  vertical-align: top;
}

.products .product-hover-style-minimal .product-info .product-actions .product-actions-inner > .product-action a:hover {
  color: var(--sage);
}

.products .product-hover-style-minimal .product-info .price {
  line-height: 28px;
  text-align: left;
  visibility: visible;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-minimal .product-info .price + .product-action-add-to-cart {
  margin-top: -27px;
}

.products .product-hover-style-minimal .product-info .price + .product-action-add-to-cart a {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
}

.products .product-hover-style-minimal .product-info .product-action-add-to-cart {
  line-height: 20px;
  height: 25px;
  margin-top: 0;
  overflow: hidden;
}

.products .product-hover-style-minimal .product-info .product-action-add-to-cart a {
  margin-top: 0;
  background: 0 0;
  color: var(--sage);
  padding: 0;
  line-height: 20px;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all .25s ease-out 0s;
  -moz-transition: all .25s ease-out 0s;
  transition: all .25s ease-out 0s;
}

.products .product-hover-style-minimal:hover {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-minimal:hover .product-action-quick-view {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-minimal:hover .product-action-quick-view .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 0;
  padding: 5px 0;
  background: #fff;
  color: #969696;
}

.products .product-hover-style-minimal:hover .product-info .price {
  opacity: 0;
  visibility: hidden;
}

.products .product-hover-style-minimal:hover .product-info .product-action-add-to-cart a {
  transform: translateY(0);
}

.products .product-hover-style-minimal-hover-cart {
  border: none;
  box-shadow: none;
  background: 0 0;
  margin-bottom: 60px;
}

.products .product-hover-style-minimal-hover-cart .product-action-add-to-cart {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-minimal-hover-cart .product-action-add-to-cart a {
  font-size: 0;
  color: #fff;
  background: 0 0;
  padding: 0;
  position: relative;
  top: 47%;
  opacity: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.products .product-hover-style-minimal-hover-cart .product-action-add-to-cart a:before {
  content: "\f139";
  font-family: pgsicon-ecommerce;
  font-size: 42px;
}

.products .product-hover-style-minimal-hover-cart .product-name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 7px;
  width: 100%;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  margin: 0;
}

.products .product-hover-style-minimal-hover-cart .product-name a {
  background: #fff;
  padding: 10px 10px;
  text-align: center;
  font-size: 13px;
  color: #323232;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  display: block;
}

.products .product-hover-style-minimal-hover-cart .product-name a:hover {
  color: var(--sage);
}

.products .product-hover-style-minimal-hover-cart .product-info {
  position: relative;
  padding: 0;
  padding-top: 10px;
  padding-right: 110px;
}

.products .product-hover-style-minimal-hover-cart .product-info .price {
  line-height: 22px;
  text-align: left;
  padding-top: 5px;
  float: left;
}

.products .product-hover-style-minimal-hover-cart .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-minimal-hover-cart .product-actions {
  bottom: auto;
  width: auto;
  left: auto;
  right: 0;
  line-height: 20px;
  overflow: hidden;
}

.products .product-hover-style-minimal-hover-cart .product-actions .product-actions-inner {
  transform: translateY(-90%);
  display: inline-block;
  position: relative;
  top: 0;
  width: 100%;
  vertical-align: top;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.products .product-hover-style-minimal-hover-cart .product-actions .product-actions-inner > .product-action {
  float: left;
}

.products .product-hover-style-minimal-hover-cart .product-actions .product-actions-inner > .product-action a {
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  box-shadow: none;
  width: 35px;
  height: 30px;
  line-height: 20px;
  vertical-align: top;
}

.products .product-hover-style-minimal-hover-cart .product-actions .product-actions-inner > .product-action a:hover {
  color: var(--sage);
}

.products .product-hover-style-minimal-hover-cart .svelta-product-category {
  display: none;
}

.products .product-hover-style-minimal-hover-cart:hover {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-minimal-hover-cart:hover .product-name {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.products .product-hover-style-minimal-hover-cart:hover .product-action-add-to-cart {
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-minimal-hover-cart:hover .product-action-add-to-cart a {
  top: 50%;
}

.products .product-hover-style-minimal-hover-cart:hover .product-actions {
  opacity: 1;
}

.products .product-hover-style-minimal-hover-cart:hover .product-actions .product-actions-inner {
  transform: translateY(0);
}

.products .product-hover-style-hover-summary {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-hover-summary .content-hover-block {
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: 0;
  z-index: 8;
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.products .product-hover-style-hover-summary .product-thumbnail {
  z-index: 8;
}

.products .product-hover-style-hover-summary .product-action-wishlist {
  position: absolute;
  top: 18px;
  left: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.products .product-hover-style-hover-summary .product-action-wishlist a {
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0;
  display: inline-block;
  text-align: center;
  font-size: 0;
  line-height: 32px;
  color: #323232;
}

.products .product-hover-style-hover-summary .product-action-wishlist a:before {
  content: "\f08a";
  position: relative;
  left: 0;
  top: 6px;
  font-family: FontAwesome;
  font-size: 16px;
}

.products .product-hover-style-hover-summary .product-actions {
  bottom: 0;
  background: #fff;
  padding: 8px 0;
  border-bottom: 1px solid #efefef;
  z-index: 11;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.products .product-hover-style-hover-summary .product-actions a {
  width: 50px;
  height: 40px;
  line-height: 30px;
  margin: 0 !important;
  background: 0 0;
  color: #323232;
  border-radius: 0;
  box-shadow: none;
}

.products .product-hover-style-hover-summary .product-action-add-to-cart {
  width: calc(100% - 102px);
  display: inline-block;
}

.products .product-hover-style-hover-summary .product-action-add-to-cart a {
  background: var(--sage);
  color: #fff;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  line-height: 36px;
  text-transform: uppercase;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  border-radius: 0;
  box-shadow: none;
  margin: 0 !important;
}

.products .product-hover-style-hover-summary .product-action-add-to-cart a:before {
  content: '' !important;
}

.products .product-hover-style-hover-summary .product-action-add-to-cart a:hover {
  background: #323232;
}

.products .product-hover-style-hover-summary .product-action-compare {
  float: left;
}

.products .product-hover-style-hover-summary .product-action-quick-view {
  float: right;
}

.products .product-hover-style-hover-summary .product-action-quick-view .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-hover-summary .product-info {
  text-align: center;
  padding: 0;
  padding-top: 15px;
  position: relative;
  top: 0;
  z-index: 8;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.products .product-hover-style-hover-summary .product-info .svelta-product-category,
.products .product-hover-style-hover-summary .product-info .svelta-product-category a {
  display: block;
  line-height: 18px;
}

.products .product-hover-style-hover-summary .product-info .product-name {
  padding-top: 3px;
}

.products .product-hover-style-hover-summary .product-info .product-name a {
  font-weight: 600;
}

.products .product-hover-style-hover-summary .product-info .svelta-product-description {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  -webkit-transition: all .15s ease-out 0s;
  -moz-transition: all .15s ease-out 0s;
  transition: all .15s ease-out 0s;
}

.products .product-hover-style-hover-summary .product-info .svelta-product-description.svelta-short-description .svelta-description-inner {
  max-height: 75px !important;
}

.products .product-hover-style-hover-summary .stock {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.products .product-hover-style-hover-summary .stock.out-of-stock {
  width: calc(100% - 30px);
  line-height: 28px;
  font-size: 13px;
}

.products .product-hover-style-hover-summary:hover {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-hover-summary:hover .product-inner {
  position: relative;
  z-index: 9;
}

.products .product-hover-style-hover-summary:hover .content-hover-block {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
}

.products .product-hover-style-hover-summary:hover .product-action-wishlist {
  top: 10px;
  opacity: 1;
  visibility: visible;
}

.products .product-hover-style-hover-summary:hover .product-actions {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.products .product-hover-style-hover-summary:hover .product-info {
  top: -5px;
}

.products .product-hover-style-hover-summary:hover .product-info .svelta-product-description {
  opacity: 1;
  visibility: visible;
  color: #969696;
}

.products .product-hover-style-hover-summary:hover .product-info .svelta-product-description .svelta-description-inner {
  line-height: 24px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
  max-height: inherit;
  overflow: hidden;
}

.products .product-hover-style-hover-summary:hover .product-info .svelta-product-description .cs-more-btn {
  display: block;
  color: #ccc;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 22px;
  position: relative;
  overflow: hidden;
}

.products .product-hover-style-hover-summary:hover .product-info .svelta-product-description .cs-more-btn span {
  vertical-align: top;
  position: relative;
  padding: 0 15px;
}

.products .product-hover-style-hover-summary:hover .product-info .svelta-product-description .cs-more-btn span:before,
.products .product-hover-style-hover-summary:hover .product-info .svelta-product-description .cs-more-btn span:after {
  content: "";
  background: #ddd;
  height: 1px;
  width: 300px;
  position: absolute;
  top: 10px;
  left: 100%;
}

.products .product-hover-style-hover-summary:hover .product-info .svelta-product-description .cs-more-btn span:after {
  left: auto;
  right: 100%;
}

.products .product-hover-style-info-bottom-bar {
  box-shadow: none;
  border: 1px solid #eee;
}

.products .product-hover-style-info-bottom-bar .open-quick-view {
  background: #fff !important;
  color: #323232;
  font-size: 13px;
  width: 135px;
  height: 42px;
  opacity: 0;
  line-height: 41px;
  padding: 0;
  top: 60%;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transform: translate(-50%, -70%);
  -moz-transform: translate(-50%, -70%);
  transform: translate(-50%, -70%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.products .product-hover-style-info-bottom-bar .open-quick-view:hover {
  color: var(--sage);
}

.products .product-hover-style-info-bottom-bar .open-quick-view:before {
  content: "\f06e";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  padding-right: 4px;
}

.products .product-hover-style-info-bottom-bar .product-info {
  z-index: 9;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  top: 0;
  min-height: 100px;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-info-bottom-bar .product-info .svelta-product-category {
  display: none;
}

.products .product-hover-style-info-bottom-bar .product-info .product-name {
  padding-top: 0;
}

.products .product-hover-style-info-bottom-bar .product-actions {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  bottom: 10px;
  opacity: 0;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-info-bottom-bar .product-actions .product-action-add-to-cart {
  width: calc(100% - 90px);
  background: #fff;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  display: inline-flex;
}

.products .product-hover-style-info-bottom-bar .product-actions .product-action-add-to-cart a {
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  background: var(--sage);
  color: #fff;
  line-height: 40px;
  text-transform: uppercase;
}

.products .product-hover-style-info-bottom-bar .product-actions .product-action-add-to-cart a:before {
  content: "\f1bf";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
  top: 0;
  padding-right: 10px;
  position: relative;
  left: 0;
}

.products .product-hover-style-info-bottom-bar .product-actions a {
  margin: 0 !important;
  border-radius: 0;
  box-shadow: none;
  background: #fff;
  color: #323232;
}

.products .product-hover-style-info-bottom-bar .product-actions a:hover {
  color: #fff;
  background: var(--sage);
}

.products .product-hover-style-info-bottom-bar .product-actions .product-action-wishlist {
  float: left;
}

.products .product-hover-style-info-bottom-bar .product-actions .product-action-compare {
  float: right;
}

.products .product-hover-style-info-bottom-bar .stock {
  left: 15px;
  top: 15px;
  bottom: auto;
}

.products .product-hover-style-info-bottom-bar:hover {
  -webkit-box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
}

.products .product-hover-style-info-bottom-bar:hover .product-info {
  top: -40px;
}

.products .product-hover-style-info-bottom-bar:hover .open-quick-view {
  opacity: 1;
  top: 50%;
}

.products .product-hover-style-info-bottom-bar:hover .product-actions {
  bottom: -1px;
  opacity: 1;
}

.products .product-hover-style-info-bottom {
  box-shadow: none;
  border: 1px solid #eee;
  padding-bottom: 65px;
  position: relative;
}

.products .product-hover-style-info-bottom .open-quick-view {
  background: #fff !important;
  color: #323232;
  font-size: 13px;
  width: 135px;
  height: 42px;
  opacity: 0;
  line-height: 41px;
  padding: 0;
  top: 60%;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transform: translate(-50%, -70%);
  -moz-transform: translate(-50%, -70%);
  transform: translate(-50%, -70%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.products .product-hover-style-info-bottom .open-quick-view:hover {
  color: var(--sage);
}

.products .product-hover-style-info-bottom .open-quick-view:before {
  content: "\f06e";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  padding-right: 4px;
}

.products .product-hover-style-info-bottom .product-info {
  padding: 20px 20px;
  padding-bottom: 0;
  position: relative;
}

.products .product-hover-style-info-bottom .product-info .svelta-product-category {
  display: none;
}

.products .product-hover-style-info-bottom .product-info .product-name {
  padding-top: 0;
}

.products .product-hover-style-info-bottom .product-actions {
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
  margin-top: 0;
}

.products .product-hover-style-info-bottom .product-actions .product-action {
  display: inline-block;
  vertical-align: top;
}

.products .product-hover-style-info-bottom .product-actions .product-action-add-to-cart {
  width: calc(100% - 115px);
}

.products .product-hover-style-info-bottom .product-actions .product-action-add-to-cart a {
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  background: var(--sage);
  color: #fff;
  line-height: 36px;
  text-transform: uppercase;
}

.products .product-hover-style-info-bottom .product-actions .product-action-add-to-cart a:hover {
  color: #fff;
}

.products .product-hover-style-info-bottom .product-actions .product-action-add-to-cart a:before {
  content: "\f1bf";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
  top: 0;
  padding-right: 10px;
  position: relative;
  left: 0;
}

.products .product-hover-style-info-bottom .product-actions a {
  width: 55px;
  height: 40px;
  margin: 0 !important;
  border-radius: 0;
  box-shadow: none;
  background: 0 0;
  color: #323232;
}

.products .product-hover-style-info-bottom .product-actions a:hover {
  color: var(--sage);
}

.products .product-hover-style-info-bottom .product-actions .product-action-wishlist {
  float: left;
}

.products .product-hover-style-info-bottom .product-actions .product-action-compare {
  float: right;
}

.products .product-hover-style-info-bottom .stock {
  left: 15px;
  top: 15px;
  bottom: auto;
}

.products .product-hover-style-info-bottom:hover {
  -webkit-box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 22px rgba(0, 0, 0, 0.07);
}

.products .product-hover-style-info-bottom:hover .open-quick-view {
  opacity: 1;
  top: 50%;
}

.products .product-hover-style-image-bottom-bar {
  box-shadow: none;
  border: 0;
}

.products .product-hover-style-image-bottom-bar .open-quick-view {
  background: #fff !important;
  color: #323232;
  font-size: 13px;
  width: 135px;
  height: 42px;
  opacity: 0;
  line-height: 41px;
  padding: 0;
  top: 60%;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transform: translate(-50%, -70%);
  -moz-transform: translate(-50%, -70%);
  transform: translate(-50%, -70%);
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.products .product-hover-style-image-bottom-bar .open-quick-view:hover {
  color: var(--sage);
}

.products .product-hover-style-image-bottom-bar .open-quick-view:before {
  content: "\f06e";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  padding-right: 4px;
}

.products .product-hover-style-image-bottom-bar .product-info {
  z-index: 9;
  background: #fff;
  padding: 0;
  padding-top: 15px;
  position: relative;
  top: 0;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-image-bottom-bar .product-info .svelta-product-category {
  display: none;
}

.products .product-hover-style-image-bottom-bar .product-info .product-name {
  padding-top: 0;
}

.products .product-hover-style-image-bottom-bar .product-actions {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  bottom: -46px;
  opacity: 0;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-image-bottom-bar .product-actions .product-action-add-to-cart {
  width: calc(100% - 90px);
  background: #fff;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  display: inline-flex;
}

.products .product-hover-style-image-bottom-bar .product-actions .product-action-add-to-cart a {
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  background: var(--sage);
  color: #fff;
  line-height: 40px;
  text-transform: uppercase;
}

.products .product-hover-style-image-bottom-bar .product-actions .product-action-add-to-cart a:before {
  content: "\f1bf";
  font-family: pgsicon-ecommerce;
  font-size: 18px;
  top: 0;
  padding-right: 10px;
  position: relative;
  left: 0;
}

.products .product-hover-style-image-bottom-bar .product-actions a {
  margin: 0 !important;
  border-radius: 0;
  box-shadow: none;
  background: #fff;
  color: #323232;
}

.products .product-hover-style-image-bottom-bar .product-actions a:hover {
  color: #fff;
  background: var(--sage);
}

.products .product-hover-style-image-bottom-bar .product-actions .product-action-wishlist {
  float: left;
}

.products .product-hover-style-image-bottom-bar .product-actions .product-action-compare {
  float: right;
}

.products .product-hover-style-image-bottom-bar .stock {
  left: 15px;
  top: 15px;
  bottom: auto;
}

.products .product-hover-style-image-bottom-bar:hover {
  border: 0;
  box-shadow: none;
}

.products .product-hover-style-image-bottom-bar:hover .open-quick-view {
  opacity: 1;
  top: 50%;
}

.products .product-hover-style-image-bottom-bar:hover .product-actions {
  bottom: 0px;
  opacity: 1;
}

.products .product-hover-style-image-bottom-2 {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-image-bottom-2 .product-actions {
  bottom: -5px;
  opacity: 0;
}

.products .product-hover-style-image-bottom-2 .product-actions .product-actions-inner {
  background: #fff;
  display: inline-block;
  padding: 0 8px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  padding: 0 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.products .product-hover-style-image-bottom-2 .product-actions .product-action {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.products .product-hover-style-image-bottom-2 .product-actions .product-action:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #e5e5e5;
  width: 1px;
  height: 20px;
}

.products .product-hover-style-image-bottom-2 .product-actions .product-action:last-child:before {
  width: 0;
}

.products .product-hover-style-image-bottom-2 .product-actions a {
  width: 45px;
  height: 55px;
  line-height: 40px;
  margin: 0 !important;
  background: 0 0;
  box-shadow: none;
}

.products .product-hover-style-image-bottom-2 .product-actions a:hover {
  color: #fff;
}

.products .product-hover-style-image-bottom-2 .open-quick-view {
  min-width: auto;
  left: inherit;
  top: inherit;
  transform: inherit;
}

.products .product-hover-style-image-bottom-2 .stock {
  bottom: auto;
  top: 15px;
}

.products .product-hover-style-image-bottom-2 .product-info .price ins .amount {
  color: var(--sage);
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-dark .product-actions .product-actions-inner {
  background: #323232;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-dark .product-actions .product-action:before {
  background: #545454;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-dark .product-actions .product-action.product-action-add-to-cart a:before {
  content: '' !important;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-dark .product-actions a {
  color: #a7a5a6;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-dark .product-actions a:hover {
  color: #fff;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-dark.product-hover-button-shape-round .product-actions .product-action.product-action-add-to-cart a:before {
  content: '\f1bf' !important;
  font-family: pgsicon-ecommerce;
  font-size: 18px;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-light .product-actions .product-actions-inner {
  border-radius: 2px;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-light .product-actions .product-action:before {
  background: #e5e5e5;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-light .product-actions a {
  color: #323232;
}

.products .product-hover-style-image-bottom-2.product-hover-button-style-light .product-actions a:hover {
  color: var(--sage);
}

.products .product-hover-style-image-bottom-2:hover {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-image-bottom-2:hover .product-actions {
  opacity: 1;
  bottom: 15px;
}

.products .product-hover-style-image-bottom {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-image-bottom .product-actions {
  bottom: -10px;
  opacity: 0;
  text-align: center;
}

.products .product-hover-style-image-bottom .product-actions .product-actions-inner {
  display: inline-block;
  vertical-align: top;
}

.products .product-hover-style-image-bottom .product-actions .product-actions-inner > .product-action {
  float: left;
}

.products .product-hover-style-image-bottom .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-image-bottom:hover {
  border: none;
  box-shadow: none;
  background: 0 0;
}

.products .product-hover-style-image-bottom:hover .product-actions {
  opacity: 1;
  bottom: 15px;
}

.products .product-hover-style-image-bottom .stock {
  bottom: auto;
  top: 15px;
}

.products .product-hover-style-icons-rounded {
  border: 0;
  box-shadow: none;
}

.products .product-hover-style-icons-rounded .product-action-add-to-cart {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
}

.products .product-hover-style-icons-rounded .product-action-add-to-cart a {
  width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 2px 0;
  text-align: center;
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
}

.products .product-hover-style-icons-rounded .product-action-add-to-cart a:hover {
  background: #323232;
}

.products .product-hover-style-icons-rounded .product-actions {
  position: absolute;
  top: 15px;
  bottom: auto;
  left: -5px;
  width: auto;
  opacity: 0;
  visibility: hidden;
}

.products .product-hover-style-icons-rounded .product-actions .product-actions-inner > .product-action {
  margin-bottom: 6px;
}

.products .product-hover-style-icons-rounded .product-actions a {
  margin: 0 !important;
  background: #fff;
  color: #323232;
  border-radius: 70px;
}

.products .product-hover-style-icons-rounded .product-actions a:hover {
  color: var(--sage);
}

.products .product-hover-style-icons-rounded .open-quick-view {
  min-width: inherit;
  top: inherit;
  left: inherit;
  transform: inherit;
}

.products .product-hover-style-icons-rounded .stock {
  left: 15px;
  top: 15px;
  bottom: auto;
  -webkit-transition: all .2s ease-out 0s;
  -moz-transition: all .2s ease-out 0s;
  transition: all .2s ease-out 0s;
  opacity: 1;
}

.products .product-hover-style-icons-rounded .stock.out-of-stock {
  bottom: 15px;
  top: auto;
}

.products .product-hover-style-icons-rounded:hover {
  border: 0;
  box-shadow: none;
}

.products .product-hover-style-icons-rounded:hover .product-action-add-to-cart {
  bottom: 0;
}

.products .product-hover-style-icons-rounded:hover .product-actions {
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.products.list li.product {
  width: 100%;
  margin-right: 0;
  padding: 0;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
}

.products.list .product.product-hover-style-hover-summary:hover {
  border-bottom: 1px solid #e8e8e8;
}

.products.list li.product:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.products.list li.product:hover {
  box-shadow: none;
}

.products.list .product .product-inner {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.products.list .product-thumbnail {
  position: relative;
  -ms-flex: 0 0 280px;
  flex: 0 0 280px;
}

.products.list .product-thumbnail img {
  width: 100% !important;
  margin-right: 0 !important;
}

.box-image-resize {
  background-size: cover;
  background-position: center center;
  height: 142px;
  width: 100%;
}

.box-image-resize-mobile {
  background-size: cover;
  background-position: center center;
  height: 102px;
  width: 100%;
}

.products.list .product-info {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: left;
  position: relative;
  top: 0;
  padding: 0px;
  margin-left: 30px;
}

.products.list .product-info .gridlist-buttonwrap {
  display: none;
}

.products.list .product-hover-style-image-center .product-info .product-actions,
.products.list .product-hover-style-image-left .product-info .product-actions,
.products.list .product-hover-style-image-bottom .product-info .product-actions,
.products.list .product .product-info .product-actions {
  display: block;
  opacity: 1;
  position: relative;
  left: 0;
  top: 0;
  transform: inherit;
  text-align: left;
  width: inherit;
  visibility: visible;
}

.products.list li.product-hover-style-info-bottom .product-name,
.products.list li.product-hover-style-info-bottom-bar .product-name {
  padding: 0.5em 0;
}

.products.list li.product-hover-style-info-bottom .product-info .product-actions a {
  width: 45px;
}

.products.list .product-hover-style-info-bottom .product-thumbnail .product-actions,
.products.list .product-hover-style-info-bottom-bar .product-thumbnail .product-actions {
  display: none;
}

.products.list .product-hover-style-icon-top-left .product-actions-inner {
  box-shadow: none;
}

.products.list li.product.product-hover-style-icon-top-left .product-info .product-actions .product-action {
  float: left;
}

.products.list li.product.product-hover-style-icon-top-left .product-info .product-actions a {
  line-height: 34px;
}

.products.list .star-rating-wrapper {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 7px;
}

.products.list .star-rating-wrapper:after {
  width: 80px;
  left: 0;
  top: 3px;
  z-index: -1;
  background: #ffffff;
  margin-left: 0;
}

.products.list .star-rating-wrapper .star-rating {
  margin: 0;
}

.products.list li.product .svelta-product-category {
  padding-bottom: 7px;
}

.products.list li.product .product-name {
  padding: 0;
  padding-bottom: 5px;
}

.products.list li.product .price {
  float: none;
  width: 100%;
  text-align: left;
  margin: 0;
}

.products.list li.product.product-hover-style-icons-rounded .price,
.products.list li.product-hover-style-icons-bottom-right .price,
.products.list li.product-hover-style-image-bottom .price {
  margin-bottom: 5px;
}

.products.list li.product.product-hover-style-image-left .woocommerce-product-details__short-description,
.products.list li.product.product-hover-style-button-standard .woocommerce-product-details__short-description,
.products.list li.product.product-hover-style-icons-left .woocommerce-product-details__short-description,
.products.list li.product-hover-style-icons-rounded .woocommerce-product-details__short-description,
.products.list li.product-hover-style-image-bottom .woocommerce-product-details__short-description {
  margin-top: 10px;
  margin-bottom: 0;
}

.products.list li.product .price del .amount {
  font-size: 18px;
  margin-right: 5px;
  color: #969696;
}

.products.list li.product .price .amount {
  font-size: 18px;
  color: var(--sage);
}

.products.list li.product img {
  margin-bottom: 0;
}

ul.products .woocommerce-product-details__short-description {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
  max-height: inherit;
  overflow: hidden;
}

.products.list .woocommerce-product-details__short-description {
  margin-bottom: 0;
}

ul.products .woocommerce-product-details__short-description p {
  margin: 0;
}

.products.list li.product .woocommerce-product-details__short-description strong {
  display: inline-block;
}

.products.list .product-thumbnail .stock,
ul.products .product-inner .product-info .stock {
  display: none;
}

.products.list .product-info .stock {
  display: inline-block;
  position: absolute;
  left: -120px;
  bottom: auto;
  top: 28px;
  margin-top: -5px;
  margin-bottom: 15px;
  transform: inherit;
}

.products.list .product-hover-style-standard-quick-shop .product-info .svelta-product-variations-wrapper {
  text-align: left;
}

.products.list .product-hover-style-standard-quick-shop .product-info .stock {
  position: relative;
  left: 0;
  margin: 0;
  top: 0;
  min-width: inherit;
  padding: 3px 15px;
}

.products.list li.product .product-info .product-actions a {
  background: none;
  color: var(--sage);
  border: 1px solid #eeeeee;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  vertical-align: top;
  line-height: 30px;
  margin: 0 !important;
  margin-right: 5px !important;
  box-shadow: none;
}

.products.list li.product .product-info .product-actions .product-action-add-to-cart a {
  background: var(--sage);
  color: #ffffff;
  width: auto;
  font-size: 14px;
  line-height: 45px;
  padding: 0 15px;
  border: none;
}

.products.list li.product .product-info .product-actions .product-action-add-to-cart a:hover {
  background: #323232;
}

.products.list li.product .product-info .product-actions .product-action {
  float: none;
}

.products.list li.product .product-info .product-actions,
.products.list li.product .product-info .product-actions .product-action-add-to-cart {
  width: auto;
  border: none;
}

.products.list li.product .product-info .product-actions .product-action-add-to-cart a:before {
  top: 0;
  padding-right: 7px;
}

.products.list .countdown {
  margin: 15px 0px !important;
}

/* Quick View List Style */
.products.list .product-hover-style-icon-top-left .open-quick-view {
  transform: inherit;
  left: 0;
}

.products.list .product-hover-style-default .open-quick-view {
  top: 0 !important;
  left: 0 !important;
  transform: inherit;
}

.svelta-pagination {
  text-align: center;
  justify-content: center;
}

.svelta-pagination ul {
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  clear: both;
  border: 1px solid #d3ced2;
  border-right: 0;
  margin: 1px;
  border: none;
}

.svelta-pagination ul li {
  line-height: inherit;
  list-style: none;
  overflow: hidden;
  float: left;
  padding: 0;
  border: none;
  margin: 0 4px;
  display: inline-block;
}

.svelta-pagination ul li span,
.svelta-pagination ul li a {
  margin: 0;
  text-decoration: none;
  padding: 0;
  line-height: 1;
  min-width: 1em;
  display: block;
  font-size: 15px;
  color: #323232;
  font-weight: 600;
  border: 1px solid #ececec;
  background: #f5f5f5;
  padding: 13px 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
}

.svelta-pagination ul li span.current,
.svelta-pagination ul li a.current {
  background: var(--sage);
  border-color: var(--sage);
  color: #fff;
}

.svelta-pagination ul li a:hover {
  border-color: var(--sage);
  background: var(--sage);
  color: #fff;
}

.inner-intro.inner-intro-alt {
  height: auto !important;
  background: #fbfbfb;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
}

.inner-intro.inner-intro-alt .page-breadcrumb {
  margin-top: 0;
}

.inner-intro.inner-intro-alt .page-breadcrumb li {
  color: #323232;
}

.product-hover-button-style-dark .product-actions .product-action.product-action-add-to-cart a:before {
  content: '' !important;
}

.wishlist-not-found {
  text-align: center;
}

.wishlist-not-found .btn.btn-solid {
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.wishlist-not-found .btn.btn-solid:hover {
  background: var(--sage);
  color: #ffffff;
}

.wishlist-no-found img {
  height: 80px;
}

.svelta-product-gallery {
  position: relative;
}

.svelta-product-gallery .svelta-product-gallery_buttons_wrapper {
  position: absolute;
  right: 0;
  bottom: 7px;
  background: #fff;
  padding: 5px;
  -webkit-border-radius: 5px 0 0;
  -moz-border-radius: 5px 0 0;
  border-radius: 5px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.svelta-product-gallery .svelta-product-gallery_buttons_wrapper .svelta-product-gallery_button {
  display: table-cell;
  padding: 3px;
  vertical-align: top;
}

.svelta-product-gallery .svelta-product-gallery_buttons_wrapper .svelta-product-gallery_button a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #fff;
  border: 1px solid #ddd;
  color: #323232;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  vertical-align: top;
}

.svelta-product-gallery .svelta-product-gallery_buttons_wrapper .svelta-product-gallery_button a:hover {
  background: #323232;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
}

.svelta-gallery-style-default .svelta-product-thumbnails {
  margin-top: 10px;
}

.svelta-gallery-style-default .svelta-product-thumbnails .slick-slide {
  margin: 0;
  margin-right: 5px;
  cursor: pointer;
}

.svelta-gallery-style-default .svelta-product-thumbnails .slick-slider .slick-arrow {
  background: #fff;
  width: 25px;
  height: 25px;
  z-index: 10;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  font-size: 0;
  line-height: 35px;
  border-radius: 3px;
}

.svelta-gallery-style-default .svelta-product-thumbnails .slick-slider .slick-arrow:before {
  font-family: FontAwesome;
  color: var(--sage);
  font-size: 12px;
  opacity: 1;
  line-height: 22px;
}

.svelta-gallery-style-default .svelta-product-thumbnails .slick-slider .slick-arrow.slick-prev:before {
  content: "\f053";
}

.svelta-gallery-style-default .svelta-product-thumbnails .slick-slider .slick-arrow.slick-next:before {
  content: "\f054";
}

.svelta-gallery-style-default .svelta-product-thumbnails .slick-slider:hover .slick-prev {
  left: 5px;
  opacity: 1;
  background: #fff;
}

.svelta-gallery-style-default .svelta-product-thumbnails .slick-slider:hover .slick-next {
  right: 5px;
  opacity: 1;
  background: #fff;
}

.single-product .product_title {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.single-product .edit-icon {
  position: relative;
}

.single-product .edit-icon:before {
  position: absolute;
  left: 0;
  top: 0;
}

.single-product .product-rating {
  margin-bottom: 20px;
}

.single-product .product-rating .star-rating {
  float: left;
  line-height: 20px;
  margin-right: 10px;
}

.single-product .product-rating .star-rating .fa-star {
  color: #ffbe00;
}

.single-product .product-rating .review-link {
  color: #969696;
  font-size: 13px;
  line-height: 18px;
}

.single-product .price {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    font-weight: bold;
    color: #4d4d4d;
}

.single-product .product-details__short-description {
  padding-bottom: 20px;
  max-height: inherit;
}

.single-product .product-size-guide {
  margin-bottom: 20px;
}

.single-product .product-size-guide .open-product-size-guide {
  background: #fff;
  border: 1px solid #e8e8e8;
  color: #969696;
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 15px;
  padding-left: 12px;
  display: inline-block;
  position: relative;
  border-radius: 3px;
}

.single-product .product-size-guide .open-product-size-guide:before {
  content: "";
  background: url("../images/shop/size-icon.png");
  width: 23px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 7px;
}

.single-product .product-size-guide .open-product-size-guide:hover {
  background: #fbfbfb;
}

.single-product .cart {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.single-product .cart .quantity {
  position: relative;
  padding: 0 24px;
  display: inline-block;
  float: left;
  margin: 0 10px 0 0;
}

.single-product .cart .quantity .qty {
  text-align: center;
}

.single-product .cart .quantity input {
  width: 50px !important;
  height: 45px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0 10px;
  margin: 0;
  font-size: 16px;
  color: #969696;
  border: 1px solid #e6e6e6;
  height: 45px;
  background: #f8f8f8;
}

.single-product .cart .quantity .quantity-button {
  position: relative;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e6e6e6;
  width: 20px;
  text-align: center;
  color: #969696;
  font-size: 20px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 45px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  line-height: 45px;
  background: #fff;
}

.single-product .cart .quantity .quantity-button.quantity-up {
  position: absolute;
  width: 26px;
  height: 100%;
  right: 0;
  top: 0;
}

.single-product .cart .quantity .quantity-button.quantity-down {
  position: absolute;
  width: 26px;
  height: 100%;
  left: 0;
  top: 0;
}

.single-product .cart .button {
  vertical-align: middle;
  float: left;
  padding: 10px 20px;
  position: relative;
  border: none;
  font-size: 14px;
  background: var(--sage);
  text-align: center;
  border: none;
  color: #fff;
  line-height: 26px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
}


.single-product .cart .button:hover {
  background: #323232;
}

.single-product .product-summary-actions {
  margin-bottom: 25px;
  border-bottom: 1px solid #d3ced2;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.single-product .product-summary-actions .add-to-wishlist {
  float: left;
  margin: 0;
  margin-right: 20px;
  margin-left: 12px;
  position: relative;
}

.single-product .product-summary-actions .add-to-wishlist a {
  color: #323232;
  line-height: 30px;
  font-size: 15px;
}

.single-product .product-summary-actions .add-to-wishlist a:before {
  content: "\f08a";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  font-size: 16px;
  padding-right: 7px;
  color: var(--sage);
}

.single-product .product-summary-actions .add-to-wishlist-fill a:before {
  content: "\f004";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  font-size: 16px;
  padding-right: 7px;
  color: var(--sage);
}

.single-product .product-summary-actions .add-to-wishlist a:hover {
  color: var(--sage);
}

.single-product .product-summary-actions .compare {
  background: 0 0;
  font-size: 15px;
  color: #323232;
  padding: 0;
  line-height: 30px;
  text-transform: capitalize;
  margin-right: 20px;
}

.single-product .product-summary-actions .compare:before {
  content: "\f066";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  font-size: 16px;
  padding-right: 7px;
  color: var(--sage);
}

.single-product .product-summary-actions .compare:hover {
  color: var(--sage);
}

.single-product .product_meta {
  margin-bottom: 25px;
}

.single-product .product_meta > span {
  display: block;
  font-size: 15px;
  margin: 8px 0;
  color: #969696;
}

.single-product .product_meta > span label {
  padding-right: 5px;
  margin-bottom: 0;
  color: #323232;
  font-weight: 600;
}

.single-product .product_meta > span a {
  color: #969696;
}

.single-product .product_meta > span a:hover {
  color: var(--sage);
}

.single-product .social-profiles {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #d3ced2;
  border-bottom: 1px solid #d3ced2;
  position: relative;
  padding-left: 60px;
}

.single-product .social-profiles .share-label {
  float: left;
  margin-top: 3px;
  color: #323232;
  font-weight: 600;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 10px;
}

.single-product .social-profiles ul {
  padding: 0;
  margin: 0;
}

.single-product .social-profiles ul li {
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 0;
  float: left;
}

.single-product .social-profiles ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 0;
  text-align: center;
  line-height: 30px;
  background: 0 0;
  border-radius: 3px;
  color: var(--sage);
}

.single-product .social-profiles ul li a:hover {
  background: var(--sage);
  color: #fff;
}

.single-product .sidebar > .widget {
  border: 1px solid #ececec;
  padding: 18px;
  margin-bottom: 20px;
}

.single-product .sidebar .pgs_brand_filters .block-content {
  text-align: center;
}

.single-product .sidebar .pgs_brand_filters .block-content h5 {
  margin-bottom: 20px;
}

.single-product .sidebar .pgs_brand_filters .brand-products {
  padding: 6px 20px;
  background: #323232;
  color: #fff;
  font-size: 13px;
  display: block;
  margin-bottom: 8px;
}

.single-product .sidebar .pgs_brand_filters .brand-products:hover {
  background: var(--sage);
}

.single-product .sidebar .pgs_qr_code {
  position: relative;
  border: 2px dashed #ddd;
}

.single-product .sidebar .pgs_qr_code .pgs-qrcode-style-popup {
  padding: 15px 15px 0 15px;
}

.single-product .sidebar .pgs_qr_code .pgs-qrcode-style-popup .pgs-qrcode-popup-link {
  text-decoration: none;
  background-image: url("../images/shop/qr_code-bg.png");
  background-position: right center;
  background-size: auto;
  display: block;
  background-repeat: no-repeat;
  height: auto;
  min-height: 72px;
}

.single-product .sidebar .pgs_qr_code .pgs-qrcode-style-popup .pgs-qrcode-popup-link .pgs-qrcode-popup-link-inner {
  width: calc(100% - 75px);
  text-align: left;
}

.single-product .sidebar .pgs_qr_code .pgs-qrcode-style-popup .pgs-qrcode-popup-link .pgs-qrcode-label {
  font-size: 16px;
  font-weight: 600;
}

.single-product .sidebar .pgs_qr_code .pgs-qrcode-style-popup .pgs-qrcode-popup-link .pgs-qrcode-desc {
  color: #969696;
  font-size: 13px;
  line-height: 19px;
}

.single-product .sidebar .pgs_qr_code .pgs-qrcode-style-popup .pgs-qrcode-popup-link .pgs-qrcode-desc p {
  margin-bottom: 0;
}

.single-product .sidebar .widget_media_image {
  background: 0 0;
  border: none;
  padding: 0;
}

.product_list_widget {
  list-style: none outside;
  padding: 0;
  margin: 0;
}

.product_list_widget li {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.product_list_widget li a {
  line-height: 18px !important;
  font-weight: 500;
  color: #323232 !important;
  font-size: 13px;
  display: block !important;
}

.product_list_widget li a:hover {
  color: var(--sage) !important;
}

.product_list_widget li img {
  height: auto;
  box-shadow: none;
  float: left;
  margin-right: 15px;
  margin-left: 0;
  width: 60px;
}

.product_list_widget li .price_code {
  color: var(--sage);
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}

.request-box .form-control {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.05);
  vertical-align: top;
}

.request-box .form-control:focus {
  border-color: var(--sage);
}

.request-box .btn {
  display: block;
  width: 100%;
  background: #323232;
  box-shadow: none;
}

.request-box .btn:hover {
  background: var(--sage);
}

.widget_product_tag_cloud .tagcloud a {
  background: 0 0;
  color: #969696;
  margin-bottom: 8px;
  margin-right: 4px;
  display: inline-block;
  border: 1px solid #f2f2f2;
  line-height: 28px;
  padding: 0 12px;
  font-size: 12px !important;
  text-transform: uppercase;
  border-radius: 2px;
}

.widget_product_tag_cloud .tagcloud a:hover {
  color: #fff;
  border-color: var(--sage);
  background: var(--sage);
}

.svelta-sticky-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  background: #fff;
  padding: 25px 0;
  -webkit-box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.1);
  -webkit-transition: all .25s ease-out 0s;
  -moz-transition: all .25s ease-out 0s;
  transition: all .25s ease-out 0s;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
}

.svelta-sticky-btn.sticky {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.svelta-sticky-btn .svelta-sticky-btn-content {
  float: left;
  display: table;
}

.svelta-sticky-btn .svelta-sticky-btn-content .svelta-sticky-btn-thumbnail {
  display: table-cell;
}

.svelta-sticky-btn .svelta-sticky-btn-content .svelta-sticky-btn-thumbnail img {
  width: 45px;
}

.svelta-sticky-btn .svelta-sticky-btn-content .svelta-sticky-btn-info {
  display: table-cell;
  padding-left: 15px;
  vertical-align: middle;
}

.svelta-sticky-btn .svelta-sticky-btn-content .product-title {
  margin-bottom: 0;
  display: block;
  width: 400px;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.svelta-sticky-btn .svelta-sticky-btn-content .star-rating {
  float: none;
  margin: 0;
  margin-top: 5px;
}

.svelta-sticky-btn .svelta-sticky-btn-content .star-rating .fa-star {
  color: #ffbe00;
}

.svelta-sticky-btn .svelta-sticky-btn-cart {
  float: right;
  display: table;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .wishlist-compare-button {
  width: 75px;
  margin-top: 7px;
  margin-right: 10px;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .wishlist-compare-button > div {
  display: inline-block;
  vertical-align: top;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .wishlist-compare-button .product-action-wishlist a {
  display: inline-block;
  background: 0 0;
  font-size: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .wishlist-compare-button .product-action-wishlist a:before {
  content: "\f08a";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  font-size: 16px;
  color: #323232;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .wishlist-compare-button .product-action-compare a {
  display: inline-block;
  background: 0 0;
  font-size: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .wishlist-compare-button .product-action-compare a:before {
  content: "\f066";
  position: relative;
  left: 0;
  top: 0;
  font-family: FontAwesome;
  font-size: 16px;
  color: #323232;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .price {
  font-size: 18px;
  line-height: 26px;
  padding-right: 10px;
  display: table-cell;
  vertical-align: middle;
}

.svelta-sticky-btn .svelta-sticky-btn-cart .cart {
  margin: 0;
  padding: 0;
  display: table-cell;
  vertical-align: top;
}

.product-nav-btn {
  position: fixed;
  bottom: 125px;
  right: 35px;
  z-index: 9;
  box-shadow: none;
}

.product-nav-btn .product-nav-arrow {
  position: absolute;
  left: 14px;
  top: 18px;
  opacity: 0;
  color: #969696;
}

.product-nav-btn .product-nav-arrow:before {
  content: attr(data-title);
  color: #fff;
}

.product-nav-btn .product-nav-arrow i {
  display: none;
}

.product-nav-btn .product-nav-image {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background: #fff;
}

.product-nav-btn .product-nav-image img {
  opacity: 1;
  -webkit-transition: all .5s ease-out 0s;
  -moz-transition: all .5s ease-out 0s;
  transition: all .5s ease-out 0s;
}

.product-nav-btn .product-nav-content {
  position: absolute;
  top: 8px;
  width: 180px;
  text-align: right;
  opacity: 0;
  -webkit-transition: all .5s ease-out 0s;
  -moz-transition: all .5s ease-out 0s;
  transition: all .5s ease-out 0s;
  visibility: hidden;
}

.product-nav-btn .product-nav-content .product_nav_title {
  font-size: 14px;
  color: #323232;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-nav-btn .product-nav-content .product-nav-content-rating {
  display: none;
}

.product-nav-btn .product-nav-content .product-nav-content-price del {
  color: #323232;
}

.product-nav-btn .product-nav-content .product-nav-content-price ins {
  background: 0 0;
  color: var(--sage);
}

.product-nav-btn.product-nav-btn-next .product-nav-content {
  right: 95px;
}

.product-nav-btn:hover .product-nav-image {
  background: #323232;
}

.product-nav-btn:hover .product-nav-image img {
  opacity: 0;
}

.product-nav-btn:hover .product-nav-arrow {
  opacity: 1;
  background: 0 0 !important;
}

.product-nav-btn:hover .product-nav-content {
  right: 75px;
  opacity: 1;
  visibility: visible;
}

.product-content-bottom {
  margin-top: 40px;
}

.product-content-bottom .nav-tabs {
  list-style: none;
  padding: 0 0 0 1em;
  margin: 0 0 1.618em;
  position: relative;
}

.product-content-bottom .nav-tabs li {
  margin-right: 5px;
}

.product-content-bottom .nav-tabs li a {
  position: relative;
  cursor: pointer;
  border: 1px solid #d3ced2;
  padding: 0;
  border-radius: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: #323232;
  padding: 12px 22px;
}

.product-content-bottom .nav-tabs li a:before {
  content: "";
  width: 20%;
  height: 3px;
  background: var(--sage);
  top: -1px;
  left: 30%;
  box-shadow: none;
  border: none;
  border-radius: 0;
  -webkit-transition: all .4s ease-out 0s;
  -moz-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
  opacity: 0;
  position: absolute;
  width: 5px;
  height: 5px;
}

.product-content-bottom .nav-tabs li a:hover {
  border: 1px solid #d3ced2;
}

.product-content-bottom .nav-tabs li a:hover:before {
  width: 100%;
  left: 0;
  opacity: 1;
  box-shadow: none;
  background: var(--sage);
}

.product-content-bottom .nav-tabs li a.active {
  background: #fff;
  z-index: 2;
  border-bottom-color: #fff;
  color: var(--sage);
}

.product-content-bottom .nav-tabs li a.active:before {
  width: 100%;
  left: 0;
  opacity: 1;
  box-shadow: none;
  background: var(--sage);
}

.product-content-bottom .tab-content {
  margin-bottom: 35px;
}

.product-content-bottom .tab-content .tab-pane h2 {
  font-size: 18px;
  font-weight: 500;
}

.svelta_info_box_2-icon_position-left {
  padding: 30px;
}

.svelta_info_box_2-icon_position-left .svelta_info_box_2-icon {
  padding-right: 25px;
  font-size: 44px;
  line-height: 44px;
  color: var(--sage);
}

.products.related h2 {
  font-size: 26px;
  margin-bottom: 20px;
  font-weight: 600;
}

.contact-wrapper {
  padding: 60px 50px;
}

.contact-wrapper .form-group {
  width: 100%;
}

.contact-wrapper .btn.btn-default {
  width: auto;
  background: var(--sage);
  border-color: var(--sage);
  color: #ffffff;
}

.contact-wrapper .btn.btn-default:hover {
  background: #323232;
  border-color: #323232;
}

.form-group .error {
  color: #dc3545;
}

.error-block {
  margin-bottom: 50px;
}

.error-block h1 {
  font-size: 200px;
  line-height: 260px;
  margin-bottom: 0;
  color: var(--sage);
  font-weight: 600;
}

.error-block p {
  font-size: 30px;
  font-weight: 600;
  color: #323232;
}

.error-search-box {
  width: 100%;
  margin: 0;
}

.error-search-box p {
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 30px;
}

.error-search-box #searchform {
  position: relative;
}

.error-search-box #searchform .screen-reader-text {
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 0;
  width: 1px;
  overflow: hidden;
}

.error-search-box #searchform input {
  padding-right: 85px;
}

.error-search-box #searchform .search-button {
  margin: 0;
  min-width: 70px;
  font-size: 15px;
  display: inline-block;
  padding: 11px 20px;
  border: none;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  height: 46px;
  width: 46px;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  line-height: normal;
  background: var(--sage);
}

.content-404-image {
  margin-bottom: -60px;
}

.maintenance {
  min-height: 100vh;
  position: relative;
  padding-bottom: 64px;
}

.maintenance .header {
  background: 0 0;
  text-align: center;
}

.maintenance .header img {
  margin: 20px 0;
}

.maintenance .mntc-cs-item.mntc-cs-content {
  padding: 80px 0 20px;
}

.maintenance .mntc-cs-item.mntc-cs-content i {
  font-size: 100px;
  margin-bottom: 25px;
  color: #e4e4e4;
}

.maintenance .mntc-cs-item.mntc-cs-content h1 {
  font-size: 62px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 50px;
}

.maintenance .mntc-cs-item.mntc-cs-content p {
  font-size: 32px;
  padding: 0 250px;
  line-height: 44px;
}

.maintenance footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  box-shadow: none;
}

.coming-soon {
  min-height: 100vh;
  position: relative;
  padding-bottom: 64px;
}

.coming-soon .header {
  background: 0 0;
  text-align: center;
}

.coming-soon .header img {
  margin: 20px 0;
}

.coming-soon .mntc-cs-item.mntc-cs-content {
  padding: 80px 0 20px;
  text-align: center;
}

.coming-soon .mntc-cs-item.mntc-cs-content i {
  font-size: 100px;
  margin-bottom: 25px;
  color: #e4e4e4;
}

.coming-soon .mntc-cs-item.mntc-cs-content h1 {
  font-size: 62px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 50px;
}

.coming-soon .mntc-cs-item.mntc-cs-content p {
  font-size: 28px;
  padding: 0 50px;
  line-height: 36px;
}

.coming-soon .commingsoon_countdown {
  padding: 0;
  margin: 15px 0 30px;
  display: inline-block;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  vertical-align: top;
}

.coming-soon .commingsoon_countdown li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  padding: 14px 6px;
  margin: 0 2px;
  background: #f5f5f5;
  color: #323232;
}

.coming-soon .commingsoon_countdown li span {
  vertical-align: top;
  color: var(--sage);
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
}

.coming-soon .commingsoon_countdown li p {
  margin: 0;
  font-size: 22px;
  line-height: 28px;
  vertical-align: top;
  text-transform: capitalize;
}

.coming-soon .commingsoon_countdown li:last-child {
  border-right: none;
}

.coming-soon footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  box-shadow: none;
}

.wishlist-table thead th {
  background: 0 0;
  border: none;
  padding: 15px 10px;
  text-align: left;
  font-weight: 700;
  color: #323232;
}

.wishlist-table thead th.product-thumbnail {
  width: 100px;
}

.wishlist-table thead td {
  vertical-align: middle;
  line-height: 1.5em;
  text-align: left;
  border: none;
  border-top: 1px solid #e7e7e7;
  padding: 16px 12px;
  color: #969696;
}

.wishlist-table thead td.product-thumbnail a {
  display: block;
  border: none;
  max-width: 80px;
}

.wishlist-table thead td.product-thumbnail a img {
  width: 70px;
  height: auto;
}

.wishlist-table thead td.product-stock-status .wishlist-in-stock {
  color: #61d008;
}

.wishlist-table thead td.product-stock-status .wishlist-out-of-stock {
  color: #f00;
}

.wishlist-table thead td.product-add-to-cart .add_to_cart_button {
  font-weight: 500;
  line-height: 26px;
  background: var(--sage);
  color: #fff;
  display: block !important;
  margin: 0;
  width: 180px;
  text-align: center !important;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  text-transform: uppercase;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.wishlist-table thead td.product-add-to-cart .add_to_cart_button:hover,
.wishlist-table thead td.product-add-to-cart .add_to_cart_button:focus {
  background: #323232;
}

.cart-table thead th {
  background: 0 0;
  border: none;
  padding: 15px 10px;
  text-align: left;
  font-weight: 700;
  color: #323232;
}


.br{
  display:block;
}

.table-mobile {
  font-size: 9px !important;
}

.cart-table thead th.product-thumbnail {
  width: 100px;
}

.cart-table thead td {
  vertical-align: middle;
  line-height: 1.5em;
  text-align: left;
  border: none;
  border-top: 1px solid #e7e7e7;
  padding: 16px 12px;
  color: #969696;
}

.cart-table thead td.product-remove {
  text-align: center;
  width: 50px;
}

.cart-table thead td.product-remove .remove {
  display: inline-block;
  font-size: 0;
  color: #969696;
}

.cart-table thead td.product-remove .remove:before {
  content: "\f014";
  position: relative;
  left: 6px;
  top: 0;
  font-family: FontAwesome;
  font-size: 16px;
  font-weight: 400;
}

.cart-table thead td.product-thumbnail a {
  display: block;
  border: none;
  width: 80px;
}

.cart-table thead td.product-thumbnail a img {
  width: 70px;
  height: auto;
}

.cart-table thead td.product-quantity {
  min-width: 80px;
}

.cart-table thead td.product-quantity .quantity {
  position: relative;
  padding: 0 24px;
  display: inline-block;
  width: auto;
}

.cart-table thead td.product-quantity .quantity .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 0;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}

.cart-table thead td.product-quantity .quantity input {
  width: 50px !important;
  height: 45px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0 10px;
  margin: 0;
  font-size: 16px;
  color: #969696;
  border: 1px solid #e6e6e6;
  background: #f8f8f8;
  vertical-align: middle;
  text-align: center;
}

.cart-table thead td.product-quantity .quantity .quantity-button {
  position: relative;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e6e6e6;
  width: 20px;
  text-align: center;
  color: #969696;
  font-size: 20px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 45px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.cart-table thead td.product-quantity .quantity .quantity-button.quantity-up {
  position: absolute;
  width: 26px;
  height: 100%;
  right: 0;
  top: 0;
}

.cart-table thead td.product-quantity .quantity .quantity-button.quantity-down {
  position: absolute;
  width: 26px;
  height: 100%;
  left: 0;
  top: 0;
}

.cart-table thead td.actions {
  text-align: right;
}

.cart-table thead td.actions .coupon {
  float: left;
}

.cart-table thead td.actions .coupon label {
  display: none;
}

.cart-table thead td.actions .coupon .input-text {
  float: left;
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: 0;
  width: 250px;
  margin-right: 0;
  padding: 0 15px;
  background: #f5f5f5;
  border: none;
  height: 40px;
  border-radius: 0;
}

.cart-table thead td.actions .button {
  background: var(--sage);
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  float: none;
  height: 40px;
  border-radius: 0;
  color: #fff;
}

.cart-table thead td.actions .button:hover {
  background: #323232;
}

.cart-table thead td.actions .button:disabled[disabled] {
  cursor: not-allowed;
  opacity: .5;
  padding: 0 18px;
  color: #fff;
}

.cart-table thead td.actions .button:disabled[disabled]:hover {
  opacity: .1;
}

.cart_totals {
  float: none;
  width: auto;
  border: 1px solid #e8e8e8;
  background: #f9f9f9;
  padding: 0 20px 20px;
}

.cart_totals h2 {
  font-size: 20px;
  line-height: 30px;
  color: #4b4949;
  padding: 20px;
  margin: 0;
  font-weight: 600;
  text-align: center;
}

.cart-collaterals h2 {
  font-size: 20px;
  line-height: 30px;
  padding: 20px;
  margin: 0;
  color: #4b4949;
  font-weight: 600;
  text-align: center;
}

.cart_totals h4 {
  font-size: 15px;
  line-height: 30px;
  color: grey;
  font-weight: 600;
  text-align: center;
}
.box-login-body{
    float: none;
    width: auto;
    border: 1px solid #e8e8e8;
    padding: 0 20px 20px;
}

.cart-collaterals h4 {
  font-size: 15px;
  line-height: 30px;
  color: grey;
  font-weight: 600;
  text-align: center;
}

.cart_totals .shop_table {
  text-align: left;
  width: 100%;
  border-radius: 0;
  border-collapse: separate;
  margin: 0 -1px 20px 0;
  padding: 0;
  border: none;
  background: #fff;
}

.cart_totals .shop_table .cart-subtotal th,
.cart_totals .shop_table .cart-subtotal td {
  border-top: 0;
}

.cart_totals .shop_table th {
  width: 35%;
  vertical-align: top;
  line-height: 1.5em;
  border: none;
  background: 0 0;
  text-align: left;
  font-size: 18px;
  color: #323232;
  padding: 15px 20px;
  font-weight: 600;
  border-top: 1px solid #e8e8e8;
}

.cart_totals .shop_table .order-total th {
  vertical-align: middle;
}

.cart_totals .shop_table td {
  font-weight: 600;
  color: #323232;
  text-align: right;
  font-size: 18px;
  border: none;
  padding: 20px;
  line-height: 1.5em;
  vertical-align: top;
  border-top: 1px solid #e8e8e8;
}

.cart_totals .shop_table .shipping {
  padding-top: 25px;
}

.cart_totals .shop_table .shipping th,
.cart_totals .shop_table .shipping td {
  font-size: 14px;
}

.cart_totals .shop_table .order-total td {
  font-size: 26px;
  color: var(--sage);
}

.cart_totals .shop_table #shipping_method {
  list-style: none outside;
  margin: 0;
  padding: 0;
}

.cart_totals .shop_table #shipping_method li {
  margin: 0 0 .5em;
  line-height: 1.5em;
  list-style: none outside;
}

.cart_totals .shop_table #shipping_method li input {
  margin: 5px .4375em 0 0;
  vertical-align: top;
}

.cart_totals .shop_table #shipping_method label {
  font-weight: 400;
}

.cart_totals .shop_table #shipping_method .amount {
  font-weight: 700;
}

.cart_totals .shop_table p {
  margin-bottom: 0;
  font-weight: 400;
}

.cart_totals .shop_table .shipping-calculator-button {
  float: none;
  margin-top: .5em;
  display: inline-block;
  font-weight: 400;
}

.cart_totals .shipping-calculator .popup-content {
  padding: 15px 0 !important;
}

.cart_totals .shipping-calculator .popup-content .shop_table td {
  padding: 20px 0px 20px 20px;
  border: none;
}

.cart_totals .shipping-calculator .popup-content .shop_table td
.cart_totals .shop_table .shipping-calculator-button:after {
  font-family: FontAwesome;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-left: .618em;
  text-decoration: none;
  content: "\f0d1";
}

.cart_totals .proceed-to-checkout a.button {
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 0;
  background: #323232;
  color: #fff;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.no-padding: {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.coupon a.button {
  padding: 9px 15px;
  font-size: 15px;
  margin-top: 1px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 0;
  background: #323232;
  color: #fff;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.coupon  a.button:hover {
  background: var(--sage);
}

.cart_totals .proceed-to-checkout a.button:hover {
  background: var(--sage);
}

.user-info {
  color: #fff;
  font-size: 14px;
  padding: 25px 190px;
  padding-left: 60px;
  margin: 0;
  margin-bottom: 20px;
  border: none;
  line-height: 30px;
  padding-left: 120px;
  padding-right: 25px;
  color: #323232;
  border: none;
  background: #f9f9f9;
  font-weight: 700;
  margin-bottom: 20px;
}

.user-info:before {
  display: inline-block;
  position: absolute;
  font-size: 18px;
  position: absolute;
  left: 50px;
  top: 26px;
  font-family: FontAwesome;
  color: #000;
  opacity: .1;
  font-size: 64px;
}

.user-info.message:before {
  content: "\f090";
}

.user-info.coupon:before {
  content: "\f145";
}

.user-info a {
  padding-left: 5px;
  text-decoration: underline;
  color: #323232;
  font-weight: 400;
}

.user-info a:hover {
  text-decoration: none;
  color: var(--sage);
}

.billing-fields label {
  display: block;
}

.billing-fields .select2-container,
.billing-fields .select2-selection--single {
  width: 100% !important;
}

.checkout-review-order {
  border: 1px solid #e5e5e5;
  padding: 15px 30px 0 30px;
  position: relative;
}

.checkout-review-order .checkout-review-order-table {
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-radius: 0;
  border: none;
  border-collapse: collapse;
}

.checkout-review-order .checkout-review-order-table th {
  font-weight: 700;
  padding: 15px 0;
  line-height: 1.5em;
  background: 0 0;
  border: none;
  font-size: 18px;
  color: #323232;
  border-top: 1px solid #ececec;
}

.checkout-review-order .checkout-review-order-table td {
  vertical-align: middle;
  line-height: 1.5em;
  border: none;
  border-color: rgba(255, 255, 255, 0.08);
  padding: 15px 0px;
  font-size: 14px;
  border-top: 1px solid #ececec;
}

.checkout-review-order .checkout-review-order-table thead th {
  border-top: none;
}

.checkout-review-order .checkout-review-order-table .cart-subtotal td {
  font-weight: 700;
  font-size: 18px;
  color: #323232;
}

.checkout-review-order .checkout-review-order-table #shipping_method {
  list-style: none outside;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.checkout-review-order .checkout-review-order-table #shipping_method li {
  margin: 0 0 .5em;
  line-height: 1.5em;
  list-style: none outside;
}

.checkout-review-order .checkout-review-order-table #shipping_method li input {
  margin: 3px .4375em 0 0;
  vertical-align: top;
}

.checkout-review-order .checkout-review-order-table #shipping_method li label {
  display: inline;
}

.checkout-review-order .checkout-review-order-table #shipping_method li label .amount {
  font-weight: 700;
}

.checkout-review-order .checkout-review-order-table .order-total td {
  color: var(--sage);
  font-size: 26px;
}

.checkout-review-order .payment_methods {
  margin: 0;
  list-style: none outside;
  text-align: left;
  padding: 0;
  border: none;
}

.checkout-review-order .payment_methods li {
  line-height: 2;
  text-align: left;
  margin: 0;
  font-weight: 400;
  list-style: none;
  color: #323232;
  margin: 18px 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  background: 0;
}

.checkout-review-order .payment_methods li input {
  margin: 0 1em 0 0;
  margin-right: 5px;
}

.checkout-review-order .payment_methods li label {
  margin-bottom: 0;
  font-weight: 600;
}

.checkout-review-order .payment_box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  font-size: .92em;
  border-radius: 2px;
  line-height: 1.5;
  background: #f3f3f3;
  color: #323232;
  border-radius: 3px;
}

.checkout-review-order .payment_box:before {
  content: '';
  display: block;
  border: 1em solid #dfdcde;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -.75em;
  left: 0;
  margin: -1em 0 0 2em;
  border-bottom-color: #f3f3f3;
}

.checkout-review-order .payment_box p {
  line-height: 24px;
  margin-bottom: 0;
}

.checkout-review-order .payment_method_paypal img {
  max-height: 52px;
  vertical-align: middle;
  margin: -2px 0 0 .5em;
  padding: 0;
  position: relative;
  box-shadow: none;
}

.checkout-review-order .payment_method_paypal .about_paypal {
  float: right;
  font-size: .83em;
  line-height: 30px;
  padding-left: 5px;
}

.checkout-review-order .place-order {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-left: -30px;
  margin-right: -30px;
  padding-bottom: 0;
  width: auto;
}

.checkout-review-order .place-order .terms-and-conditions-wrapper p {
  margin: 0 30px 30px;
}

.checkout-review-order .place-order .terms-and-conditions {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 1.618em;
  padding: 1.618em;
}

.checkout-review-order #place_order {
  padding: 15px 40px;
  margin-bottom: 0;
  font-size: 16px;
  float: none;
  width: 100%;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  border: none;
  background: #323232;
  color: #fff;
}

.checkout-review-order #place_order:hover {
  background: var(--sage);
}

.sidebar .widget {
  margin-bottom: 30px;
}

.sidebar .widget.widget_search #searchform {
  position: relative;
}

.sidebar .widget.widget_search #searchform .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 0;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}

.sidebar .widget.widget_search #searchform input {
  padding-right: 30px;
  border: 1px solid #e5e5e5;
}

.sidebar .widget.widget_search #searchform input:focus {
  border-color: var(--sage);
}

.sidebar .widget.widget_search .search-button {
  background: 0 0;
  border: none;
  position: absolute;
  right: 2px;
  top: 0;
  line-height: 46px;
  padding: 0 10px;
  color: #969696;
  cursor: pointer;
}

.sidebar .widget.widget_recent_entries .recent-post,
.sidebar .widget.widget_recent_entries ul li {
  display: table;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  width: 100%;
}

.sidebar .widget.widget_recent_entries .recent-post:last-child,
.sidebar .widget.widget_recent_entries ul li:last-child {
  border-bottom: 0;
}

.sidebar .widget.widget_recent_entries .recent-post .recent-post-image,
.sidebar .widget.widget_recent_entries .recent-post .recent-post-info {
  display: table-cell;
  vertical-align: middle;
}

.sidebar .widget.widget_recent_entries .recent-post .recent-post-image {
  width: 65px;
  padding-right: 12px;
}

.sidebar .widget.widget_recent_entries .recent-post .recent-post-info a {
  font-size: 14px;
  color: #323232;
  line-height: 20px;
  margin-bottom: 3px;
  display: inline-block;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.sidebar .widget.widget_recent_entries .recent-post .recent-post-info a:hover {
  color: var(--sage);
}

.cursor-pointer {
   cursor: pointer;
}

.sidebar .widget.widget_recent_entries .recent-post .recent-post-info .post-date {
  display: block;
  font-size: 12px;
  color: #969696;
}

.sidebar .widget.widget_recent_entries .recent-post .recent-post-info .post-date i {
  margin-right: 7px;
  color: var(--sage);
}

.sidebar .widget.widget_archive ul,
.sidebar .widget.widget_categories ul,
.sidebar .widget.widget_meta ul,
.sidebar .widget.widget_pages ul,
.sidebar .widget.widget_pgs_contact_widget ul,
.sidebar .widget.widget_pgs_social_profiles ul,
.sidebar .widget.widget_recent_comments ul,
.sidebar .widget.widget_rss ul {
  padding-left: 0;
  margin-bottom: 0;
}

.sidebar .widget.widget_archive ul li,
.sidebar .widget.widget_categories ul li,
.sidebar .widget.widget_meta ul li,
.sidebar .widget.widget_pages ul li {
  padding-left: 15px;
  position: relative;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--sage);
}

.sidebar .widget.widget_archive ul li:before,
.sidebar .widget.widget_categories ul li:before,
.sidebar .widget.widget_meta ul li:before,
.sidebar .widget.widget_pages ul li:before {
  content: "\f105";
  font-family: FontAwesome;
  height: 24px;
  width: 10px;
  display: inline-block;
  text-align: left;
  color: #969696;
  margin-right: 15px;
  font-weight: 100;
  line-height: 22px;
  padding-left: 2px;
  position: absolute;
  left: 0;
  top: 2px;
}

.sidebar .widget.widget_archive ul li {
  font-weight: 600;
}

.sidebar .widget.widget_categories ul li {
  font-weight: 600;
}

.sidebar .widget.widget_categories ul li .widget_categories-post-count {
  margin-left: 5px;
  color: var(--sage);
  font-weight: 600;
}

.sidebar .widget .social-profiles ul li {
  display: inline-block;
  margin: 0;
  margin-right: 7px;
  margin-bottom: 7px;
  float: left;
  list-style-type: none;
  text-align: center;
}

.sidebar .widget .social-profiles ul li a {
  width: auto;
  height: auto;
  background: 0 0;
  border: none;
  border-radius: 0;
  vertical-align: top;
  line-height: inherit;
  transition: inherit;
}

.sidebar .widget .social-profiles ul li a:hover i {
  border-color: transparent;
  background: var(--sage);
  color: #fff;
}

.sidebar .widget .social-profiles ul li i {
  border: 1px solid rgba(173, 173, 173, 0.5);
  font-size: 13px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  vertical-align: top;
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.sidebar .widget ul li {
  list-style-type: none;
  margin: 5px 0;
}

.sidebar .widget ul li a {
  font-weight: 400;
  text-transform: capitalize;
  color: #969696;
  display: inline-block;
  line-height: 24px;
}

.sidebar .widget ul li a:hover {
  color: var(--sage);
}

.sidebar .widget .widget-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  position: relative;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.post {
  margin-bottom: 60px;
}

.post .blog-entry-audio,
.post .blog-entry-grid,
.post .blog-entry-html-video,
.post .blog-entry-quote,
.post .blog-entry-slider,
.post .blog-entry-vimeo,
.post .blog-entry-you-tube,
.post .post-entry-image {
  margin-bottom: 25px;
}

.post .blog-entry-audio img,
.post .blog-entry-grid img,
.post .blog-entry-html-video img,
.post .blog-entry-quote img,
.post .blog-entry-slider img,
.post .blog-entry-vimeo img,
.post .blog-entry-you-tube img,
.post .post-entry-image img {
  width: 100%;
}

.post .entry-header-section {
  position: relative;
  margin-bottom: 15px;
}

.post .entry-header-section .entry-meta-date {
  display: block;
  margin-bottom: 5px;
}

.post .entry-header-section .entry-meta-date a {
  color: var(--sage);
  text-transform: uppercase;
  font-weight: 600;
}

.post .entry-header-section .entry-title {
  color: #323232;
  font-weight: 600;
  margin-bottom: 5px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  font-size: 24px;
  line-height: 34px;
}

.post .entry-header-section .entry-title a {
  color: #323232;
}

.post .entry-header-section .entry-meta ul {
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.post .entry-header-section .entry-meta ul li {
  list-style-type: none;
  display: inline;
  margin-right: 8px;
  line-height: 20px;
  color: #bbb;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.post .entry-header-section .entry-meta ul li .fa-comments-o {
  padding-right: 3px;
}

.post .entry-header-section .entry-meta ul li:after {
  content: "/";
  padding-left: 8px;
  color: #ccc;
  font-size: 11px;
  position: relative;
  top: 1px;
}

.post .entry-header-section .entry-meta ul li:last-child {
  margin-right: 0;
}

.post .entry-header-section .entry-meta ul li:last-child:after {
  content: '';
}

.post .entry-header-section .entry-meta ul li a {
  color: #bbb;
  font-size: 12px;
}

.post .entry-header-section .entry-meta ul li a:hover {
  color: var(--sage);
}

.post .entry-header-section .entry-content {
  margin-bottom: 30px;
}

.post .entry-footer {
  position: relative;
  clear: both;
}

.post .entry-footer .readmore {
  background: #323232;
  font-size: 13px;
  color: #fff;
  line-height: 30px;
  display: inline-block;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
}

.post .entry-footer .readmore:hover {
  background: var(--sage);
}

.post .share {
  position: absolute;
  cursor: pointer;
  right: 0;
  z-index: 9;
  top: 0;
}

.post .share .share-button {
  background: 0 0;
  border-radius: 3px;
  padding: 2px 12px;
  font-size: 13px;
  color: #323232;
  position: relative;
  display: inline-block;
  z-index: 99;
  border: 1px solid #e5e5e5;
}

.post .share a {
  font-weight: 500;
  line-height: 22px;
  font-size: 18px;
}

.post .share ul {
  float: none;
  opacity: 0;
  background: #f9f9f9;
  border-radius: 3px;
  padding: 1px 10px;
  margin-bottom: 0;
  right: 10px;
  top: 0;
  position: absolute;
  display: table;
  visibility: hidden;
  transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  font-weight: 500;
  line-height: 22px;
  font-size: 18px;
  list-style-type: none;
}

.post .share ul:before {
  position: absolute;
  z-index: 999;
  content: "";
  right: -10px;
  top: 9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 10px;
  border-color: transparent transparent transparent #f9f9f9;
  box-shadow: 0 0 black;
}

.post .share ul li {
  display: table-cell;
  line-height: 26px;
  list-style: none;
}

.post .share ul li a {
  display: block;
  padding: 2px 10px 2px;
  font-size: 14px;
}

.post .share ul li .facebook-share {
  color: #3b5998;
}

.post .share ul li .twitter-share {
  color: #55acee;
}

.post .share ul li .linkedin-share {
  color: #007bb5;
}

.post .share ul li .facebook-share {
  color: #3b5998;
}

.post .share ul li .google-plus-share {
  color: #fc5b4d;
}

.post .share ul li .pinterest-share {
  color: #ca2028;
}

.post .share:hover ul {
  opacity: 1;
  right: 55px;
  visibility: visible;
  transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
}

.post .blog-entry-quote blockquote {
  background: var(--sage);
  color: #fff;
  border-left: none;
  padding: 25px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
  padding-left: 50px;
  padding-top: 40px;
  border-radius: 5px;
}

.post .blog-entry-quote blockquote .fa,
.post .blog-entry-quote blockquote i {
  float: left;
}

.post .blog-entry-quote blockquote i {
  position: absolute;
  left: 25px;
  top: 15px;
  opacity: .25;
  margin: 0;
  font-size: 80px;
  color: #fff;
}

.post .blog-entry-quote blockquote p {
  display: table;
  vertical-align: top;
  margin-bottom: 10px;
}

.post .blog-entry-quote blockquote .quote-author {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
  text-align: left !important;
}

.navigation.pagination {
  padding-left: 15px;
  display: block;
}

.navigation.pagination .page-numbers {
  border: none;
  padding-left: 0;
  margin-bottom: 0;
}

.navigation.pagination .page-numbers li {
  border: none;
  margin: 4px;
  list-style: none;
  display: inline-block;
  line-height: inherit;
}

.navigation.pagination .page-numbers li > span,
.navigation.pagination .page-numbers li > a {
  background: #fff;
  font-size: 15px;
  color: #323232;
  font-weight: 400;
  border: 1px solid #e5e5e5;
  padding: 8px 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  display: inline-block;
}

.navigation.pagination .page-numbers li a:hover {
  background: var(--sage);
  border-color: var(--sage);
  color: #fff;
}

.navigation.pagination .page-numbers li .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 0;
  width: 1px;
  overflow: hidden;
}

.navigation.pagination .page-numbers li .current {
  background: var(--sage);
  border-color: var(--sage);
  color: #fff;
}

.blog-grid .post {
  background: #fff;
  position: relative;
  border: 1px solid #f2f2f2;
  padding: 20px;
  margin-bottom: 50px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
}

.blog-grid .post .entry-meta-date {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 2;
}

.blog-grid .post .entry-meta-date a {
  background: var(--sage);
  color: #fff;
  padding: 0 18px;
  line-height: 32px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.blog-grid .post.format-quote .entry-meta-date {
  position: relative;
  top: 0;
  margin-bottom: 10px;
}

.single-post .entry-content {
  margin-bottom: 40px;
}

.single-post .entry-footer .share {
  position: initial;
  padding-left: 25%;
}

.single-post .entry-footer .share .share-button {
  position: absolute;
  left: 0;
  top: 7px;
  background: 0 0;
  padding: 0;
  color: #323232;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
  border: none;
}

.single-post .entry-footer .share .share-button:after {
  content: attr(data-title);
  padding-left: 8px;
}

.single-post .entry-footer .share ul {
  position: relative;
  visibility: visible;
  opacity: 1;
  background: 0 0;
  right: 0;
  padding: 0;
}

.single-post .entry-footer .share ul li {
  display: inline;
  float: right;
  list-style-type: none;
}

.single-post .entry-footer .share ul li a {
  display: block;
  padding: 5px 25px;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  margin: 2px;
  border-radius: 3px;
}

.single-post .entry-footer .share ul li .facebook-share:hover {
  color: #fff;
  background: #3b5998;
  border-color: #3b5998;
}

.single-post .entry-footer .share ul li .twitter-share:hover {
  color: #fff;
  background: #55acee;
  border-color: #55acee;
}

.single-post .entry-footer .share ul li .linkedin-share:hover {
  color: #fff;
  background: #007bb5;
  border-color: #007bb5;
}

.single-post .entry-footer .share ul li .facebook-share:hover {
  color: #fff;
  background: #3b5998;
  border-color: #3b5998;
}

.single-post .entry-footer .share ul li .google-plus-share:hover {
  color: #fff;
  background: #fc5b4d;
  border-color: #fc5b4d;
}

.single-post .entry-footer .share ul li .pinterest-share:hover {
  color: #fff;
  background: #ca2028;
  border-color: #ca2028;
}

.single-post .entry-footer .share:hover ul {
  position: relative;
  visibility: visible;
  opacity: 1;
  background: 0 0;
  right: 0;
  padding: 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 0;
  width: 1px;
  overflow: hidden;
}

.post-navigation .nav-links {
  display: table;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.post-navigation .nav-links:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #e5e5e5;
}

.post-navigation .nav-links .nav-next,
.post-navigation .nav-links .nav-previous {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}

.post-navigation .nav-links .nav-next a,
.post-navigation .nav-links .nav-previous a {
  background: 0 0;
  color: #323232;
  padding: 15px 0;
  font-weight: 500;
  border-radius: 3px;
  display: block;
  -ms-word-break: break-all;
  word-break: break-all;
}

.post-navigation .nav-links .nav-previous {
  padding-right: 1%;
  float: left;
}

.post-navigation .nav-links .nav-next {
  padding-left: 1%;
  float: right;
}

.related-posts {
  margin-bottom: 30px;
}

.related-posts h3 {
  font-size: 24px;
  margin-bottom: 30px;
}

.related-posts .related-post-info h5 {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.related-posts .related-post-info h5 a {
  color: #969696;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.ReactTable .rt-noData {
  padding: 8px 20px;
  margin-top: 2px;
}

.comments-area .comment-respond {
  margin-bottom: 30px;
  margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent input[type=checkbox] {
  margin-right: 10px;
  margin-top: 6px;
}

.comments-area .comment-respond .section-field,
.comments-area .respond-form .section-field {
  display: inline-block;
  width: 31.33333%;
  float: left;
  margin-right: 3%;
  margin-bottom: 3%;
}

.comments-area .comment-respond .section-field i,
.comments-area .respond-form .section-field i {
  display: none;
}

.comments-area .comment-respond .section-field.comment-form-url,
.comments-area .respond-form .section-field.comment-form-url {
  margin-right: 0;
}

.comments-area .comment-respond .section-field.textarea,
.comments-area .respond-form .section-field.textarea {
  width: 100%;
  float: none;
}

.comments-area .comment-respond .section-field.textarea textarea,
.comments-area .respond-form .section-field.textarea textarea {
  width: 100%;
  height: auto;
}

.comments-area .comment-respond .form-submit,
.comments-area .respond-form .form-submit {
  display: inline-block;
  margin-bottom: 0;
}

.comments-area .respond-form .form-submit .submit {
  background: var(--sage);
  color: #ffffff;
  border-color: var(--sage);
}

.comments-area .respond-form .form-submit .submit:hover {
  background: #323232;
  border-color: #323232;
}

.my-account h2 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 25px;
  position: absolute;
  top: 30px;
  left: 50px;
  font-weight: 600;
}

.my-account .form {
  padding: 30px;
  padding-top: 80px;
  margin-bottom: 0;
  height: 100%;
  border: solid;
  border-width: 3px;
  border-color: #e8ebf1;
}

.my-account .form .required {
  color: red;
  font-weight: 700;
  border: 0 !important;
  text-decoration: none;
}

.my-account .form .form-row {
  margin: 0;
  margin-bottom: 20px;
}

.my-account .form button.button {
  margin-right: 10px;
  border: none;
  color: #fff;
  line-height: 38px;
  padding: 0 18px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  background: var(--sage);
}

.my-account .form button.button:hover {
  background: #323232;
}

.my-account .form .remember {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: 15px;
}

.my-account .form.register .form-row {
  margin-bottom: 0;
}

.shop-filter-wrapper .rangeslider-horizontal .rangeslider__fill {
  background: var(--sage);
  box-shadow: none;
}

.shop-filter-wrapper .rangeslider {
  box-shadow: none;
}

.shop-filter-wrapper .rangeslider-horizontal {
  height: 6px;
}

.shop-filter-wrapper .rangeslider-horizontal .rangeslider__handle {
  box-shadow: none;
  border: 3px solid  var(--sage);
  width: 18px;
  height: 18px;
  background: #ffffff;
}

.shop-filter-wrapper .rangeslider-horizontal .rangeslider__handle:after {
  display: none
}

/* home slider 01 */
.slider-01 .slide-inner {
  position: relative;
}

.slider-01 .slide-content {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  top: 16%;
}

.slider-01 .slide-content .slide-sale {
  font-size: 50px;
  line-height: 50px;
  display: inline-flex;
  padding: 4px 6px;
  font-weight: 600;
  background: #323232;
  color: #ffffff;
}

.slider-01 .slide-content .slide-sale span {
  padding-left: 10px;
  font-size: 24px;
  line-height: 24px;
}

.slider-01 .slide-content .slide-title h1 {
  font-size: 140px;
  line-height: 140px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
}

.slider-01 .slide-content .slide-left {
  margin-top: 40px;
}

.slider-01 .slide-content .slide-subtitle {
  font-size: 80px;
  line-height: 80px;
  color: #323232;
  font-weight: 700;
  margin-top: 20px;
}

.slider-01 .slide-content .slide-since {
  margin-top: 30px;
  display: inline-block;
  background: #fff2d5;
  padding: 6px 10px;
  font-size: 22px;
  line-height: 22px;
  color: #323232;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
}

.slider-01 .slide-content .slide-offer {
  font-size: 50px;
  text-transform: capitalize;
  line-height: 50px;
  word-spacing: 100vw;
  color: #323232;
  font-weight: 700;
}

.slider-01 .slide-content .slide-button {
  font-size: 18px;
  border-bottom: 2px solid #323232;
  color: #323232;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 20px;
  display: inline-block;
}

.slider-01 .slide-content .slide-button:hover {
  color: var(--sage);
  border-color: var(--sage);
}

.slider-01 .slide-content .slide-button-flat {
  font-size: 14px;
  color: #323232;
  background: #efcac8;
  font-weight: 700;
  padding: 10px 22px;
  text-transform: uppercase;
  margin-top: 30px;
  display: inline-block;
}

.slider-01 .slide-content .slide-button-flat:hover {
  color: #ffffff;
  background: var(--sage);
}

.slider-01 .slick-next {
  right: 0;
}

/* home slider 02 */
.slider-02 .slide-inner {
  position: relative;
}

.slider-02 .slide-content {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.slider-02 .slide-content .slide-right {
  text-align: center;
  margin-left: auto;
  padding: 80px 50px;
  background: #ffffff;
  border: 20px solid transparent;
  border-image: url("../images/backgrounds/spring-pat.jpg") 50;
}

.slider-02 .slide-content .slide-left {
  text-align: center;
  padding: 80px 50px;
  background: #ffffff;
  border: 20px solid transparent;
  border-image: url("../images/backgrounds/spring-pat2.jpg") 50;
}

.slider-02 .slide-content .slide-subtitle {
  text-transform: uppercase;
  font-weight: 500;
  color: #323232;
}

.slider-02 .slide-content .slide-title h1 {
  font-size: 72px;
  line-height: 100px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.slider-02 .slide-content .slide-button-flat {
  font-size: 18px;
  color: #ffffff;
  background: #323232;
  font-weight: 600;
  padding: 12px 30px;
  margin-top: 0px;
  display: inline-block;
  border-radius: 330px;
}

.slider-02 .slide-content .slide-button-flat:hover {
  color: #ffffff;
  background: var(--sage);
}

.slider-02 .slick-next {
  right: 0;
}

/* home slider 03 */
.slider-03 .slide-inner {
  position: relative;
}

.slider-03 .slide-content {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.slider-03 .slide-content .slide-since {
  margin-top: 30px;
  display: inline-block;
  background: #323232;
  padding: 8px 60px;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 6px;
}

.logo-mobile {
  max-width: 80%;
  margin-left: 12%;
}

.slider-03 .slide-content .slide-title h1 {
  font-size: 65px;
  line-height: 100px;
  margin-bottom: 50px;
  margin-top: 20px;
  font-weight: 600;
  letter-spacing: 9px;
  text-transform: uppercase;
}

.slider-03 .slide-content .slide-button {
  font-size: 18px;
  border-bottom: 2px solid #323232;
  color: #323232;
  font-weight: 700;
  display: inline-block;
}

.slider-03 .slide-content .slide-button:hover {
  color: var(--sage);
  border-color: var(--sage);
}

.slider-03 .slick-next {
  right: 0;
}

/* home slider 04 */
.slider-04 .slide-inner {
  position: relative;
}

.slider-04 .slide-content {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.slider-04 .slide-content .slide-inner {
  padding: 80px 50px;
  box-shadow: 15px 15px 0px rgb(253, 225, 214);
}

.slider-04 .slide-content .slide-title h1 {
  font-size: 65px;
  line-height: 80px;
  font-weight: 600;
  text-transform: uppercase;
}

.slider-04 .slide-content .slide-subtitle {
  font-weight: 500;
  color: #323232;
  font-size: 32px;
  display: inline-block;
  margin-bottom: 20px;
}

.slider-04 .slide-content p {
  font-size: 18px;
}

.slider-04 .slide-content .slide-inner-02 {
  padding: 40px 40px;
  box-shadow: 15px 15px 0px rgb(253, 225, 214);
  position: relative;
}

.slider-04 .slide-content .slide-inner-02 .slide-sale {
  position: absolute;
  left: 0;
  right: 0;
}

.slider-04 .slide-content .slide-button {
  font-size: 18px;
  border-bottom: 2px solid #323232;
  color: #323232;
  font-weight: 700;
  display: inline-block;
}

.slider-04 .slide-content .slide-button:hover {
  color: var(--sage);
  border-color: var(--sage);
}

/* slider-simple-arrow */
.slider-simple-arrow .slick-next {
  right: 40px;
}

.slider-simple-arrow .slick-prev {
  left: 40px;
  z-index: 1;
}

.slider-simple-arrow .slick-prev,
.slider-simple-arrow .slick-next {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.slider-simple-arrow .slick-prev:before,
.slider-simple-arrow .slick-next:before {
  font-size: 16px;
  width: 50px;
  height: 50px;
  display: block;
  line-height: 50px;
  color: #323232;
  background: #ffffff;
  font-family: FontAwesome;
}

.slider-simple-arrow .slick-prev:before {
  content: "\f053";
}

.slider-simple-arrow .slick-next:before {
  content: "\f054";
}

/* slider-arrow-hover */
.slider-arrow-hover {
  overflow: hidden;
}

.slick-next,
.slick-prev {
  height: 40px;
}

.slider-arrow-hover .slick-next {
  right: -30px;
}

.slider-arrow-hover .slick-prev {
  left: -30px;
  z-index: 1;
}

.slider-arrow-hover .slick-prev,
.slider-arrow-hover .slick-next {
  line-height: 40px;
  height: 40px;
}

.slider-arrow-hover .slick-prev:before,
.slider-arrow-hover .slick-next:before {
  font-size: 14px;
  width: 26px;
  height: 40px;
  display: block;
  line-height: 40px;
  border-radius: 3px;
  color: #ffffff;
  opacity: 1;
  background: #323232;
  transition: all 0.3s ease-in-out;
  font-family: FontAwesome;
}

.slider-arrow-hover .slick-prev:hover:before,
.slider-arrow-hover .slick-next:hover:before {
  color: #ffffff;
  background: var(--sage);
}

.slider-arrow-hover .slick-prev:before {
  content: "\f053";
}

.slider-arrow-hover .slick-next:before {
  content: "\f054";
}

.slider-arrow-hover:hover .slick-next {
  right: 20px;
}

.slider-arrow-hover:hover .slick-prev {
  left: 15px;
  z-index: 1;
}

/* slider-spacing-10  */
.slider-spacing-10 .slick-list {
  margin: 0 -10px;
}

.slider-spacing-10 .item > div {
  margin: 10px;
}

/* OrderHistory */
.woocommerce-MyAccount-navigation .nav .nav-item .nav-link {
  border: 1px solid #e5e5e5;
  margin-top: -1px;
  padding: 14px 26px;
  color: #969696;
}

.woocommerce-MyAccount-navigation .nav .nav-item .nav-link i {
  padding-right: 6px;
}

.woocommerce-MyAccount-navigation .nav .nav-item .nav-link:hover,
.woocommerce-MyAccount-navigation .nav .nav-item .nav-link.active {
  background: #f5f5f5;
}

.orderhistory-table thead.thead-light th {
  background: #f5f5f5;
  padding: 14px 20px;
  border-bottom: 0;
}

.orderhistory-table tbody tr td {
  padding: 14px 20px;
}

.orderhistory-table .action-button {
  padding: 10px 20px;
  background-color: var(--sage);
  color: #ffffff;
  border-radius: 3px;
  text-transform: uppercase;
}

.orderhistory-table .action-button:hover {
  background-color: #323232;
  color: var(--sage);
}

/* woocommerce-Address */
.woocommerce-Address {
  padding: 20px;
}

.woocommerce-Address .woocommerce-Address-title {
  display: flex;
  align-items: center;
}

.woocommerce-Address .woocommerce-Address-title .edit {
  margin-left: auto;
}

.woocommerce-Address .woocommerce-Address-info {
  background: #ffffff;
  padding: 20px;
}

.woocommerce-Address .woocommerce-Address-info li {
  display: flex;
}

.woocommerce-Address .woocommerce-Address-info li span {
  min-width: 90px;
  display: inline-block;
}

.add-new-card {
  padding: 10px 20px;
  background-color: var(--sage);
  color: #ffffff;
  border-radius: 3px;
  text-transform: uppercase;
}

.add-new-card:hover {
  background-color: #323232;
  color: var(--sage);
}

/* success-screen */
.success-screen {
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.success-screen .thank-you {
  background: var(--sage);
  padding: 40px;
}

.success-screen .thank-you i {
  font-size: 80px;
  line-height: 80px;
  color: #ffffff;
}

.success-screen .thank-you span {
  font-size: 18px;
  line-height: 30px;
  display: block;
  color: #ffffff;
  margin-bottom: 20px;
}

.ordered-detail {
  padding: 0 40px 40px 40px;
}

.ordered-item {
  background: #f5f5f5;
  margin-bottom: 5px;
  text-align: center;
}

.ordered-item h6 {
  font-size: 16px;
}

.ordered-detail .table .ordered-item .ordered-image {
  width: 50px;
  display: inline-block;
  border: none;
}

.ordered-detail .table td {
  padding: 20px 10px;
  vertical-align: top;
}

.table.total-table td {
  padding: 10px 20px;
}

.success-screen .button {
  padding: 10px 20px;
  background: var(--sage);
  color: #ffffff;
}

/* reports page */
.reports-table.table-striped tbody tr:nth-of-type(odd) {
  background: #f5f5f5;
}

.reports-table th,
.reports-table td {
  padding: 20px;
}

.reports-table .table th,
.reports-table .table td {
  vertical-align: middle;
}

.reports-tab .nav-pills .nav-link {
  margin-left: 4px;
  background: #f5f5f5;
  color: #323232;
}

.reports-tab .nav-pills .nav-link.active,
.reports-tab .nav-pills .show>.nav-link,
.reports-tab .nav-pills .nav-link:hover {
  background: var(--sage);
  color: #ffffff;
}

/* invoices page */
.ReactTable .view-button {
  background: var(--sage);
  color: #ffffff;
  padding: 10px 14px 0;
  font-size: 0px;
  border-radius: 3px;
}

.ReactTable .view-button i {
  font-size: 14px;
  padding-left: 0 !important;
}

.ReactTable .delete-button {
  background: #dc3545;
  color: #ffffff !important;
  padding: 10px 14px 0;
  border-radius: 3px;
  margin-left: 3px;
  cursor: pointer;
  font-size: 0px;
  width: 42px;
  text-align: center;
}

.ReactTable .delete-button i {
  font-size: 14px;
  padding-left: 0 !important;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 15px 10px;
  font-weight: 600;
}

.ReactTable .rt-tr-group:nth-of-type(odd) {
  background: #f5f5f5;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 10px 10px;
}

.ReactTable .rt-td > div {
  display: flex;
  flex-wrap: wrap;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  background: #fbfbfb;
  border-bottom: 1px solid #e5e5e5;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  font-weight: 700;
  color: #323232;
}

.ReactTable .rt-thead .rt-th {
  padding: 20px 10px;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  color: #969696;
}

.ReactTable .-pagination {
  box-shadow: none;
  border-top-width: 1px;
}

.invoices-table .rt-resizable-header {
  position: relative;
  padding-right: 20px;
}

.invoices-table .rt-resizable-header:before {
  position: absolute;
  content: "\f175";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  font-size: 14px;
  color: #969696;
  right: 8px;
  top: 50%;
  margin-top: -8px;
}

.invoices-table .rt-resizable-header:after {
  position: absolute;
  content: "\f176";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  font-size: 14px;
  color: #969696;
  right: 16px;
  top: 50%;
  margin-top: -8px;
}

.invoices-table .rt-resizable-header.-sort-asc:after {
  color: var(--sage);
}

.invoices-table .rt-resizable-header.-sort-desc:before {
  color: var(--sage);
}

.invoices-table .rt-resizable-header:nth-child(2):before,
.invoices-table .rt-resizable-header:nth-child(2):after {
  display: none;
}

.modal-delete .modal-content {
  padding: 20px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  color: #323232;
}

.modal-delete .modal-content .action-button {
  background: var(--sage);
  color: #ffffff;
  padding: 6px 20px;
  line-height: 30px;
  font-size: 13px;
  border-radius: 3px;
}

.modal-delete .modal-content .action-button.no {
  background: #dc3545;
  color: #ffffff;
}

.svelta-pagination .page-item.active .page-link {
  background: var(--sage);
  border-color: var(--sage);
}

.woocommerce-Address .woocommerce-Address-info strong {
  word-break: break-word;
}

.btn {
  padding: 10px 20px;
  border-radius: 3px;
}

.btn-primary {
  background: var(--sage)!important;
  border-color: var(--sage)!important;
  color: #ffffff!important;
}

.btn-primary:focus {
  background: var(--sage)!important;
  border-color:var(--sage)!important;
  color: #ffffff!important;
}

.btn-primary:disabled {
  background: var(--sage)!important;
  border-color: var(--sage) !important;
  color: #ffffff!important;
}

.btn-primary:active {
  background: var(--sage)!important;
  border-color: var(--sage)!important;
  color: #ffffff!important;
}



.btn-primary:hover {
  background:#adc199!important;
  border-color: #adc199!important;
  color: #ffffff!important;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none;
  color: #ffffff;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background: #323232;
  border-color: #323232;
  color: #ffffff;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: none;
  color: #ffffff;
}

/* admin profile */
.admin-profile {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  margin-bottom: 20px;
  padding: 30px;
}

.admin-profile .admin-profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 30px;
}

.admin-profile .admin-profile-number {
  color: #323232;
  background: transparent;
}

.admin-profile .admin-profile-number i {
  color: var(--sage);
}

/* collaboration page */
.collaboration-table .user-image {
  width: 40px;
  height: 40px;
}

.collaboration-table tbody {
  border-bottom: 1px solid #e8e8e8;
}

.collaboration-table tbody h3 {
  padding: 20px;
  margin-bottom: 0;
  display: block;
  width: 100%;
  font-size: 20px;
}

.collaboration-table .delete-button {
  background: #dc3545;
  color: #ffffff;
  padding: 6px 20px;
  line-height: 30px;
  font-size: 13px;
  border-radius: 3px;
}

.collaboration-table th,
.collaboration-table td {
  padding: 20px;
  text-transform: capitalize;
  vertical-align: middle;
}

/* modal add user */
.modal-add-user .modal-content {
  padding: 20px;
  color: #323232;
}

.modal-add-user .modal-content .action-button {
  background: var(--sage);
  color: #ffffff;
  padding: 6px 20px;
  line-height: 30px;
  font-size: 13px;
  border-radius: 3px;
}

.modal-add-user .modal-content .action-button.no {
  background: #dc3545;
  color: #ffffff;
  border-color: #dc3545;
}

/* admin-menu */
.admin-menu {
  background: #f5f5f5;
  padding: 50px 0 0;
}

.admin-menu .logo {
  width: 160px;
}

.admin-menu .navbar {
  margin-top: 30px;
  border: 1px solid #e5e5e5;
  position: relative;
  bottom: -20px;
  padding: 0;
  border-radius: 3px;
}

.admin-menu .navbar .navbar-nav .nav-item .nav-link {
  font-weight: 600;
  text-transform: capitalize;
  color: #323232;
  padding: 12px 20px;
}

.admin-menu .navbar .navbar-nav .nav-item .nav-link i {
  padding-right: 6px;
}

.admin-menu .navbar .navbar-nav .nav-item.active .nav-link {
  color: var(--sage);
}

.admin-menu .navbar .navbar-nav .nav-item .nav-link:hover {
  color: var(--sage);
}

.admin-menu .navbar .dropdown .dropdown-menu {
  margin: 0;
  padding: 0;
}

.admin-menu .navbar .dropdown .dropdown-menu .nav-link {
  padding: 10px 20px;
  color: #323232;
}

.admin-menu .navbar .dropdown .dropdown-menu .dropdown-item:hover .nav-link {
  color: var(--sage)  ;
}

.admin-menu .navbar .dropdown .dropdown-item {
  padding: 8px 20px;
  font-weight: 500;
}

.admin-menu .navbar .dropdown .dropdown-item i {
  padding-right: 6px;
}

.admin-menu .navbar .dropdown .dropdown-item.active,
.admin-menu .navbar .dropdown .dropdown-item:active,
.admin-menu .navbar .dropdown .dropdown-item:hover {
  background: var(--sage);
  border-color: var(--sage);
  color: #ffffff !important;
  box-shadow: none;
}

/* profile-dropdown */
.profile-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  margin-left: auto;
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  color: #323232;
  text-align: left;
}

.profile-dropdown .dropdown-toggle::after {
  border-top: 0.6em solid;
  border-right: 0.6em solid transparent;
  border-left: 0.6em solid transparent;
  margin-left: 20px;
}

.profile-dropdown .dropdown-toggle:focus,
.profile-dropdown .dropdown-toggle:active {
  border: 0;
  box-shadow: inherit;
}

.profile-dropdown .dropdown-toggle .profile-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: inherit !important;
}

.profile-dropdown .btn-secondary:not(:disabled):not(.disabled).active,
.profile-dropdown .btn-secondary:not(:disabled):not(.disabled):active,.profile-dropdown.show> .btn-secondary.dropdown-toggle {
  color: #323232;
}

.profile-dropdown .dropdown-menu {
  width: 240px;
  border-radius: inherit;
  border: none;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.12);
  margin: 0;
  padding: 0;
  margin-top: 5px;
}

.profile-dropdown .dropdown-menu .dropdown-item {
  padding: 10px 20px;
}

.profile-dropdown .dropdown-menu .dropdown-item a {
  color: #323232;
  padding: 10px 20px;
}

.profile-dropdown .dropdown-menu .dropdown-item i {
  padding-right: 8px;
}

.profile-dropdown .dropdown-menu .dropdown-item:hover {
  color: #ffffff;
  background: var(--sage);
}

.header .mega-menu .menu-links li a.active {
  color: #db2d2e;
}

.seen-data {
  background: #28a745;
  color: #ffffff;
  padding: 20px;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.lazyload-img {
  background-image: url("../images/loader-2.svg");
  background-position: center center;
  background-attachment: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  bottom: -20px;
  padding: 12px 30px;
  margin-left: -25px;
}

/* single-product edit */
.single-product-edit .entry-summary .form-control {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.single-product-edit .entry-summary .product_title {
  color: #323232;
  text-overflow: ellipsis;
}

.single-product-edit .entry-summary .form-group label {
  position: relative;
  padding-left: 21px;
  margin-right: 20px;
}

.single-product-edit .entry-summary .form-group label .form-check-input {
  margin-left: 0;
  left: 0;
  margin-top: 5px;
}

.single-product-edit .fileContainer .chooseFileButton {
  background: #323232;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--sage);
  border-radius: 50%;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}

.single-product-edit .fileContainer .chooseFileButton:hover {
  background: #ffffff;
}

.single-product-edit .fileContainer .chooseFileButton:before {
  content: "\f044";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -7px;
}

.single-product-edit .svelta-product-thumbnail__image {
  position: relative;
}

.single-product-edit .fileContainer {
  position: inherit;
  padding: 0;
  margin: 0;
}

.single-product-edit .fileContainer p {
  display: none;
}

.single-product-edit .fileContainer .uploadPictureContainer {
  width: 100%;
}

.single-product-edit .fileContainer .uploadPictureContainer img {
  object-fit: cover;
  width: 103px;
  height: 131px;
}

.single-product-edit .fileContainer .uploadPictureContainer {
  margin: 0;
  border: none;
  box-shadow: none;
}

.single-product-edit .fileContainer .deleteImage {
  width: 18px;
  height: 18px;
  font-size: 10px;
  line-height: 18px;
  top: 0;
  right: 0;
}

.single-product-edit .fileContainer .uploadPictureContainer {
  padding: 0;
}

.single-product-edit .fileContainer .uploadPicturesWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.single-product-edit .summary .form-group {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.single-product-edit .summary .title {
  font-weight: 600;
  color: #323232;
  margin-bottom: 0;
}

.single-product-edit .edit-icon {
  position: relative;
}

.single-product-edit .product-top-right-inner {
  padding-left: 30px;
}

.single-product-edit .edit-icon:before {
  position: absolute;
  content: "\f044";
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: 900;
  left: -40px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #f5f5f5;
  color: #323232;
  text-align: center;
  top: 10px;
  border-radius: 3px;
}

.single-product-edit .product-top-right-inner .btn {
  color: #ffffff;
  margin-right: 5px;
}

.single-product-edit .product-top-right-inner .btn-primary:not(:disabled):not(.disabled).active,
.single-product-edit .product-top-right-inner .btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle {
  background: #323232;
  color: #ffffff;
}

.single-product-edit .product-top-right-inner a:not([href]):not([tabindex]):focus,
.single-product-edit .product-top-right-inner a:not([href]):not([tabindex]):hover {
  color: #ffffff;
}

.svelta-product-thumbnails__wrapper .slick-track {
  width: 100% !important;
}

/*modal-login*/
.modal .modal-header {
  padding: 0;
  margin: 0;
  border-bottom: 0;
}

.modal .modal-header .close {
  padding: 0;
  background: #fff;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--sage);
  text-shadow: none;
  position: absolute;
  right: -20px;
  top: -20px;
  margin: 0;
  z-index: 99;
}

.modal .modal-header .close:hover {
  background: #323232;
  color: #fff;
}

.svelta_banner.svelta_banner-style-deal-2.svelta_banner-effect-none {
  margin-top: 10px;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
  outline: none;
  box-shadow: none;
}

.modal-login .modal-header {
  background: var(--sage);
  padding: 24px;
}

.modal-login .modal-header h4 {
  color: #ffffff;
}

.modal-login .nav-tabs.nav-tabs {
  border: none;
  margin-bottom: 20px;
  justify-content: center;
}

.modal-login .nav-tabs.nav-tabs .nav-link {
  background: #fbfbfb;
  cursor: pointer;
  border-radius: 3px;
  color: #323232;
  border: none;
  margin-right: 3px;
}

.modal-login .nav-tabs.nav-tabs .nav-link.active {
  background: var(--sage);
  color: #ffffff;
}

.modal-login .modal-body {
  padding: 30px;
}

.modal-login .modal-body .form-control {
  background: #ffffff;
}

.pagination {
  justify-content: center;
}

.pagination .page-link {
  padding: 12px 18px;
  color: #323232;
}

.page-item.active .page-link {
  background: #03d39f;
  color: #ffffff;
  border-color: var(--sage);
}

.react-tabs__tab-list {
  border: none !important;
  margin-bottom: 20px !important;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab {
  background: #f5f5f5;
  color: #323232;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  margin-right: 3px;
  margin-bottom: 3px;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected,
.react-tabs .react-tabs__tab-list .react-tabs__tab:hover {
  background: #03d39f;
  border-color: #03d39f;
  color: #ffffff;
}

.reports-table {
  margin-top: 80px;
}

.Toastify__toast--success {
  background: #28a745;
  box-shadow: none;
}

.Toastify__toast--warning {
  background: #ffc107;
  color: #323232;
  box-shadow: none;
}

.Toastify__toast-body {
  padding: 2px 20px;
}

.Toastify__toast {
  padding: 0;
  min-height: 44px;
  box-shadow: none;
}

.Toastify__close-button {
  min-height: 44px;
  line-height: 44px;
  background: rgba(0, 0, 0, .3);
  padding: 10px 20px;
}

.ril__caption,
.ril__toolbar {
  background: transparent !important;
}

.svelta-btn .inline_hover:hover,
.svelta-btn.inline_hover:hover {
  color: var(--sage);
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10000 !important;
}

.popup-overlay {
  z-index: 9999 !important;
}

button:focus {
  outline: 0
}

@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
  .single-post .entry-footer .share {
    width: 100%;
    display: flex;
  }

  .single-post .entry-footer .share .single-share-box {
    margin-left: auto;
  }

  .post {
    margin-bottom: 110px;
  }

  .recent-post .recent-post-image img {
    width: 100%;
  }

  .content-wrapper .content-wrapper {
    width: 100%;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    z-index: 999;
  }

  .svelta_info_box_2-icon_position-left {
    padding-bottom: 0;
  }

  .svelta_info_box_2-icon_position-left.icon-left-spacing {
    padding-bottom: 30px;
  }

  .ReactTable .rt-th,
  .ReactTable .rt-td {
    padding: 15px 6px 15px 6px;
  }

  .ReactTable .-pagination {
    padding: 6px 6px 0px 6px;
  }

  .single-product-edit .fileContainer .chooseFileButton {
    left: 50%;
    margin-left: -20px;
  }

  select {
    padding: 0 0px 0 10px
  }

  .Toastify__toast-body {
    padding: 20px 20px;
  }

  .cart_totals .shipping-calculator .popup-content {
    background: transparent !important;
    border: none !important;
    top: 32%;
  }

  .cart_totals .shop_table td {
    text-align: left;
  }

  .ReactTable .-pagination input {
    padding: 5px 7px 12px 7px;
  }

}

.img-icon-user {
  max-width: 100%;
  height: 28px;
  margin-bottom: 25.5%;
}

.hidden-login {
  display: none !important; 
}

.box-login {
   border-radius: 10px;
   transition: all 0.3s;
   -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
   -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
   box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
   font-size: 14px;
}

.no-padding{
  padding-left: 0 !important;
}
.button-social-facebook {
 width: 270px;
 background: #3B5998;
}

.login-calendar{
  width: 100%;
  padding: 10px 15px;
  height: 46px;
  background: #fff;
  color: #969696;
  font-size: 14px;
}

.button-social-google {
  width: 270px;
  background: #dd4b39;
 }


 .modalCart {
  min-height: 798px;
 }

 .product-detail-cart{
   min-height: 700px;
 }

 .rightbarDesktop{
  width: 25%;
  position: fixed !important;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1000 !important;
  background: #f9f9f9;
  color: #fff;
  transition: all 0.3s;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
}

.rightbarMobile {
  width: 80%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
  background: #f9f9f9;
  color: #fff;
  transition: all 0.3s;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
}
.cartheader-desktop {
  height:10%;
  background: var(--sage);
}

.cartlist-desktop{
  height:70%;
  overflow: auto;
  background: transparent;
  color: #969696;
}

.font-grey {
  color: #969696;
}

.cartlist-mobile{
  height:60%;
  overflow: auto;
  background: transparent;
  color: #969696;
}


.cartlist-paid-desktop{
  height:20%;
  color: #969696;
}

.cartlist-paid-mobile{
  height:30%;
  color: #969696;
}

.list-cart-side {
  color: #969696;
}

.subtotal-desktop{
  font-size: 18px;
  border-top: 1px solid rgb(224, 224, 224);
}

.border-product-cart{
  border-bottom: 1px solid rgb(224, 224, 224);
}

.profile-left-bar{
  height: 80vh;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgb(238, 238, 238);
  font-size: 15px;
  font-weight: 200;
  text-transform: uppercase;
}

.profile-border-top {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(238, 238, 238);
}

.order-box{
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
}

.break-line{
  white-space: nowrap;
}

.profile-menu-link{
  color: grey;
}

.profile-menu-link-active{
  color: var(--sage);
}

.profile-menu-link:hover{
  color: var(--sage);
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup {
  min-width: 40% !important;
}

.white-space {
  white-space: pre-wrap;
}

.box_whatsapp{
 position: fixed !important;
 align-items: center;
 align-self: flex-end;
 background-color: rgb(29 29 29);
 border-radius: 50%;
 box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
 color: rgb(79, 206, 93);
 cursor: pointer;
 display: flex;
 font-size: 2em;
 height: 45px;
 justify-content: center;
 margin-bottom: 15px;
 margin-right: 15px;
 margin-left: 15px;
 outline: transparent;
 -webkit-user-select: none;
 user-select: none;
 width: 45px;
 -webkit-box-align: center;
 -webkit-box-pack: center;
 bottom: 0px;
 right: 0px;
}

.icon_whatsapp {
  color: white;
}

.font-doacao {
  font-family: "Open Sans", sans-serif;
  font-weight: 540;
  line-height: 15px;
  font-size: 12px;
  color: #626365;
}

.avatar-purpose {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 14px;
  box-shadow: 0 0 0 10px #e6e6e6;
  color: grey;
  box-shadow: 0 0 0 1px #e6e6e6;
  line-height: 36px;
  box-shadow: 0 0 0 1px #e6e6e6;
  text-align: center;
  vertical-align: middle;
  font-family: "Open Sans", sans-serif;
  background-color: #fff;
  border: 2px solid white;
  /* float: left; */
  cursor: pointer;
  background-size: cover !important;           /* Faz com que a imagem cubra todo o círculo */
  background-position: center !important;  ;      /* Centraliza a imagem dentro do círculo */
  background-repeat: no-repeat !important;  ;     /* Evita que a imagem se repita */
}


.tag-discount {
  align-items: center;
  background: #000;
  bottom: 0;
  color: #fff;
  display: flex;
  z-index: 100;
  flex-direction: column;
  height: 3rem;
  position: absolute;
  width: 3rem;
  font-size: 11px;
}

.tag-discount-left {
  align-items: center;
  background: #000;
  bottom: 0;
  color: #fff;
  display: flex;
  z-index: 100;
  flex-direction: column;
  height: 3rem;
  position: absolute;
  width: 3rem;
  font-size: 11px;
  margin-left: 10px;
}



.tag-natural {
  align-items: center;
  background: white;
  top: 0;
  display: flex;
  z-index: 100;
  flex-direction: column;
  position: absolute;
  font-size: 11px;
  background-image:  url("../images/icons/folha.webp"); /* Caminho da sua imagem */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Impede que a imagem se repita */
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 14px;
  line-height: 36px;
  box-shadow: 0 0 0 1px #2ea824;
  margin-left: 4px;
  margin-top: 4px;
  text-align: center;
  vertical-align: middle;
  font-family: "Open Sans", sans-serif;
  border: 2px solid white;
  /* float: left; */
  cursor: pointer;
}

.tag-natural-left {
  align-items: center;
  background: white;
  top: 0;
  display: flex;
  z-index: 100;
  flex-direction: column;
  position: absolute;
  font-size: 11px;
  background-image:  url("../images/icons/folha.webp"); /* Caminho da sua imagem */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Impede que a imagem se repita */
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 14px;
  line-height: 36px;
  box-shadow: 0 0 0 1px #2ea824;
  margin-left: 14px;
  margin-top: 4px;
  text-align: center;
  vertical-align: middle;
  font-family: "Open Sans", sans-serif;
  border: 2px solid white;
  /* float: left; */
  cursor: pointer;
}

.title-span-discount {
  margin-top: -15%;
}

.box-cart-mobile{
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 16%);
}

.line{
  border-bottom: 1px solid #ccc;
}

.btn-delete-cart {
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 20px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  z-index: 9;
  display: flex;
  align-items: center;
}

.btn-card-delete{
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 20px;
  background: #dc3545;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  z-index: 9;
}

.btn-card-edit{
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 60px;
  background: #04d39f;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  z-index: 9;
}

.price-card{
  color: #323232;
  font-weight: 600;
  font-size: 1.1rem;
}

.min-height-700 {
  min-height: 700px;
}

.min-height-600 {
  min-height: 600px;
}

.min-height-150 {
  min-height: 150px;
}

.min-height-180 {
  min-height: 180px;
}

.name-product-card {
  font-size: 0.9rem;
  color: #000;
}

.color-product-card {
  font-size: 1.2rem;
  color: #000;
}

.btn-botton{
  position: absolute;
  bottom: 0;
}

.mt-minus-10 {
  margin-top: -10px;
}

.wishlist-comprar-button {
  padding: 9px 15px;
  font-size: 15px;
  margin-top: 0.5rem;
  font-weight: 500;
  line-height: 26px;
  border-radius: 0;
  background-color: #323232;
  color: #fff;
  display: block;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.wishlist-comprar-button:disabled {
  background-color: #FFFFFF;
  color: grey;
  border: 1px solid rgba(0,0,0,.125);
}
.installment-price {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
  color: #757575;
}

.discount-off {
    font-size: 12px;
    font-weight: 400;
    font-weight: 600;
    color: #087908;
    text-decoration: none;
}

.no-bullets {
  list-style-type: none;
}

.content-details-product{
     font-size: 12px;
    line-height: 16px;
    color: #343a40;
    padding: 0 0 16px;
    flex-direction: column;
    gap: 16px;
    text-align: left;
    line-height: 18px;
}

@media (min-width:376px) {
  .shop-content{
  margin-top: 25.5%;
  }
}

@media (min-width:768px) {
    .shop-content{
    margin-top: 7%;
    }
}

.footer-fixed-pay{
  position: fixed;
  bottom:0;
  left: 0;
  right: 0;
  height: 70px;
  width: 100%;
  z-index: 900;
  padding: 8px 16px;
  background: #fff;
  box-shadow: 0 -4px 2px rgb(0 0 0 / 10%);
  transform: translateY(0);
  transition: .4s ease-in-out;
}

.header-fixed-promotion {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  background-color:rgb(237, 229, 220);
  font-size: 10px;
  overflow: hidden;
  color: #423f3f;
  font-weight: 100;
  z-index: 999;
}

.site-content {
  margin-top: 30px;
}

.marker-ul{
  list-style-type: disc;
}

.list-ul{
  list-style: none;
}