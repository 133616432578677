.searchSideContainer {
  padding: 0 !important;

  aside {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 120px;
    height: 70px;

    > header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      border-bottom: 1px solid var(--very-light-pink);

      button {
        margin-right: 20px;
        width: 40px;
        height: 40px;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
      }


      > h1 {
        margin: 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25;
        color: var(--black) !important;
        white-space: nowrap;
        text-align: center;
      }
    }

    .form-control,
    input[type=email],
    input[type=password],
    input[type=search],
    input[type=text],
    textarea {
      width: 30%;
      padding: 10px 15px;
      height: 46px;
      background: #fff;
      border-width:0px;
      border:none;
      font-size: 14px;
      outline: none;
    }


    .rowSearch{
      display: flex !important;
      flex-wrap: wrap !important;
    }

    .products {
      overflow-y: auto;
      padding: 10px 10px 0;
      overscroll-behavior: contain;

      article {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-areas:
          'closebutton closebutton'
          'image header'
          'image info';
        padding: 10px;
        border-bottom: 1px solid var(--very-light-pink);

        button {
          grid-area: closebutton;
          margin-left: auto;
          opacity: .5;
        }

        .productImage {
          grid-area: image;
          width: 80px;
          height: 100px;
          object-fit: cover;
        }

        header {
          grid-area: header;
          display: flex;
          flex-direction: column;

          h1 {
            margin: 0;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.25;
            color: var(--black);
          }
        }

        .productInfo {
          grid-area: info;

          p {
            display: flex;
            justify-content: space-between;
            font-family: Montserrat;
            font-size: 12px;
            margin: 0;

            span {
              font-weight: 600;
            }
          }
        }
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid var(--very-light-pink);

      p {
        display: flex;
        justify-content: space-between;
        font-size: 16px;

        span:first-child {
          font-weight: 600;
        }
      }
    }
  }
}
