:root {
    --swamp: #dbe4a4;
    --brownish-grey: #707070;
    --slate: #3c635a;
    --faded-pink: #eaa2b4;
    --very-light-pink: #ebebeb;
    --gold: #ecb913;
    --white: #ffffff;
    --black: #151515;
    --seafoam-blue: #73dfdf;
    --almost-black: #0e100d;
    --dark-forest-green: #001212;
    --vermillion: #e9361a;
    --charcoal-grey: #41574d;
    --pink: #fc9bae;
    --pink-red: #f30052;
    --light-blue-grey: #aac9eb;
    --light-grey: #dbe8d9;
    --teal: #018e69;
    --cloudy-blue: #9bbfe4;
    --soft-pink: #f9a1b1;
    --dirty-blue: #3e8593;
    --light-mauve: #baaeaf;
    --dark-blue-grey: #0e272b;
    --coral: #eb5d5a;
    --dark-royal-blue: #01197e;
    --pale-blue: #daeff6;
    --orangish-red: #df1313;
    --reddish-orange: #e94e1e;
    --beige: #cfd0c9;
    --sage: #214a4c;
  }
  
  body,
  html {
    font-family: 'Montserrat', sans-serif;
  }
  body {
    overflow-x: hidden !important;
    width: 100%;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  a {
    text-decoration: none;
  }
  