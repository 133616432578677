.tp-bullets.custom .tp-bullet {
    background: transparent;
  }
  
  .tp-bullets.custom div.tp-bullet {
    display: inline-block;
    width: 56px;
    height: 56px;
    margin-right: 30px;
    font-size: 24px;
    color: #323232;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  
  .tp-bullets.custom div.tp-bullet:before {
    text-align: center;
    line-height: 27px;
    width: 27px;
    display: inline-block;
  }
  
  .tp-bullets.custom div.tp-bullet:after {
    content: "/";
    position: absolute;
    right: 0px;
    font-size: 30px;
    color: #323232;
  }
  
  .tp-bullets.custom div.tp-bullet:last-child:after {
    content: none;
  }
  
  .tp-bullets.custom div.tp-bullet.selected {
    background: transparent;
    color: var(--sage);
    font-size: 36px;
  }
  
  .tp-bullets.custom div.tp-bullet.selected:hover,
  .tp-bullets.custom div.tp-bullet:hover {
    background: transparent;
    color: var(--sage);
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(1):before {
    content: "01";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(2):before {
    content: "02";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(3):before {
    content: "03";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(4):before {
    content: "04";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(5):before {
    content: "05";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(6):before {
    content: "06";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(7):before {
    content: "07";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(8):before {
    content: "08";
  }
  
  .tp-bullets.custom div.tp-bullet:nth-child(9):before {
    content: "09";
  }
  
  .custom.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
  }
  
  .custom .tp-bullet {
    width: 12px;
    height: 12px;
    position: absolute;
    background: #aaa;
    background: rgba(125, 125, 125, 0.5);
    cursor: pointer;
    box-sizing: content-box;
  }
  
  .custom .tp-bullet:hover,
  .custom .tp-bullet.selected {
    background: #7d7d7d;
  }
  
  body,
  html {
    font-family: "Open Sans", sans-serif;
  }
  body {
    overflow-x: hidden !important;
    width: 100%;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  .header-style-menu-center .mega-menu .menu-links > li.current-menu-item > a {
    color: var(--sage);
  }
  
  .border-space {
    border-width: 1px;
    margin-top: 20px;
    margin-left: 80%;
    border-color: #323232;
    border-style: solid;
  }
  
  .border-space.border-space-dark {
    border-color: #323232;
  }
  
  .border-space.border-space-light {
    border-color: #fff;
  }
  
  .section {
    margin-top: 100px;
    margin-bottom: 60px;
  }
  
  .section-1 .section-title h2 {
    font-size: 56px;
    line-height: 67px;
    text-align: right;
    font-weight: 700;
    word-break: break-word;
  }
  
  .section-1 .single_image-content {
    margin-top: -70px;
  }
  
  .section-1 .single_image-content p {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
  }
  
  .section-1 .single_image-content p {
    font-size: 24px;
    color: #fff;
    margin-bottom: 0;
  }
  
  .section-1 .single_image-content p.text-dark {
    color: #323232 !important;
  }
  
  .section-1 .single_image-content .border-space {
    margin-left: 34px;
    margin-top: 12px;
  }
  
  .svelta_button_wrapper {
    display: inline-block;
  }
  
  .svelta_button_wrapper .svelta_button_size_medium a {
    font-size: 14px;
    padding: 12px 26px;
    line-height: 18px;
    color: #fff;
    background: #323232;
    display: inline-block;
  }
  
  .svelta_button_wrapper .svelta_button_size_medium a:hover {
    background: var(--sage);
  }
  
  .svelta_banner {
    position: relative;
    overflow: hidden;
  }
  
  .svelta_banner.svelta_banner-3 .svelta_banner-content-wrapper {
    align-items: flex-start !important;
  }
  
  .svelta_banner.svelta_banner-3 .svelta_banner-text-wrap .svelta_banner-text h2 {
    font-size: 105px !important;
    line-height: normal !important;
    font-weight: 500 !important;
    font-family: "Open Sans", sans-serif;
  }
  
  .svelta_banner.svelta_banner-3 .svelta_banner-text-wrap .svelta_banner-text h3 {
    font-size: 28px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
  }
  
  .svelta_banner.svelta_banner-3 .svelta_banner-btn-wrap .svelta_banner-btn {
    line-height: 44px;
    padding: 0 25px !important;
    font-weight: 500 !important;
    color: #fff !important;
    background-color: var(--sage);
    border: none !important;
  }
  
  .svelta_banner.svelta_banner-3 .svelta_banner-btn-wrap .svelta_banner-btn:hover {
    background-color: #323232;
  }
  
  .svelta_banner .svelta_banner-content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 10;
    padding: 25px;
    -webkit-transition: background .5s ease;
    -o-transition: background .5s ease;
    transition: background .5s ease;
  }
  
  .svelta_banner .svelta_banner-content-1 {
    padding-left: 48%;
  }
  
  .svelta_banner .svelta_banner-content-2 {
    padding-right: 48%;
    text-align: right;
  }
  
  .svelta_banner .svelta_banner-content .svelta_banner-content-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
  }
  
  .svelta_banner .svelta_banner-content.svelta_banner-content-vbottom {
    top: inherit;
  }
  
  .svelta_banner .svelta_banner-content.svelta_banner-content-vbottom .svelta_banner-content-wrapper {
    justify-content: center;
  }
  
  .svelta_banner .svelta_banner-content .svelta_banner-content-wrapper .svelta_banner-content-inner-wrapper .svelta_banner-label-wrap .svelta_banner-label {
    font-size: 16px;
    color: #ffffff;
    background-color: #323232;
    line-height: 20px;
    font-weight: 700;
    padding: 7px 15px;
    margin: 0;
    margin-bottom: 10px;
    display: inline-block;
  }
  
  .svelta_banner .svelta_banner-content .svelta_banner-content-wrapper .svelta_banner-content-inner-wrapper .svelta_banner-label-wrap .svelta_banner-label.svelta_banner-lanel-bg-yellow {
    background-color: #fff2d5;
    color: #323232;
  }
  
  .svelta_banner .svelta_banner-content .svelta_banner-content-wrapper .svelta_banner-content-inner-wrapper .svelta_banner-text-wrap .svelta_banner-text h2 {
    font-size: 32px;
    color: #323232;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
  }
  
  .svelta_banner .svelta_banner-content .svelta_banner-content-wrapper .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap {
    position: relative;
    z-index: 9;
    margin-top: 15px;
  }
  
  .svelta_banner .svelta_banner-content .svelta_banner-content-wrapper .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap .svelta_banner-btn {
    font-weight: 600;
    border-bottom: 0px solid;
    padding-left: 0;
    padding-right: 0;
    color: #969696;
    display: inline-block;
    font-size: 14px;
  }
  
  .svelta_banner .svelta_banner-content .svelta_banner-content-wrapper .svelta_banner-content-inner-wrapper .svelta_banner-btn-wrap .svelta_banner-btn:hover {
    color: var(--sage);
  }
  
  .svelta_banner .countdown {
    padding: 0;
    margin: 15px 0 0 !important;
    display: inline-block;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  
  .svelta_banner .countdown li {
    background: 0 0;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    border: none;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    min-width: 50px;
  }
  
  .svelta_banner .countdown li span {
    display: block;
    font-size: 28px;
    color: #323232;
    line-height: 40px;
    padding-bottom: 5px;
    font-weight: 600;
  }
  
  .svelta_banner .countdown li p {
    background: #323232;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
  }
  
  .svelta_banner.svelta_banner-style-deal-1 .svelta_banner-content {
    padding: 120px 0;
    position: relative;
  }
  
  .section-2 .svelta_banner-content-wrapper {
    justify-content: center;
    text-align: center;
  }
  
  .section-2 .svelta_banner-content-wrapper .svelta_banner-label {
    font-size: 28px !important;
    line-height: 38px !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 400 !important;
  }
  
  .section-2 .svelta_banner-content-wrapper .svelta_banner-text h2 {
    margin-top: 30px;
    font-size: 62px;
    color: #323232;
    font-style: normal;
    font-weight: 600;
    line-height: 82px;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
  }
  
  .section-2 .svelta_banner-content-wrapper .svelta_banner-btn {
    font-size: 14px;
    line-height: 44px;
    padding: 0 25px !important;
    color: #323232 !important;
    background-color: #fff;
    border: none !important;
  }
  
  .section-2 .svelta_banner-content-wrapper .svelta_banner-btn:hover {
    background-color: #323232;
    color: #fff !important;
  }
  
  .section-3 .svelta_button_wrapper {
    margin-top: 0;
  }
  
  .section-3 .svelta_button_wrapper a.inline_hover {
    position: relative;
    background: transparent;
    color: #323232;
    font-size: 14px;
    padding: 3px 0;
    line-height: 22px;
  }
  
  .section-3 .svelta_button_wrapper a.inline_hover:after {
    content: "";
    width: 100%;
    border-bottom: 2px solid;
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }
  
  .section-3 .svelta_button_wrapper a.inline_hover:hover {
    background: transparent;
  }
  
  .section-3 .svelta_button_wrapper a.inline_hover:hover:after {
    width: 30%;
  }
  
  .banner-bg {
    padding-top: 80px;
    padding-bottom: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .newsletter-wrapper.newsletter-style-1.newsletter-design-4 .input-area {
    background-color: #fff;
    padding-left: 0;
    border-radius: 3px;
  }
  
  .newsletter-wrapper .newsletter-title {
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
  }
  
  .position-a {
    position: absolute;
  }
  
  .text-sale {
    text-decoration: line-through;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
  }
  
  .circleSize {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    font-size: 14px;
    color: grey;
    line-height: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid var(--sage);
  }
  
  .circle-size-active{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    background-color: var(--sage);
    border: 1px solid var(--sage);
  }
  
  .circle-size-disabled{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    line-height: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    background-color: #cdd0d4;
    border: 1px solid #b7c3be;
  }
  
  .marginLeft-circleClor{
    margin-right: 12%;
  }
  
  .circleColor {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 2px solid #fff;
    font-size: 14px;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow: 0 0 0 10px #e6e6e6;
    color: grey;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow: 0 0 0 1px #e6e6e6;
    line-height: 36px;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow: 0 0 0 1px #e6e6e6;
    text-align: center;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: 2px solid white;
    /* float: left; */
    cursor: pointer;
  }
  
  .circleColorOff {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 2px solid #fff;
    font-size: 14px;
    box-shadow: 0 0 0 10px #e6e6e6;
    color: #2b2828;
    box-shadow: 0 0 0 1px #e6e6e6;
    line-height: 36px;
    box-shadow: 0 0 0 1px #cacaca;
    text-align: center;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: 2px solid #eaeaea;
    /* float: left; */
    cursor: pointer;
  }
  
  .circleColorShop{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #fff;
    font-size: 14px;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow: 0 0 0 10px #e6e6e6;
    color: grey;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow: 0 0 0 1px #e6e6e6;
    line-height: 36px;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow: 0 0 0 1px #e6e6e6;
    text-align: center;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: 2px solid white;
    display: none;
    /* float: left; */
    cursor: pointer;
  }
  
  .circleColorShopOff{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: none;
    border: 2px solid #fff;
    font-size: 14px;
    box-shadow: 0 0 0 10px #e6e6e6;
    color: #2b2828;
    box-shadow: 0 0 0 1px #e6e6e6;
    line-height: 36px;
    box-shadow: 0 0 0 1px #cacaca;
    text-align: center;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: 2px solid #eaeaea;
    /* float: left; */
    cursor: pointer;
  }
  
  
  
  .circle-color-active{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 2px solid #fff;
    font-size: 14px;
    -moz-box-shadow: 0 0 0 2px #000000;
    box-shadow: 0 0 0 10px #e6e6e6;
    color: grey;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow: 0 0 0 2px #000000;
    line-height: 36px;
    -moz-box-shadow: 0 0 0 1px #e6e6e6;
    box-shadow:0 0 0 2px #000000;
    text-align: center;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: 2px solid white;
    /* float: left; */
    cursor: pointer;
  }
  
  .product-detail-label{
    padding-right: 5px;
    margin-bottom: 0;
    float:left;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    color: #000;
  }

  .product-detail-text {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #000;
  }
  
  .button-disabled {
    cursor: default;
    color: grey;
  }

  .heart-icon-svg {
    filter: invert(71%) sepia(3%) saturate(3171%) hue-rotate(47deg) brightness(88%) contrast(80%)
  }

  .slider-liked-products{
    background-color: #f5f5f5;
  }