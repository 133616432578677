.button {
  background-color: var(--sage);
  border: none;
  padding: 11px;
  border-radius: 5px;
  color: #fff;

  &:focus, &:hover:not(:disabled) {
    opacity: .8;
  }

  &:disabled {
    background-color: var(--beige);
  }
}
