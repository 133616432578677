.button {
  background-color: transparent;
  border: none;
  padding: 11px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: var(--beige);
  }
}
