

.container {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }

  .content {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 70px;
    padding: 30px;
    height: 8%;
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba($color: #000000, $alpha: 0.16);
    transition: transform 0.2s ease-in-out;
  }


  .left {
    left: 0;
    transform: translateX(-102%);
  }

  .right {
    right: 0;
    transform: translateX(102%);
  }

  &[data-is-open='true'] {
    &::before {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }

    .content {
      transform: translateX(0);
    }
  }
}
